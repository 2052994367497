import React from "react";
import styled from "styled-components";
import Tabs from "./sub-components/tabs";
import {
  InputWrapper,
  PaginationWrapper,
  SearchBox,
  SearchInput,
} from "../../user-management/components/users";
import IconsBox from "../../../../components/ui-components/iconbox";
import { useDebounce } from "primereact/hooks";
import { bahasa, english } from "./text";
import { AuthButton } from "../../../user-login/components/login";
import CommonList from "./sub-components/common-list";
import PaginationManger from "../../../../components/ui-components/pagination";
import { PaginatorPageChangeEvent } from "primereact/paginator";
import {
  useCategoriesList,
  useKeywordsList,
  useSubCategoriesList,
} from "../../../../api/admin-api/category-management";
import CreateCategory from "./sub-components/create-category";
import CreateSubCategory from "./sub-components/create-subcategory";
import CreateKeyword from "./sub-components/create-keyword";
import Spinner from "../../../../components/ui-components/spinner";
import { LoaderWrapper } from "../../../../components/common-styles";

const CMWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TopHeader = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
`;

const CreateButton = styled(AuthButton)`
  width: max(15.3rem, 153px);
  height: max(4.7rem, 47px);
`;

interface IProps {
  isEnglish: boolean;
  activeTab: any;
  onActiveTab: any;
}

function CmHomePage({ isEnglish, activeTab, onActiveTab }: IProps) {
  const {
    inputPlaceholder,
    createCatText,
    createKeywordText,
    createSubCatText,
    mainTabs,
  } = isEnglish ? english : bahasa;

  const [modalForCategory, setModalForCategory] = React.useState(false);
  const [modalForSubCategory, setModalForSubCategory] = React.useState(false);
  const [modalForKeywords, setModalForKeywords] = React.useState(false);
  const [searchValue, debouncedValue, setSearchValue] = useDebounce("", 800);
  const [first, setFirst] = React.useState<number>(0);
  const [rows, setRows] = React.useState(5);
  const [pageNo, setPageNo] = React.useState(1);

  //   get list from server
  const {
    data: categoryData,
    isLoading: catgeoryListLoading,
    isError: categoryError,
    refetch: getCategoryData,
  } = useCategoriesList(rows, first, activeTab, debouncedValue);
  const {
    data: subCategoryData,
    isLoading: subCatgeoryListLoading,
    isError: subCategoryError,
    refetch: getSubCategoryData,
  } = useSubCategoriesList(rows, first, activeTab, debouncedValue);
  const {
    data: keywordData,
    isLoading: keywordListLoading,
    isError: keywordError,
    refetch: getKeywordData,
  } = useKeywordsList(rows, first, activeTab, debouncedValue);

  React.useEffect(() => {
    setFirst(() => 0);
    setRows(() => 5);
    return () => {};
  }, [activeTab]);

  //   create button handler
  const handleButtonAction = () => {
    setModalForSubCategory(false);
    setModalForKeywords(false);
    setModalForCategory(false);
    if (activeTab === 1) {
      setModalForCategory(true);
    }
    if (activeTab === 2) {
      setModalForSubCategory(true);
    }
    if (activeTab === 3) {
      setModalForKeywords(true);
    }
  };

  // refetch data according to tab
  const handleRefetch = () => {
    if (activeTab === 1) {
      getCategoryData();
    }
    if (activeTab === 2) {
      getSubCategoryData();
    }
    if (activeTab === 3) {
      getKeywordData();
    }
  };
  //   pagination handler
  const handlePageChange = (e: PaginatorPageChangeEvent, index: number) => {
    setFirst(() => e.first);
    setRows(() => e.rows);
    setPageNo(() => e.first / e.rows + 1);
  };

  return (
    <CMWrapper>
      {/* create Category modal */}
      <CreateCategory
        key={"create-category"}
        onHideModal={() => setModalForCategory(false)}
        showModal={modalForCategory}
        isEnglish={isEnglish}
        tabIndex={activeTab}
        onRefetch={handleRefetch}
      />
      {/* create Sub Category modal */}
      <CreateSubCategory
        key={"create-sub-category"}
        onHideModal={() => setModalForSubCategory(false)}
        showModal={modalForSubCategory}
        isEnglish={isEnglish}
        tabIndex={activeTab}
        onRefetch={handleRefetch}
      />
      {/* create keywords modal */}
      <CreateKeyword
        key={"create-keyword"}
        onHideModal={() => setModalForKeywords(false)}
        showModal={modalForKeywords}
        isEnglish={isEnglish}
        tabIndex={activeTab}
        onRefetch={handleRefetch}
      />

      <TopHeader>
        <Tabs
          isEnglish={isEnglish}
          activeTab={activeTab}
          onChangeTab={(tabIndex: number) => {
            setSearchValue("");
            onActiveTab(tabIndex);
          }}
          tabsArray={mainTabs}
        />
        <SearchBox>
          <InputWrapper>
            <SearchInput
              className="search-input"
              type="search"
              placeholder={inputPlaceholder}
              value={searchValue}
              onChange={(event: any) => setSearchValue(event.target.value)}
            />
            <span className="search-icon">
              <IconsBox name="search" />
            </span>
          </InputWrapper>
          <CreateButton onClick={handleButtonAction}>
            {activeTab === 1
              ? createCatText
              : activeTab === 2
              ? createSubCatText
              : createKeywordText}
          </CreateButton>
        </SearchBox>
      </TopHeader>
      {catgeoryListLoading || subCatgeoryListLoading || keywordListLoading ? (
        <LoaderWrapper>
          <Spinner />
        </LoaderWrapper>
      ) : (
        <CommonList
          isEnglish={isEnglish}
          data={
            activeTab === 1
              ? categoryData?.catList
              : activeTab === 2
              ? subCategoryData?.subCatList
              : keywordData?.keywordList
          }
          tableNumber={activeTab}
          onGetList={handleRefetch}
        />
      )}
      <PaginationWrapper>
        <PaginationManger
          onPageChange={handlePageChange}
          row={rows}
          page={first}
          totalItems={
            activeTab === 1
              ? categoryData?.totalPages || 0
              : activeTab === 2
              ? subCategoryData?.totalPages || 0
              : activeTab === 3
              ? keywordData?.totalPages || 0
              : 0
          }
        />
      </PaginationWrapper>
    </CMWrapper>
  );
}
export default CmHomePage;
