import { ADMIN_MENU_PATHS } from "../../../../all-routes/paths";

const english = {
  // tabs
  tab1: "Category",
  tab2: "Sub Category",
  tab3: "Keywords",
  // Home page tabs
  mainTabs: [
    {
      tabName: "Category",
      tabIndex: 1,
      tabPath: `?${ADMIN_MENU_PATHS.CATEGORY}`,
    },
    {
      tabName: "Sub Category",
      tabIndex: 2,
      tabPath: `?${ADMIN_MENU_PATHS.SUBCATEGORY}`,
    },
    {
      tabName: "Keywords",
      tabIndex: 3,
      tabPath: `?${ADMIN_MENU_PATHS.KEYWORDS}`,
    },
  ],

  //   controls & buttons
  inputPlaceholder: "Search",
  createCatText: "Create Category",
  createSubCatText: "Create Sub Category",
  createKeywordText: "Create Keyword",
  saveText: "Save",
  cancelText: "Cancel",
  noText: "No",
  updateText: "Update",
  confirmText: "Confirm",
  backText: "Back",

  //   more options
  assignText: "Assign",
  deleteText: "Delete",
  deletedText: "Deleted",
  editText: "Edit",

  //   tab1 table headings
  tableName1: "Category",
  tableName2: "Sub Category Linked",
  tableName3: "Keywords Linked",
  tableName4: "Surveys Linked",
  tableName5: "Reports Linked",
  tableName6: "Added On",
  tableName7: "Last Updated On",
  tableName8: "Action",
  //   tab2 table headings
  tableSubName1: "Sub Category",
  tableSubName2: "Category Linked",
  //   tab3 table headings
  tableKeywordsName1: "Keyword",

  //   create category
  categoryHeading: "Create New Category",
  categoryNameLabel: "Category Name",
  categoryNamePH: "Enter category name",
  categoryDescriptionLabel: "Category Description",
  categoryDescriptionPH: "Enter description",
  categorySubCategoryLabel: "Link Sub category",
  categorySubCategoryPH: "Enter or select link",
  categoryKeywordsLabel: "Link Keywords",
  categoryKeywordsPH: "Enter or select link",
  createdText: "Created Category",
  createdSubCatText: "Created Sub Category",
  createdKeywordText: "Created Keyword",
  updatedText: "Updated Category",
  updatedSubCatText: "Updated SubCategory",
  updatedKeywordText: "Updated Category",
  //   create sub category
  subCategoryHeading: "Create New Sub Category",
  subCategoryNameLabel1: "Sub category Name",
  subCategoryNamePH: "Enter Sub Category name",
  subCategoryNameLabel2: "Sub category Description",
  subCategoryNameLabel3: "Link Category",
  subCategoryNameLabel4: "Link Keywords",

  //   create keyword
  keywordHeading: "Create New Keyword",
  keywordNameLabel1: "Keyword Name",
  keywordNamePH: "Enter Keyword name",
  keywordNameLabel2: "Keyword Description",

  //   assign
  assignHeading: "Assign",
  assignSubCategoryLabel: "Link Sub category",
  assignCategoryLabel: "Category",
  assignSubCategoryLabel2: "Sub Category",
  assignKeywordLabel: "Keyword",
  assignSurveyLabel: "Survey",
  assignKeywordPH: "Enter or select Keyword",
  assignCategoryPH: "Enter or select Category",
  assignSubCategoryPH: "Enter or select Sub category",
  assignSurveyPH: "Enter or select Survey",
  assignConfirmHeading: "Confirm Assigning",
  assignCatConfirmSubHeading:
    "Are you sure you want to assign the Category to:",
  assignSubCatConfirmSubHeading:
    "Are you sure you want to assign the Subcategory to:",
  assignKeywordConfirmSubHeading:
    "Are you sure you want to assign the Keyword to:",

  // edit
  editKeywords: "Edit Keywords",
  editSubCategory: "Edit Sub Category",
  editCategory: "Edit Category",

  // Modal text
  modalSubCategorySubHeading: "Are you sure to Delete this Sub Category?",
  modalCategorySubHeading: "Are you sure to Delete this Category?",
  modalKeywordsHeading: "Are you sure to Delete this Keyword?",
  modalSurveyHeading: "Are you sure to Delete this Survey?",
  modalReportHeading: "Are you sure to Delete this Report?",
  modalHeader: "Delete",
  modalBtnText1: "Delete",
  modalBtnText2: "No",

  // error msg
  requiredError: "Required!",
  smtgWentWrgText: "Something went wrong!",

  // other texts
  detailText: "Details ",
  updatedOn: "Last Updated On date",
  addedOn: "Added On date",
  reportsText: "Reports Linked",
  surveyText: "Surveys Linked",
  keywordText: "Keywords Linked",
  subCatText: "Sub Category Linked",

  // detail page tab headings
  subCategoriesTxt: "Sub Categories",
  keywordsTxt: "Keywords",
  surveysTxt: "Surveys",
  reportsTxt: "Reports",
  //detail page table headings
  linkedOnTxt: "Linked On",
  linkedTxt: "Linked",

  // details page tabs
  detailPageTabs: [
    {
      tabName: "Category",
      tabIndex: 1,
      tabId: 1,
      tabPath: `?${ADMIN_MENU_PATHS.CATEGORY}`,
    },
    {
      tabName: "Sub Category",
      tabIndex: 2,
      tabId: 2,
      tabPath: `?${ADMIN_MENU_PATHS.SUBCATEGORY}`,
    },
    {
      tabName: "Keywords",
      tabIndex: 3,
      tabId: 3,
      tabPath: `?${ADMIN_MENU_PATHS.KEYWORDS}`,
    },
    {
      tabName: "Survey",
      tabIndex: 4,
      tabId: 4,
      tabPath: `?survey`,
    },
    // {
    //   tabName: "Reports",
    //   tabIndex: 5,
    //   tabId: 5,
    //   tabPath: `?reports`,
    // },
  ],
  // table headings
  dpSurveyMainText: "Survey Linked",
  dpSurveyTH1: "Surveys",
  activeText: "Active",
  deactiveText: "Deactivated",
  assignSuccessText: "Assigned",
  removeText: "Remove",
};

const bahasa = {
  // tabs
  tab1: "Kategori",
  tab2: "Sub Kategori",
  tab3: "Kata kunci",
  // Home page tabs
  mainTabs: [
    {
      tabName: "Kategori",
      tabIndex: 1,
      tabPath: `?${ADMIN_MENU_PATHS.CATEGORY}`,
    },
    {
      tabName: "Sub Kategori",
      tabIndex: 2,
      tabPath: `?${ADMIN_MENU_PATHS.SUBCATEGORY}`,
    },
    {
      tabName: "Kata kunci",
      tabIndex: 3,
      tabPath: `?${ADMIN_MENU_PATHS.KEYWORDS}`,
    },
  ],

  //   controls & buttons
  inputPlaceholder: "Pencarian",
  createCatText: "Buat Kategori",
  createSubCatText: "Buat Sub Kategori",
  createKeywordText: "Buat Kata Kunci",
  saveText: "Menyimpan",
  cancelText: "Membatalkan",
  noText: "TIDAK",
  updateText: "Memperbarui",
  confirmText: "Mengonfirmasi",
  backText: "Kembali",

  //   more options
  assignText: "Menetapkan",
  deleteText: "Hapus",
  deletedText: "Dihapus",
  editText: "Ubah",

  //   tab1 table headings
  tableName1: "Kategori",
  tableName2: "Sub Kategori Terhubung",
  tableName3: "Kata kunci Terhubung",
  tableName4: "Survei Terhubung",
  tableName5: "Laporan Terhubung",
  tableName6: "Ditambahkan pada",
  tableName7: "Terakhir diperbarui pada",
  tableName8: "Tindakan",
  //   tab2 table headings
  tableSubName1: "Sub Kategori",
  tableSubName2: "Kategori Terhubung",
  //   tab3 table headings
  tableKeywordsName1: "Kata kunci",

  //   create category
  categoryHeading: "Buat Kategori baru",
  categoryNameLabel: "Nama Kategori",
  categoryNamePH: "Masukkan nama kategori",
  categoryDescriptionLabel: "Deskripsi Kategori",
  categoryDescriptionPH: "Masukkan deskripsi",
  categorySubCategoryLabel: "Hubungkan Subkategori",
  categorySubCategoryPH: "Masukkan atau pilih",
  categoryKeywordsLabel: "Hubungkan Kata Kunci",
  categoryKeywordsPH: "Masukkan atau pilih",
  createdText: "Dibuat Kategori",
  createdSubCatText: "Dibuat Sub Kategori",
  updatedText: "Diperbarui Kategori",
  createdKeywordText: "Dibuat Kata kunci",
  updatedSubCatText: "Diperbarui Sub Kategori",
  updatedKeywordText: "Diperbarui Kata kunci",
  //   create sub category
  subCategoryHeading: "Buat Sub Kategori Baru",
  subCategoryNameLabel1: "Nama subkategori",
  subCategoryNamePH: "Masukkan nama Sub Kategori",
  subCategoryNameLabel2: "Deskripsi sub kategori",
  subCategoryNameLabel3: "Hubungkan Kategori",
  subCategoryNameLabel4: "Hubungkan Kata Kunci",

  //   create keyword
  keywordHeading: "Buat Kata Kunci baru",
  keywordNameLabel1: "Nama Kata Kunci",
  keywordNamePH: "Masukkan nama Kata Kunci",
  keywordNameLabel2: "Deskripsi Kata Kunci",

  //   assign
  assignHeading: "Menetapkan",
  assignSubCategoryLabel: "Hubungkan  Subkategori",
  assignCategoryLabel: "Kategori",
  assignSubCategoryLabel2: "Sub Kategori",
  assignKeywordLabel: "Kata kunci",
  assignSurveyLabel: "Survei",
  assignKeywordPH: "Masukkan atau pilih Kata kunci",
  assignCategoryPH: "Masukkan atau pilih Kategori",
  assignSubCategoryPH: "Masukkan atau pilih Sub kategori",
  assignSurveyPH: "Masukkan atau pilih Survei",
  assignConfirmHeading: "Konfirmasi Penugasan",
  assignCatConfirmSubHeading: "Apakah Anda yakin ingin menetapkan Kategori ke:",
  assignSubCatConfirmSubHeading:
    "Apakah Anda yakin ingin menetapkan Subkategori ke:",
  assignKeywordConfirmSubHeading:
    "Apakah Anda yakin ingin menetapkan Kata Kunci ke:",

  // edit
  editKeywords: "Edit Kata Kunci",
  editSubCategory: "Sunting Sub Kategori",
  editCategory: "Sunting Kategori",

  // Modal text
  modalSubCategorySubHeading:
    "Apakah Anda yakin untuk menghapus Sub Kategori ini?",
  modalCategorySubHeading: "Apakah Anda yakin akan Menghapus Kategori ini?",
  modalKeywordsHeading: "Apakah Anda yakin akan Menghapus Kata Kunci ini?",
  modalSurveyHeading: "Apakah Anda yakin akan Menghapus Survei ini?",
  modalReportHeading: "Apakah Anda yakin akan Menghapus Laporan ini?",
  modalHeader: "Menghapus",
  modalBtnText1: "Menghapus",
  modalBtnText2: "TIDAK",

  // error msg
  requiredError: "Diperlukan!",
  smtgWentWrgText: "Ada yang salah",

  // other texts
  detailText: "Detail ",
  updatedOn: "Terakhir Diperbarui Pada tanggal",
  addedOn: "Ditambahkan Pada tanggal",
  reportsText: "Laporan Terhubung",
  surveyText: "Survei Terhubung",
  keywordText: "Kata Kunci Terhubung",
  subCatText: "Sub Kategori Terhubung",

  // detail page tab headings
  subCategoriesTxt: "Sub Kategori",
  keywordsTxt: "Kata kunci",
  surveysTxt: "Survei",
  reportsTxt: "Laporan",
  //detail page table headings
  linkedOnTxt: "Terhubung pada",
  linkedTxt: "Terhubung",

  // details page tabs
  detailPageTabs: [
    {
      tabName: "Kategori",
      tabIndex: 1,
      tabId: 1,
      tabPath: `?${ADMIN_MENU_PATHS.CATEGORY}`,
    },
    {
      tabName: "Sub Kategori",
      tabIndex: 2,
      tabId: 2,
      tabPath: `?${ADMIN_MENU_PATHS.SUBCATEGORY}`,
    },
    {
      tabName: "Kata kunci",
      tabIndex: 3,
      tabId: 3,
      tabPath: `?${ADMIN_MENU_PATHS.KEYWORDS}`,
    },
    {
      tabName: "Survei",
      tabIndex: 4,
      tabId: 4,
      tabPath: `?survey`,
    },
    // {
    //   tabName: "Laporan",
    //   tabIndex: 5,
    //   tabId: 5,
    //   tabPath: `?reports`,
    // },
  ],
  // table headings
  dpSurveyMainText: "Survei Terhubung",
  dpSurveyTH1: "Survei",
  activeText: "Aktif",
  deactiveText: "Dinonaktifkan",
  assignSuccessText: "Ditugaskan",
  removeText: "Hapus",
};

export { english, bahasa };
