import styled from "styled-components";
import Sidebar from "./sidebar";
import React from "react";
import Switch from "../../ui-components/switch";
import { bahasa, english } from "./text";
import Breadcrumbs from "../../ui-components/breadcrumbs";
import NotificationBar from "./notification";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import useWindowSize from "../../hooks/useWindowSize";
import myStorage from "../../../store/my-storage";
import { LanguageContext } from "../../../store/language-context";

interface IStyles {
  isCollapsed?: boolean;
}

const MenuWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  background: var(--blue_100);
`;

const ContentWrapper = styled.div<IStyles>`
  width: ${(props) =>
    props.isCollapsed ? "min(100%, 100vw)" : "min(80%, 100vw)"};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: ${(props) =>
    props.isCollapsed ? "3rem 3rem 3rem 12rem" : " 3rem 3rem 3rem 28rem"};
  background: var(--blue_100);
`;

const HeaderBox = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-transform: capitalize;
  }
`;

const RightSection = styled.div`
  height: max(4.6rem, 3rem);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  h2 {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    margin: 0 0 0 0.75rem;
  }
  .line {
    width: 1px;
    height: min(2rem, 100%);
    background-color: var(--gray_350);
    margin: 0 2.3rem 0 1.5rem;
  }
  img {
    height: max(4.6rem, 3rem);
  }
  .logo-2 {
    margin: 0 0rem 0 1rem;
  }
`;
const DynamicContent = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 2rem 0 0 0;
  min-height: 90vh;
`;

function CommonMenu(props: any) {
  const storage = myStorage();

  const languageCtx = React.useContext(LanguageContext);
  const breadcrumbs: any = useBreadcrumbs();
  const { width } = useWindowSize();

  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const [isChecked, setIsChecked] = React.useState(!languageCtx.isEnglish);
  const [showNotificationBar, setShowNotificationBar] = React.useState(false);
  const { lang, notification, clearAll } = languageCtx.isEnglish
    ? english
    : bahasa;

  const handleLanguageText = () => {
    if (languageCtx.isEnglish) {
      return breadcrumbs?.length >= 2 ? breadcrumbs[2].breadcrumb : "";
    } else if (!languageCtx.isEnglish) {
      const myBahasaPath: any = {
        Dashboard: "Dasbor",
        "Manage files": "Kelola File",
        Survey: "Survei",
        Analytics: "Analitik",
        Settings: "Pengaturan",
        "User management": "Manajemen pengguna",
        "Category management": "Manajemen kategori",
        "Media monitoring": "Pemantauan Media",
      };

      return myBahasaPath[breadcrumbs[2]?.breadcrumb?.props?.children];
    }
  };

  return (
    <MenuWrapper>
      <Sidebar
        isCollapsed={isCollapsed ? isCollapsed : width <= 998 ? true : false}
        onViewNotificationBar={() => setShowNotificationBar(true)}
      />
      <ContentWrapper
        isCollapsed={isCollapsed ? isCollapsed : width <= 998 ? true : false}
      >
        <HeaderBox>
          <Header>
            <h1>{handleLanguageText()}</h1>
          </Header>
          <RightSection>
            <Switch
              isOn={isChecked}
              handleToggle={() => {
                setIsChecked((prevState) => {
                  const isEng = prevState ? "false" : "true";
                  storage.set("bahasa", isEng);
                  languageCtx.trackChangesHandler();
                  return !prevState;
                });
              }}
            />
            <h2>{lang}</h2>
            <div className="line"></div>
            <img
              src="/images/auth/undp-logo-1.png"
              alt="logo 1"
              className="logo-1"
            />
            <img
              src="/images/auth/undp-logo-2.png"
              alt="logo 2"
              className="logo-2"
            />
          </RightSection>
        </HeaderBox>

        {breadcrumbs && breadcrumbs?.length >= 4 ? <Breadcrumbs /> : ""}
        <DynamicContent>{props.children}</DynamicContent>
      </ContentWrapper>
      <NotificationBar
        show={showNotificationBar}
        onHide={() => setShowNotificationBar(false)}
        isEnglish={languageCtx.isEnglish}
      />
    </MenuWrapper>
  );
}
export default CommonMenu;
