import { useMutation, useQuery } from "react-query";
import apiCall from "..";
import { API_END_POINTS } from "../api-end-points";

// Get users
const fetchUsers = ({ queryKey }: any) => {
  return apiCall.get(
    `${API_END_POINTS.ADMIN_USER_LIST}?search=${queryKey[1]}&limit=${queryKey[2]}&offset=${queryKey[3]}${queryKey[4]}&filter_by=${queryKey[5]}`
  );
};
const useUsersList = (
  search?: any,
  limit?: any,
  offSet?: any,
  blockedUser?: any,
  filterBy?: string
) => {
  return useQuery(
    ["users-list", search, limit, offSet, blockedUser, filterBy],
    fetchUsers,
    {
      select: (data) => {
        const totalPages = data?.data?.total_count;
        const usersList = data?.data?.data.map((user: any) => {
          if (user?.updated_at) {
            return {
              ...user,
              updated_at: new Date(user.updated_at).toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }),
              created_at: new Date(user.created_at).toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }),
            };
          }
          return user;
        });
        return (
          {
            totalPages,
            usersList,
          } || undefined
        );
      },
    }
  );
};

// Get user roles
const fetchUserRoles = () => {
  return apiCall.get(API_END_POINTS.ADMIN_GET_USER_ROLES);
};
const useUserRoles = () => {
  return useQuery("user-roles", fetchUserRoles, {
    select: (data) => {
      return data?.data?.data?.map((userRoles: any) => ({
        value: userRoles.um_role_id,
        label: userRoles.name.toUpperCase(),
      }));
    },
  });
};

// Get villages
const fetchVillageList = () => {
  return apiCall.get(API_END_POINTS.ADMIN_VILLAGE_LIST);
};
const useVillageList = () => {
  return useQuery("village-list", fetchVillageList, {
    select: (data) => {
      return data?.data?.data?.map((village: any) => ({
        value: village.village_id,
        label: village.name.toUpperCase(),
      }));
    },
  });
};

// Get current user
const fetchUserDetails = ({ queryKey }: any) => {
  return apiCall.get(`${API_END_POINTS.ADMIN_USER_BY_ID}${queryKey[1]}`);
};
const useUserDetails = (userId: any) => {
  return useQuery(["user-details", userId], fetchUserDetails, {
    select: (data) => {
      const newUpdated_at = new Date(
        data?.data?.data?.updated_at
      ).toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const newCreated_at = new Date(
        data?.data?.data?.created_at
      ).toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return {
        ...data?.data?.data,
        updated_at: newUpdated_at,
        created_at: newCreated_at,
      };
    },
    enabled: !!userId,
  });
};

// Add user
const addUser = (userData: any) => {
  return apiCall.post(API_END_POINTS.ADMIN_CREATE_USER, userData);
};
const useAddUser = (onSuccess: any, onError: any) => {
  return useMutation(addUser, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// Update user
const updateUser = (userData: any) => {
  const userId = userData.userId;
  delete userData.userId;
  return apiCall.put(
    `${API_END_POINTS.ADMIN_UPDATE_USER}/${userId}`,
    userData
  );
};
const useUpdateUser = (onSuccess: any, onError: any) => {
  return useMutation(updateUser, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// Invite user
const inviteUser = (userData: any) => {
  return apiCall.post(API_END_POINTS.ADMIN_INVITE_USER, userData);
};
const useInviteUser = (onSuccess: any, onError: any) => {
  return useMutation(inviteUser, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// Block or Unblock user
const controlUser = (userData: any) => {
  return apiCall.post(API_END_POINTS.ADMIN_BLOCK_USER, userData);
};
const useControlUser = (onSuccess: any, onError: any) => {
  return useMutation(controlUser, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// Delete user
const deleteUser = (userId: any) => {
  return apiCall.post(API_END_POINTS.ADMIN_DELETE_USER, userId);
};
const useDeleteUser = (onSuccess: any, onError: any) => {
  return useMutation(deleteUser, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

export {
  useUsersList,
  useUserDetails,
  useAddUser,
  useUpdateUser,
  useInviteUser,
  useControlUser,
  useDeleteUser,
  useUserRoles,
  useVillageList
};
