import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { CancelButton, Footer, SubmitButton } from "./create-category";
import { bahasa, english } from "../text";

const InnerWrapper = styled.div`
  max-width: 42.8rem;
  max-height: 36.7rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
`;
const Heading = styled.h1`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
`;
export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .sub-head {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    margin: 0 0 1.4rem 0;
  }
`;
const Content = styled.div`
  width: 100%;
  border-radius: 0.6rem;
  background-color: var(--gray_050);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.6rem;
  .text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .heading {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: -0.02em;
  }
  .sub-heading {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: var(--gray_600);
  }
`;
const ButtonBox = styled(Footer)`
  justify-content: center;
`;

interface Iprops {
  showModal: boolean;
  onHideModal: any;
  isEnglish: boolean;
  onConfirm: any;
  subHead: string;
  inputData: any;
  activeHomeTab: number;
  surveys?: any;
  categories?: any;
  subCategories?: any;
  keywords?: any;
}

const ConfirmAssign = ({
  showModal,
  onHideModal,
  isEnglish,
  onConfirm,
  subHead,
  inputData,
  activeHomeTab,
  surveys,
  categories,
  subCategories,
  keywords,
}: Iprops) => {
  const {
    assignConfirmHeading,
    noText,
    confirmText,
    assignCatConfirmSubHeading,
    assignSubCatConfirmSubHeading,
    assignKeywordConfirmSubHeading,
  } = isEnglish ? english : bahasa;

  const handleNameFinder = (id: any) => {
    let name: any = "";
    name = surveys?.find((item: any) => item.value == id);
    if (name) {
      return name.label;
    }
    name = categories?.find((item: any) => item.value == id);
    if (name) {
      return name.label;
    }
    name = subCategories?.find((item: any) => item.value == id);
    if (name) {
      return name.label;
    }
    name = keywords?.find((item: any) => item.value == id);
    if (name) {
      return name.label;
    }
    return "";
  };

  return (
    <Dialog
      visible={showModal}
      onHide={onHideModal}
      style={{
        backgroundColor: "var(--white_900)",
        padding: "4rem",
        borderRadius: "1rem",
        border: "1px solid var(--gray_100)",
      }}
      maskStyle={{
        backgroundColor: "var(--black_050)",
      }}
      header={<Heading>{assignConfirmHeading}</Heading>}
    >
      <InnerWrapper>
        <ModalContent>
          <p className="sub-head">
            {activeHomeTab === 1
              ? assignCatConfirmSubHeading
              : activeHomeTab === 2
              ? assignSubCatConfirmSubHeading
              : assignKeywordConfirmSubHeading}
          </p>
          <Content>
            {inputData.map((data: any, i: number) => {
              return (
                <span key={i} className="text-wrapper">
                  <h3 className="heading">{data.heading}</h3>
                  <p className="sub-heading">
                    {Array.isArray(data.subHeading)
                      ? data?.subHeading?.map((item: any) => (
                          <span key={item}>{`${handleNameFinder(
                            item
                          )}, `}</span>
                        ))
                      : handleNameFinder(data.subHeading)}
                  </p>
                </span>
              );
            })}
          </Content>
        </ModalContent>
        <ButtonBox>
          <CancelButton onClick={onHideModal}>{noText}</CancelButton>
          <SubmitButton onClick={onConfirm}>{confirmText}</SubmitButton>
        </ButtonBox>
      </InnerWrapper>
    </Dialog>
  );
};

export default ConfirmAssign;
