import styled from "styled-components";
import IconsBox from "../../../components/ui-components/iconbox";
import { bahasa, english } from "./text";
import { useNavigate } from "react-router-dom";
import DropZone from "../../../components/ui-components/drag-drop-files";
import React from "react";
import ChipInput from "../../../components/ui-components/input/chip-input";
import { AuthButton } from "../../user-login/components/login";
import SuccessModal from "../../../components/ui-components/success-modal";
import Toaster from "../../../components/ui-components/toaster";

import axios from "axios";
import ReactSelect from "../../../components/ui-components/select/r-select";
import { ButtonOutlined } from "../../../components/common-styles";
import {
  useCategoriesListForSelect,
  useUploadFile,
} from "../../../api/user-api";
import { MANAGE_FILES_SUB_PATHS } from "../../../all-routes/paths";
import { APP_ENV } from "../../../api/config";
import { useVillageList } from "../../../api/admin-api/user-management";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TopBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: -3.5rem 0 0 0;
`;
export const BackBtn = styled(ButtonOutlined)`
  width: max(7.5rem, 75px);
  height: max(3.9rem, 39px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  z-index: 3;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--white_900);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
  border-radius: 1.5rem;
  padding: 3.5rem;
  margin: 1.5rem 0 0 0;
  .line {
    width: 100%;
    height: 1px;
    background-color: var(--gray_450);
    margin: 0;
  }
`;
const SelectWrapper = styled.div`
  position: relative;
  max-width: 67.8rem;
  width: 100%;
  .error-msg {
    position: absolute;
    font-size: 1rem !important;
  }
`;
const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  margin: 2rem 0;
`;

const CancelButton = styled(ButtonOutlined)`
  width: max(9.9rem, 99px);
  height: max(4.7rem, 47px);
`;
const SaveButton = styled(AuthButton)`
  width: max(14.8rem, 148px);
  height: max(4.7rem, 47px);
`;

interface Iprops {
  isEnglish: boolean;
}

interface FormData {
  file: any;
  category: any;
  keyWords: any;
  village_id: any;
}
interface FormErrors {
  file: string | null;
  category: string | null;
  village_id: string | null;
  keywords: string | null;
  isError: boolean;
}

const initialFormErrors: FormErrors = {
  file: null,
  category: null,
  village_id: null,
  keywords: null,
  isError: false,
};

const UploadFile = ({ isEnglish }: Iprops) => {
  const {
    backbtnText,
    fileDesc,
    fileTypes,
    fileSizeText,
    browse,
    uploadingText,
    uploaded,
    cancelText,
    selectTag1Label,
    selectTag1Error,
    selectTag2Error,
    selectTag2Label,
    selectTag3Label,
    selectTag3Error,
    cancelBtn,
    saveBtn1,
    removeBtn,
    modalHeader,
    modalContent,
    modalBtnText,
    chipInputPH,
    fileTypeErrorText,
    selectCategoryText,
    selectVillageText,
  } = isEnglish ? english : bahasa;
  const navigate = useNavigate();

  const [uploadingStatus, setUploadingStatus] = React.useState("no-file");
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });
  const initialFormData: FormData = {
    file: null,
    category: null,
    village_id: null,
    keyWords: [],
  };
  const [updatedProfileImg, setUpdatedProfileImg] = React.useState("");
  const [btnLoading, setBtnLoading] = React.useState(false);
  const [formData, setFormData] = React.useState<FormData>(initialFormData);
  const [formErrors, setFormErrors] =
    React.useState<FormErrors>(initialFormErrors);

  const onSuccess = (res: any) => {
    setShowSuccessModal(true);
    setUploadingStatus("no-file");
    setFormErrors(initialFormErrors);
    setBtnLoading(false);
    axios.post(`${APP_ENV.BE_AI_BASE_URL}/file`, {
      file: res?.data?.file_name_original
        ? res?.data?.file_name_original
        : res?.data?.file_name,
    });
    setFormData((prevState) => ({ ...prevState, file: "" }));
  };
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: "",
      reason: res?.response?.data?.message,
    });
    setBtnLoading(false);
  };
  const { mutate: uploadFile } = useUploadFile(onSuccess, onError);
  const { data: categoriesList, isLoading: catIsLoading } =
    useCategoriesListForSelect();
  const { data: villageList, isLoading: villageListLoading } = useVillageList();

  const handleFileUpload = async (file: any) => {
    if (file) {
      setUploadingStatus("uploading");
      setUpdatedProfileImg(updatedProfileImg);
      setFormData((prevState) => ({ ...prevState, file: file }));
      setTimeout(() => setUploadingStatus("uploaded"), 500);
    } else {
      setShowToast({
        status: false,
        type: "",
        message: "",
        reason: "",
      });
    }
  };

  const addKeywords = (keywords: string) => {
    setFormData((prevState) => ({
      ...prevState,
      keyWords: keywords,
    }));
    setFormErrors((prevState) => ({ ...formErrors, keywords: null }));
  };

  async function handleSubmit() {
    const errors: FormErrors = {
      file: null,
      category: null,
      keywords: null,
      village_id: null,
      isError: false,
    };
    let isValid = true;

    // Validate
    if (!formData.file) {
      errors.file = "error";
      isValid = false;
    }
    if (!formData.category) {
      errors.category = selectTag1Error;
      isValid = false;
    }
    if (!formData.village_id) {
      errors.village_id = selectTag3Error;
      isValid = false;
    }
    if (!formData.keyWords?.length) {
      errors.keywords = selectTag2Error;
      isValid = false;
    }

    if (!isValid) {
      setFormErrors(errors);
    } else {
      setBtnLoading(true);
      // Submit the form data here
      await uploadFile({
        file: formData.file,
        category: formData.category,
        keywords: formData.keyWords.join(","),
        village_id: formData.village_id,
      });
      setShowToast({
        status: false,
        type: "",
        message: "",
        reason: "",
      });
    }
  }

  return (
    <>
      <Toaster
        showToast={showToast.status}
        severity="error"
        summary=""
        detail="Upload failed!"
      />
      <Container>
        <TopBox>
          <BackBtn
            onClick={() =>
              navigate(`${MANAGE_FILES_SUB_PATHS.MANAGE_FILES_BASE}`)
            }
          >
            <IconsBox name="left-arrow" /> {backbtnText}
          </BackBtn>
        </TopBox>
        <ContentWrapper>
          <DropZone
            text1={fileDesc}
            text2={browse}
            text3={fileTypes}
            text4={uploadingText}
            text5={cancelText}
            text6={uploaded}
            text7={removeBtn}
            text8={fileSizeText}
            text9={fileTypeErrorText}
            onFileUpload={handleFileUpload}
            onCancel={() => setUploadingStatus("no-file")}
            status={btnLoading ? "uploading" : uploadingStatus}
          />
          <SelectWrapper>
            {/* {catIsLoading ? null : ( */}
            <ReactSelect
              options={
                categoriesList && categoriesList.length ? categoriesList : []
              }
              onChange={(e: any) => {
                setFormData((prevState) => ({
                  ...formData,
                  category: e.target.value,
                }));
                setFormErrors((prevState) => ({
                  ...formErrors,
                  category: null,
                }));
              }}
              name="my-select"
              placeholder={selectCategoryText}
              isLabel={selectTag1Label}
              optionsLoading={catIsLoading}
            />
            {/* )} */}
            {/* <SelectTag
              isLabel={selectTag1Label}
              name="my-select"
              placeholder="select any"
              options={isLoading ? [] : categoriesList}
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...formData,
                  category: e.target.value,
                }));
                setFormErrors((prevState) => ({
                  ...formErrors,
                  category: null,
                }));
              }}
            /> */}
            {formErrors.category && (
              <span className="error-text-css error-msg">
                {selectTag1Error}
              </span>
            )}
          </SelectWrapper>
          <SelectWrapper>
            {/* {catIsLoading ? null : ( */}
            <ReactSelect
              options={villageList && villageList.length ? villageList : []}
              onChange={(e: any) => {
                setFormData((prevState) => ({
                  ...formData,
                  village_id: e.target.value,
                }));
                setFormErrors((prevState) => ({
                  ...formErrors,
                  village_id: null,
                }));
              }}
              name="my-select"
              placeholder={selectVillageText}
              isLabel={selectTag3Label}
              optionsLoading={villageListLoading}
            />
            {/* )} */}
            {/* <SelectTag
              isLabel={selectTag1Label}
              name="my-select"
              placeholder="select any"
              options={isLoading ? [] : categoriesList}
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...formData,
                  category: e.target.value,
                }));
                setFormErrors((prevState) => ({
                  ...formErrors,
                  category: null,
                }));
              }}
            /> */}
            {formErrors.village_id && (
              <span className="error-text-css error-msg">
                {selectTag3Error}
              </span>
            )}
          </SelectWrapper>
          <SelectWrapper>
            <ChipInput
              isLabel={selectTag2Label}
              handleSetKeyword={addKeywords}
              isError={formErrors.keywords ? selectTag2Error : ""}
              isPlaceholder={chipInputPH}
            />
          </SelectWrapper>
          <div className="line"></div>

          <ButtonBox>
            <CancelButton
              onClick={() =>
                navigate(`${MANAGE_FILES_SUB_PATHS.MANAGE_FILES_BASE}`)
              }
            >
              {cancelBtn}
            </CancelButton>
            <SaveButton disabled={btnLoading} onClick={handleSubmit}>
              {btnLoading ? "..." : saveBtn1}
            </SaveButton>
          </ButtonBox>
        </ContentWrapper>
      </Container>
      <SuccessModal
        showModal={showSuccessModal}
        onHideModal={() => setShowSuccessModal(false)}
        heading={modalHeader}
        content={modalContent}
        btnText={modalBtnText}
        path={MANAGE_FILES_SUB_PATHS.MANAGE_FILES_BASE}
      />
    </>
  );
};

export default UploadFile;
