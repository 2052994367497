const english = {
  // top header
  tab1: "Active Profile's",
  tab2: "Blocked Profile’s",
  tab3: "Unblocked Profile’s",

  btnTxt1: "Add New User",
  inputPlaceholder: "Search",

  // table headings
  tName: "User Name",
  tEmail: "Email ID",
  tFilesAdded: "Files added",
  tSurvey: "Surveys Created",
  tDate: "Date Created",
  tAccessedDate: "Last Accessed Date",
  tAction: "Action",

  // Modal text
  modalHeader: "File Uploaded",
  modalContent: "Your file has been uploaded successfully",
  modalBtnText: "Okay, Go To Manage File",

  // more
  viewTxt: "View",
  deleteTxt: "Delete",

  // Add user
  addUserHeading: "Add User",
  addUserInputLabel1: "Full Name",
  addUserInputLabel2: "Permission",
  addUserInputLabel3: "Email address",
  addUserInputLabel4: "Phone number",
  addUserInputLabel5: "Village",
  addUserInputPlaceholder1: "Enter your name",
  addUserInputPlaceholder2: "Select permission",
  addUserInputPlaceholder3: "Enter Email Id",
  addUserInputPlaceholder4: "Enter phone number",
  addUserInputPlaceholder5: "Select village",
  addUserBtnText1: "Cancel",
  addUserBtnText2: "Submit",
  errorEmailText1: "Email is required",
  errorEmailText2: "Invalid Email",
  errorRoleText: "Role is required",
  errorPhoneText: "Phone is required",
  errorCodeText:'Code is required',
  errorNameText: "Name is required",
  errorVillageText: "Village is required",

  // User details page
  editProfileBtnText: "Edit Profile",
  userNameLabel: "Name :",
  userEmailLabel: "Email ID :",
  userPhoneLabel: "Phone Number :",
  userDOBLabel: "Date of Birth :",
  userAddressLabel: "Address :",
  userCountryLabel: "Country :",
  cancelText: "Cancel",
  updateText: "Update",
  detailsText: "Details ",
  detailsSurveyText: "Surveys created ",
  detailsCreatedDate: "Account Created date ",
  detailsFiles: "Files uploaded ",
  detailsLastDate: "Account Last Accessed date ",
  tableHeading: "Uploaded File's",
  tableSeeAllText: "See All",
  tableSeeLessText: "See Less",
  surveyListText: "Survey List",
  moreOptText1: "Block Profile",
  moreOptText3: "Unblock Profile",
  moreOptText2: "Delete Profile",
  blockedText: 'User blocked successfully',
  unblockedText: 'User unblocked successfully',
  deleteConfirmText: "Are you sure to delete this Profile?",
  noText: "No",
  noResults: "No Result!",

  // uploaded files table
  fileNameTH: "File Name",
  categoryTH: "Category",
  keywordsTH: "Keywords",
  fileSizeTH: "File Size",
  dateCreatedTH: "Date created",
  actionTH: "Action",
  smtgWentWrgText: "Something went wrong!",
};
const bahasa = {
  // top header
  tab1: "Profil Aktif",
  tab2: "Profil yang Diblokir",
  tab3: "Profil yang Diblokir",

  btnTxt1: "Tambahkan pengguna baru",
  inputPlaceholder: "Pencarian",

  // table headings
  tName: "Nama Pengguna",
  tEmail: "ID email",
  tFilesAdded: "File ditambahkan",
  tSurvey: "Survei Dibuat",
  tDate: "Tanggal dibuat",
  tAccessedDate: "Tanggal Terakhir Diakses",
  tAction: "Tindakan",

  // Modal text
  modalHeader: "Berkas Diunggah",
  modalContent: "File Anda telah berhasil diunggah",
  modalBtnText: "Oke, Buka Kelola File",

  // more
  viewTxt: "Lihat",
  deleteTxt: "Hapus",

  // Add user
  addUserHeading: "Tambahkan pengguna",
  addUserInputLabel1: "Nama lengkap",
  addUserInputLabel2: "Izin",
  addUserInputLabel3: "Alamat email",
  addUserInputLabel4: "Nomor telepon",
  addUserInputLabel5: "Desa",
  addUserInputPlaceholder1: "Masukkan nama Anda",
  addUserInputPlaceholder2: "Pilih izin",
  addUserInputPlaceholder3: "Masukkan Id Email",
  addUserInputPlaceholder4: "Masukkan nomor telepon",
  addUserInputPlaceholder5: "Pilih desa",
  addUserBtnText1: "Batal",
  addUserBtnText2: "Kirim",
  errorEmailText1: "Email diperlukan",
  errorEmailText2: "Email tidak valid",
  errorRoleText: "Peran diperlukan",
  errorPhoneText: "Telepon diperlukan",
  errorCodeText:'Kode diperlukan',
  errorNameText: "Nama wajib diisi",
  errorVillageText: "Desa diperlukan",

  // User details page
  editProfileBtnText: "Ubah profil",
  userNameLabel: "Nama :",
  userEmailLabel: "ID email :",
  userPhoneLabel: "Nomor telepon :",
  userDOBLabel: "Tanggal lahir :",
  userAddressLabel: "Alamat :",
  userCountryLabel: "Negara :",
  cancelText: "Batal",
  updateText: "Memperbarui",
  detailsText: "Detail ",
  detailsSurveyText: "Survei dibuat",
  detailsCreatedDate: "Tanggal pembuatan akun",
  detailsFiles: "File diunggah",
  detailsLastDate: "Tanggal Terakhir Akun Diakses",
  tableHeading: "File yang Diunggah",
  tableSeeAllText: "Lihat semua",
  tableSeeLessText: "Lihat lebih sedikit",
  surveyListText: "Daftar survey",
  moreOptText1: "Blokir Profil",
  moreOptText3: "Buka Blokir Profil",
  moreOptText2: "Hapus Profil",
  blockedText: 'Pengguna berhasil diblokir',
  unblockedText: 'Pengguna berhasil dibuka blokirnya',
  deleteConfirmText: "Apakah Anda yakin akan menghapus Profil ini?",
  noText: "TIDAK",
  noResults: "Tidak ada hasil!",

  // uploaded files table
  fileNameTH: "Nama file",
  categoryTH: "Kategori",
  keywordsTH: "Kata kunci",
  fileSizeTH: "Ukuran file",
  dateCreatedTH: "Tanggal dibuat",
  actionTH: "Tindakan",
  smtgWentWrgText: "Ada yang salah",
};

export { english, bahasa };
