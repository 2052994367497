import React, { useContext } from "react";
import { LanguageContext } from "../../../store/language-context";
import styled from "styled-components";

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-size: 1.4rem;
  color: var(--gray-400);
  cursor: not-allowed;
`;

const NoData = () => {
  const { isEnglish } = useContext(LanguageContext);

  return <Div>{isEnglish ? "No data" : "Tidak ada data"}</Div>;
};

export default NoData;
