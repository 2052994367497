import { useMutation, useQuery } from "react-query";
import apiCall from "..";
import { API_END_POINTS } from "../api-end-points";

// Get categories list
const fetchCategoriesList = ({ queryKey }: any) => {
  return apiCall.get(
    `${API_END_POINTS.ADMIN_GET_CATEGORIES_LIST}?limit=${queryKey[1]}&offset=${queryKey[2]}&search=${queryKey[3]}`
  );
};
const useCategoriesList = (
  limit?: any,
  offSet?: any,
  currentTab?: number,
  search?: any
) => {
  return useQuery(
    ["category-list", limit, offSet, search],
    fetchCategoriesList,
    {
      select: (data: any) => {
        const totalPages = data?.data?.total_count;
        const catList = data?.data?.data.map((cat: any) => {
          return {
            sub_category_linked: cat?.category_subcategory,
            keyword_linked: cat?.category_keyword,
            survey: cat?.category_survey,
            reports_linked: cat?.reports_linked,
            category_id: cat?.category_id,
            name: cat?.name,
            remarks: cat?.remarks,
            description: cat?.description,
            is_active: cat?.is_active,
            deleted_at: new Date(cat?.deleted_at).toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            updated_at: new Date(cat?.updated_at).toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            created_at: new Date(cat?.created_at).toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
          };
        });
        return (
          {
            totalPages,
            catList,
          } || undefined
        );
      },
      enabled: currentTab === 1,
    }
  );
};
// Get categories list for select
const fetchCategoriesListForSelect = () => {
  return apiCall.get(`${API_END_POINTS.ADMIN_GET_CATEGORIES_LIST}`);
};
const useCategoriesListForSelect = () => {
  return useQuery(["category-list-for-select"], fetchCategoriesListForSelect, {
    select: (data: any) => {
      return data?.data?.data.map((catg: any) => ({
        value: catg.category_id,
        label: catg.name,
      }));
    },
  });
};
// Get All details By Id
const getById = ({ queryKey }: any) => {
  let url;
  if (queryKey[2] === 1) {
    url = API_END_POINTS.ADMIN_GET_CATEGORIES_LIST;
  }
  if (queryKey[2] === 2) {
    url = API_END_POINTS.ADMIN_GET_SUBCATEGORIES_LIST;
  }
  if (queryKey[2] === 3) {
    url = API_END_POINTS.ADMIN_GET_KEYWORD_LIST;
  }
  return apiCall.get(`${url}/${queryKey[1]}/`);
};
const useGetById = (id: any, tabNumber: number) => {
  return useQuery(["category-by-id", id, tabNumber], getById, {
    select: (data: any) => {
      let fetchedData = [];
      if (tabNumber === 1) {
        fetchedData = {
          survey_count: data?.data?.category_survey_count,
          subcategory_count: data?.data?.category_subcategory_count,
          keywords_count: data?.data?.category_keywords_count,
          ...data?.data?.category[0],
          updated_at: new Date(
            data?.data?.category[0].updated_at
          ).toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
          created_at: new Date(
            data?.data?.category[0].created_at
          ).toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
        };
      }
      if (tabNumber === 2) {
        fetchedData = {
          survey_count: data?.data?.subcategory_survey_count,
          category_count: data?.data?.subcategory_category_count,
          keywords_count: data?.data?.subcategory_keywords_count,
          ...data?.data?.subcategory[0],
          updated_at: new Date(
            data?.data?.subcategory[0].updated_at
          ).toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
          created_at: new Date(
            data?.data?.subcategory[0].created_at
          ).toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
        };
      }
      if (tabNumber === 3) {
        fetchedData = {
          survey_count: data?.data?.keywords_survey_count,
          category_count: data?.data?.keyword_category_count,
          subcategory_count: data?.data?.keywords_subcategory_count,
          ...data?.data?.keywords[0],
          updated_at: new Date(
            data?.data?.keywords[0].updated_at
          ).toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
          created_at: new Date(
            data?.data?.keywords[0].created_at
          ).toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
        };
      }

      return fetchedData;
    },
  });
};
// Add Category
const addCategory = (data: any) => {
  return apiCall.post(API_END_POINTS.ADMIN_ADD_CATEGORY, data);
};
const useAddCategory = (onSuccess: any, onError: any) => {
  return useMutation(addCategory, {
    onSuccess: onSuccess,
    onError: onError,
  });
};
// Edit Category
const editCategory = (data: any) => {
  const id = data.category_id;
  delete data.category_id;
  return apiCall.put(`${API_END_POINTS.ADMIN_EDIT_CATEGORY}/${id}/`, data);
};
const useEditCategory = (onSuccess: any, onError: any) => {
  return useMutation(editCategory, {
    onSuccess: onSuccess,
    onError: onError,
  });
};
// Delete Category
const deleteCategory = (id: any) => {
  return apiCall.delete(`${API_END_POINTS.ADMIN_DELETE_CATEGORY}/${id}/`);
};
const useDeleteCategory = (onSuccess: any, onError: any) => {
  return useMutation(deleteCategory, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// Get Sub Categories list
const fetchSubCategoriesList = ({ queryKey }: any) => {
  return apiCall.get(
    `${API_END_POINTS.ADMIN_GET_SUBCATEGORIES_LIST}?limit=${queryKey[1]}&offset=${queryKey[2]}&search=${queryKey[3]}`
  );
};
const useSubCategoriesList = (
  limit: any,
  offSet: any,
  currentTab: number,
  search?: any
) => {
  return useQuery(
    ["subcategory-list", limit, offSet, search],
    fetchSubCategoriesList,
    {
      select: (data: any) => {
        const totalPages = data?.data?.total_count;
        const subCatList = data?.data?.data.map((subcat: any) => {
          return {
            category_linked: subcat?.subcategory_category,
            keyword_linked: subcat?.subcategory_keyword,
            survey: subcat?.subcategory_survey,
            reports_linked: subcat?.reports_linked,
            sub_category_id: subcat?.sub_category_id,
            name: subcat?.name,
            remarks: subcat?.remarks,
            description: subcat?.description,
            is_active: subcat?.is_active,
            deleted_at: new Date(subcat?.deleted_at).toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            updated_at: new Date(subcat?.updated_at).toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            created_at: new Date(subcat?.created_at).toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
          };
        });
        return (
          {
            totalPages,
            subCatList,
          } || undefined
        );
      },
      enabled: currentTab === 2,
    }
  );
};
// Get Sub categories list for select
const fetchSubCategoriesListForSelect = () => {
  return apiCall.get(`${API_END_POINTS.ADMIN_GET_SUBCATEGORIES_LIST}`);
};
const useSubCategoriesListForSelect = () => {
  return useQuery(
    ["subcategory-list-for-select"],
    fetchSubCategoriesListForSelect,
    {
      select: (data: any) => {
        return data?.data?.data.map((subcatg: any) => ({
          value: subcatg.sub_category_id,
          label: subcatg.name,
        }));
      },
    }
  );
};

// Add Sub Category
const addSubCategory = (data: any) => {
  return apiCall.post(API_END_POINTS.ADMIN_ADD_SUBCATEGORY, data);
};
const useAddSubCategory = (onSuccess: any, onError: any) => {
  return useMutation(addSubCategory, {
    onSuccess: onSuccess,
    onError: onError,
  });
};
// Edit Sub Category
const editSubCategory = (data: any) => {
  const id = data.sub_category_id;
  delete data.sub_category_id;
  return apiCall.put(`${API_END_POINTS.ADMIN_EDIT_SUBCATEGORY}/${id}/`, data);
};
const useEditSubCategory = (onSuccess: any, onError: any) => {
  return useMutation(editSubCategory, {
    onSuccess: onSuccess,
    onError: onError,
  });
};
// Delete Sub Category
const deleteSubCategory = (id: any) => {
  return apiCall.delete(`${API_END_POINTS.ADMIN_DELETE_SUBCATEGORY}/${id}/`);
};
const useDeleteSubCategory = (onSuccess: any, onError: any) => {
  return useMutation(deleteSubCategory, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// Get Keywords list
const fetchKeywordsList = ({ queryKey }: any) => {
  return apiCall.get(
    `${API_END_POINTS.ADMIN_GET_KEYWORD_LIST}?limit=${queryKey[1]}&offset=${queryKey[2]}&search=${queryKey[3]}`
  );
};
const useKeywordsList = (
  limit: any,
  offSet: any,
  currentTab: number,
  search?: any
) => {
  return useQuery(["keywords-list", limit, offSet, search], fetchKeywordsList, {
    select: (data: any) => {
      const totalPages = data?.data?.total_count;
      const keywordList = data?.data?.data.map((keyword: any) => {
        return {
          category_linked: keyword?.keyword_category_count,
          sub_category_linked: keyword?.keywords_subcategory_count,
          survey: keyword?.keyword_survey_count,
          reports_linked: keyword?.reports_linked,
          keyword_id: keyword?.keyword_id,
          name: keyword?.name,
          remarks: keyword?.remarks,
          description: keyword?.description,
          is_active: keyword?.is_active,
          deleted_at: new Date(keyword?.deleted_at).toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
          updated_at: new Date(keyword?.updated_at).toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
          created_at: new Date(keyword?.created_at).toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
        };
      });
      return (
        {
          totalPages,
          keywordList,
        } || undefined
      );
    },
    enabled: currentTab === 3,
  });
};
// Get Keyword list for select
const fetchKeywordListForSelect = () => {
  return apiCall.get(`${API_END_POINTS.ADMIN_GET_KEYWORD_LIST}`);
};
const useKeywordListForSelect = () => {
  return useQuery(["keyword-list-for-select"], fetchKeywordListForSelect, {
    select: (data: any) => {
      return data?.data?.data.map((keyword: any) => ({
        value: keyword.keyword_id,
        label: keyword.name,
      }));
    },
  });
};
// Add Keyword
const addKeyword = (data: any) => {
  return apiCall.post(API_END_POINTS.ADMIN_ADD_KEYWORD, data);
};
const useAddKeyword = (onSuccess: any, onError: any) => {
  return useMutation(addKeyword, {
    onSuccess: onSuccess,
    onError: onError,
  });
};
// Edit Keyword
const editKeyword = (data: any) => {
  const id = data.keyword_id;
  delete data.keyword_id;
  return apiCall.put(`${API_END_POINTS.ADMIN_EDIT_KEYWORD}/${id}/`, data);
};
const useEditKeyword = (onSuccess: any, onError: any) => {
  return useMutation(editKeyword, {
    onSuccess: onSuccess,
    onError: onError,
  });
};
// Delete Keyword
const deleteKeyword = (id: any) => {
  return apiCall.delete(`${API_END_POINTS.ADMIN_DELETE_KEYWORD}/${id}/`);
};
const useDeleteKeyword = (onSuccess: any, onError: any) => {
  return useMutation(deleteKeyword, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// SURVEY LIST
// Get SURVEY list
const fetchSurveyList = ({ queryKey }: any) => {
  return apiCall.get(`${API_END_POINTS.ADMIN_GET_SURVEY_LIST}`);
};
const useSurveyList = () => {
  return useQuery(["survey-list"], fetchSurveyList, {
    select: (data: any) => {
      return data;
    },
  });
};
// Get SURVEY list for select tag
const fetchSurveyListForSelect = ({ queryKey }: any) => {
  return apiCall.get(`${API_END_POINTS.ADMIN_GET_SURVEY_LIST}`);
};
const useSurveyListForSelect = () => {
  return useQuery(["survey-list-for-select"], fetchSurveyListForSelect, {
    select: (data: any) => {
      return data?.data?.map((survey: any) => ({
        label: survey?.name,
        value: survey?.survey_id,
      }));
    },
  });
};
// Assign Survey
const assignSurvey = (data: any) => {
  return apiCall.post(API_END_POINTS.ADMIN_ASSIGN_SURVEY, data);
};
const useAssignSurvey = (onSuccess: any, onError: any) => {
  return useMutation(assignSurvey, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// Delete Survey
const deleteSurvey = (id: any) => {
  return apiCall.delete(`${API_END_POINTS.ADMIN_DELETE_SURVEY}/${id}`);
};
const useDeleteSurvey = (onSuccess: any, onError: any) => {
  return useMutation(deleteSurvey, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// Reports LIST
// Get Reports list
const fetchReportsList = ({ queryKey }: any) => {
  return [
    { value: "test1", label: "test1" },
    { value: "test2", label: "test2" },
  ];
  // return apiCall.get(
  //   `${API_END_POINTS.ADMIN_GET_REPORTS_LIST}?search=${queryKey[1]}&limit=${queryKey[2]}&offset=${queryKey[3]}${queryKey[4]}`
  // );
};
const useReportsList = () => {
  return useQuery(["report-list"], fetchReportsList);
};
// Delete Reports
const deleteReports = (id: any) => {
  return apiCall.delete(`${API_END_POINTS.ADMIN_DELETE_REPORT}/${id}`);
};
const useDeleteReports = (onSuccess: any, onError: any) => {
  return useMutation(deleteReports, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// Get by Id
const fetchDetailsById = ({ queryKey }: any) => {
  let dataFrom =
    queryKey[3] === 1
      ? "category"
      : queryKey[3] === 2
      ? "subcategory"
      : "keyword";
  let dataType =
    queryKey[2] === 1
      ? "category/"
      : queryKey[2] === 2
      ? "subcategory/"
      : queryKey[2] === 3
      ? "keywords/"
      : queryKey[2] === 4
      ? "survey/"
      : queryKey[2] === 5
      ? "reports/"
      : "";
  if (!queryKey[1]) {
    return;
  }
  const limit = queryKey[4] === undefined ? "" : `?limit=${queryKey[4]}&`;
  const offSet = queryKey[5] === undefined ? "" : `offset=${queryKey[5]}`;
  return apiCall.get(
    `${API_END_POINTS.ADMIN_GET_DETAILS}${dataFrom}/${queryKey[1]}/${dataType}${limit}${offSet}`
  );
};
const useGetDetailsById = (
  id: any,
  dataType: number,
  dataFrom: number,
  limit?: any,
  offSet?: any
) => {
  return useQuery(
    ["details-by-id", id, dataType, dataFrom, limit, offSet],
    fetchDetailsById,
    {
      select: (data: any) => {
        const fetchedData = {
          categoryList: data?.data?.category || [],
          subCategoryList: data?.data?.subcategory || [],
          keywordsList: data?.data?.keywords || [],
          surveyList: data?.data?.survey || [],
          reportsList: data?.data?.reports || [],
          totalPage: data?.data?.pagination?.count || 0,
        };
        return fetchedData;
      },
    }
  );
};

//unlink from a list
const unlinkItem = (data: any) => {
  const { id, homeTab, itemToRemove } = data;
  let url;
  let type;
  if (homeTab === 1) {
    url = API_END_POINTS.ADMIN_UNLINK_CATEGORY;
    type = "category_remove";
  }
  if (homeTab === 2) {
    url = API_END_POINTS.ADMIN_UNLINK_SUBCATEGORY;
    type = "subcategory_remove";
  }
  if (homeTab === 3) {
    url = API_END_POINTS.ADMIN_UNLINK_KEYWORD;
    type = "keyword_remove";
  }

  return apiCall.put(`${url}/${id}/`, itemToRemove);
};
const useUnlinkItem = (onSuccess: any, onError: any) => {
  return useMutation(unlinkItem, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

export {
  useCategoriesList,
  useCategoriesListForSelect,
  useGetDetailsById,
  useGetById,
  useAddCategory,
  useEditCategory,
  useDeleteCategory,
  useSubCategoriesList,
  useSubCategoriesListForSelect,
  useAddSubCategory,
  useEditSubCategory,
  useDeleteSubCategory,
  useKeywordsList,
  useKeywordListForSelect,
  useAddKeyword,
  useEditKeyword,
  useDeleteKeyword,
  useSurveyList,
  useSurveyListForSelect,
  useAssignSurvey,
  useDeleteSurvey,
  useReportsList,
  useDeleteReports,
  useUnlinkItem,
};
