import styled from "styled-components";
import IconsBox from "../../ui-components/iconbox";
import React from "react";
import { bahasa, english } from "./text";
import { NavLink, useNavigate } from "react-router-dom";
import { LanguageContext } from "../../../store/language-context";
import { Dialog } from "primereact/dialog";
import { ButtonOutlined } from "../../common-styles";
import { ButtonBox } from "../../../pages/settings/components/edit-profile";
import { ModalContent } from "../../ui-components/success-modal";
import { AuthButton } from "../../../pages/user-login/components/login";
import { Tooltip } from "primereact/tooltip";
import myStorage from "../../../store/my-storage";
import { AUTH_PATHS, MENU_PATHS } from "../../../all-routes/paths";
import { useUserLogout } from "../../../api/user-api";
import { UserContext } from "../../../store/user-context";

interface IStyles {
  isNotification?: boolean;
  isCollapsed?: boolean;
}
const SideBarWrapper = styled.div<IStyles>`
  position: fixed;
  width: ${(props) =>
    props.isCollapsed ? "min(10rem, 100px)" : "min(25.1rem, 100%)"};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid var(--gray_400);
  transition: all 0.3s;
  background: var(--white_900);
  .line {
    width: 100%;
    height: 1px;
    background-color: var(--gray_450);
    margin: 1rem 0 2rem 0;
  }
`;
const ProfileWrapper = styled.div<IStyles>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.isCollapsed ? "column" : "row")};
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
  .user-box {
    display: flex;
    flex-direction: ${(props) => (props.isCollapsed ? "column" : "row")};
    justify-content: flex-start;
    align-items: center;
    :hover {
      cursor: pointer;
      opacity: 0.8;
    }
    .user-image {
      object-fit: cover;
      width: max(36px);
      height: max(36px);
      border-radius: 5rem;
    }
    .user-details {
      display: ${(props) => (props.isCollapsed ? "none" : "flex")};
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin: 0 0 0 0.8rem;
      .user-name {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 15px;
        letter-spacing: -0.02em;
      }
      .user-type {
        max-width: 60px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1rem;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: -0.02em;
        color: var(--gray_500);
      }
    }
  }
  .notification-box {
    position: relative;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
    padding: 1rem;
    border-radius: 5rem;
    transition: all 0.3s;
    :hover {
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
      cursor: pointer;
    }
    ::before {
      display: ${(props) => (props.isNotification ? "block" : "none")};
      position: absolute;
      content: "";
      top: 0.6rem;
      right: 0.8rem;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 5rem;
      background-color: var(--red_400);
    }
  }
`;

const MenuWrapper = styled.div<IStyles>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 4rem 0;
  .hover-popup {
    display: ${(props) => (props.isCollapsed ? "flex" : "none")};
    opacity: 0;
    transition: all 0.3s;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    width: 0rem;
    padding: 0.5rem 1.5rem;
    height: max(2rem, fit-content);
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 0.5rem;
    background-color: var(--black_850);
    color: var(--white_900);
  }
`;
const Menu = styled.div`
  width: 100%;
  max-height: 62vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  padding: 0 1.5rem;
  gap: 0.4rem;
  margin: 0 0 1rem 0;
  ::-webkit-scrollbar {
    width: 0px;
  }
  a {
    width: min(21.7rem, 100%);
    height: max(4.9rem, 100%);
  }
  .activeMenu {
    background-color: var(--blue_50);
    .icon-box {
      svg {
        path {
          stroke: var(--blue_600);
        }
      }
    }
    .label-box {
      h2 {
        color: var(--blue_600);
      }
    }
  }
`;
const MenuItem = styled.div<IStyles>`
  width: min(21.7rem, 100%);
  height: max(4.9rem, 100%);
  display: flex;
  position: relative;
  justify-content: ${(props) => (props.isCollapsed ? "center" : "flex-start")};
  align-items: center;
  padding: 1.6rem 0.2rem 1.6rem 1.6rem;
  border-radius: 1rem;
  transition: all 0.3s;
  .hover-popup {
    top: 1.5rem;
  }
  :hover {
    background-color: var(--blue_50);
    .icon-box {
      svg {
        path {
          stroke: var(--blue_600);
        }
      }
    }
    .label-box {
      h2 {
        color: var(--blue_600);
      }
    }
    .hover-popup {
      display: ${(props) => (props.isCollapsed ? "flex" : "none")};
      opacity: 1;
      width: max(15rem);
      right: -16rem;
    }
  }
  .label-box {
    display: ${(props) => (props.isCollapsed ? "none" : "block")};
    margin: 0 0 0 1.5rem;
    h2 {
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 17px;
      letter-spacing: 0em;
    }
  }
`;
const LogoutBox = styled.div<IStyles>`
  position: absolute;
  bottom: 10px;
  width: min(20.5rem, 100%);
  height: max(6.4rem, 64px);
  display: flex;
  /* margin: 2rem 0 0 0; */
  justify-content: ${(props) => (props.isCollapsed ? "center" : "flex-start")};
  align-items: center;
  padding: 1.6rem;
  border-radius: 1rem;
  transition: all 0.3s;
  background-color: var(--red_10);
  .label-box {
    display: ${(props) => (props.isCollapsed ? "none" : "block")};
    margin: 0 0 0 1.5rem;
    h2 {
      color: var(--red_600);
      font-weight: 500;
    }
  }
  .hover-popup {
    top: 2rem;
  }
  :hover {
    background-color: var(--blue_50);
    cursor: pointer;

    .hover-popup {
      display: ${(props) => (props.isCollapsed ? "flex" : "none")};
      opacity: 1;
      right: -6rem;
      width: max(5rem);
    }
  }
`;

interface Imenu {
  name: string;
  iconName: string;
  path: string;
}

const initialState: Imenu = {
  name: "",
  iconName: "",
  path: "",
};

interface IProps {
  isCollapsed: boolean;
  onViewNotificationBar: any;
}

const Sidebar = ({ isCollapsed, onViewNotificationBar }: IProps) => {
  const languageCtx = React.useContext(LanguageContext);
  const { userData } = React.useContext(UserContext);
  const storage = myStorage();
  const navigate = useNavigate();
  const { refetch } = useUserLogout();

  const [menuItems, setMenuItems] = React.useState<Imenu[]>([initialState]);
  const [logoutPopup, setLogoutPopup] = React.useState(false);
  const {
    menu,
    logout,
    logoutText,
    logoutBtnConfirmText,
    logoutBtnCancelText,
  } = languageCtx.isEnglish ? english : bahasa;

  React.useEffect(() => {
    setMenuItems(menu);
  }, [languageCtx]);

  const handleLogout = () => {
    storage.remove("accessToken");
    storage.remove("userDetails");
    storage.remove("refreshToken");
    setLogoutPopup(false);
    // refetch();
    navigate(AUTH_PATHS.LOGIN);
  };

  return (
    <SideBarWrapper isCollapsed={isCollapsed}>
      <ProfileWrapper isNotification={false} isCollapsed={isCollapsed}>
        <div className="user-box" onClick={() => navigate(MENU_PATHS.SETTINGS)}>
          <img
            className="user-image"
            src={userData?.user_image || "/images/test-user.jpg"}
            alt="user"
          />
          <span className="user-details">
            <p className="user-name">{userData?.name || ""}</p>
            <p className="user-type">{userData?.email || ""}</p>
          </span>
        </div>
        <span className="notification-box" onClick={onViewNotificationBar}>
          <IconsBox name="notification" />
        </span>
      </ProfileWrapper>
      <div className="line"></div>
      <MenuWrapper isCollapsed={isCollapsed}>
        <Menu>
          {menuItems.length > 0 &&
            menuItems.map((item, i) => (
              <NavLink key={i} to={item?.path}>
                {({ isActive }) => (
                  <MenuItem
                    id={`custom-target-${i}`}
                    className={isActive ? "activeMenu" : ``}
                    isCollapsed={isCollapsed}
                  >
                    <span className="icon-box">
                      <IconsBox name={item?.iconName} />
                    </span>
                    <span className="label-box">
                      <h2>{item?.name}</h2>
                    </span>
                    {/* <span className="hover-popup">{item.name}</span> */}
                    {isCollapsed && (
                      <Tooltip target={`#custom-target-${i}`}>
                        <h2
                          style={{
                            fontSize: "1.4rem",
                            fontWeight: "500",
                          }}
                        >
                          {item?.name}
                        </h2>
                      </Tooltip>
                    )}
                  </MenuItem>
                )}
              </NavLink>
            ))}
        </Menu>
        <LogoutBox
          isCollapsed={isCollapsed}
          onClick={() => setLogoutPopup(true)}
          id="custom-target-lo"
        >
          <span className="icon-box">
            <IconsBox name="logout" />
          </span>

          <span className="label-box">
            <h2>{logout}</h2>
          </span>
          {/* <span className="hover-popup">{logout}</span> */}
          {isCollapsed && (
            <Tooltip target={`#custom-target-lo`}>
              <h2
                style={{
                  fontSize: "1.4rem",
                  fontWeight: "500",
                }}
              >
                {logout}
              </h2>
            </Tooltip>
          )}
        </LogoutBox>
        <LogoutPopup
          isShow={logoutPopup}
          onHide={() => setLogoutPopup(false)}
          text1={logoutText}
          text2={logoutBtnConfirmText}
          text3={logoutBtnCancelText}
          onLogout={handleLogout}
        />
      </MenuWrapper>
    </SideBarWrapper>
  );
};

const CancelButton = styled(ButtonOutlined)`
  width: max(9.9rem, 99px);
  height: max(4.7rem, 47px);
`;
const ConfirmButton = styled(AuthButton)`
  width: max(14.8rem, 148px);
  height: max(4.7rem, 47px);
`;

interface ISubProps {
  isShow: boolean;
  onHide: any;
  text1: string;
  text2: string;
  text3: string;
  onLogout: any;
}
function LogoutPopup({
  isShow,
  onHide,
  text1,
  text2,
  text3,
  onLogout,
}: ISubProps) {
  return (
    <Dialog
      visible={isShow}
      onHide={onHide}
      style={{
        backgroundColor: "var(--white_900)",
        borderRadius: "1rem",
        border: "1px solid var(--gray_100)",
      }}
      maskStyle={{
        backgroundColor: "var(--black_050)",
      }}
      draggable={false}
    >
      <ModalContent style={{ alignItems: "center" }}>
      <IconsBox name="logout" />
        <p style={{ margin: "2rem 0", fontSize: "1.8rem" }}>{text1}</p>
        <ButtonBox>
          <CancelButton onClick={onHide}>{text3}</CancelButton>
          <ConfirmButton onClick={onLogout}>{text2}</ConfirmButton>
        </ButtonBox>
      </ModalContent>
    </Dialog>
  );
}

export default Sidebar;
