import React from "react";
import styled from "styled-components";
import {
  AuthFormWrapper,
  AuthWrapper,
  Button,
  ErrorWrapper,
  Input,
} from "../../../components/common-styles";
import { bahasa, english } from "./text";
import IconsBox from "../../../components/ui-components/iconbox";
import PasswordInput from "../../../components/ui-components/input/password-input";
import { Link, useNavigate } from "react-router-dom";
import { AUTH_PATHS, MENU_PATHS } from "../../../all-routes/paths";
import { LanguageContext } from "../../../store/language-context";
import { useUserLogin } from "../../../api/user-api";
import myStorage from "../../../store/my-storage";
import Toaster from "../../../components/ui-components/toaster";
import { UserContext } from "../../../store/user-context";
import SimpleCaptcha from "../../../components/ui-components/captcha";
interface IStyles {
  isError?: string | null;
}

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 2.4rem 0;
  .password-input {
    position: relative;
    .password-visible {
      position: absolute;
      right: 2rem;
      top: 2.2rem;
      cursor: pointer;
      z-index: 2;
    }
  }
  .error-msg {
    color: var(--red_600);
  }
`;

export const AuthInput = styled(Input)<IStyles>`
  box-shadow: ${(props) =>
    props.isError != null
      ? `0 0 0 1px var(--red_600)`
      : `0 0 0 1px var(--gray_500)`};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  margin: 0.7rem 0 0.5rem 0;
`;

const OtherControls = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.7rem 0 3.1rem 0;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .p-1 {
      color: var(--primary-900);
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      margin: 0;
    }
  }
  .p-2 {
    color: var(--blue_600);
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    margin: 0;
  }
`;

export const CheckInputBox = styled(Input)`
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
`;

export const AuthButton = styled(Button)`
  color: var(--white_900);
  background: linear-gradient(180deg, #1089e1 10.42%, #0468b1 100%);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
`;

// none style
interface FormData {
  email: string;
  password: string;
}

interface FormErrors {
  email: string | null;
  password: string | null;
  isError: boolean;
}

const initialFormData: FormData = {
  email: "",
  password: "",
};

const initialFormErrors: FormErrors = {
  email: null,
  password: null,
  isError: false,
};

function LoginComponent() {
  const languageCtx = React.useContext(LanguageContext);
  const {
    heading,
    subHeading,
    errorText,
    errorEmailText1,
    errorEmailText2,
    errorPasswordText1,
    input1Label,
    input1PlaceHolder,
    input2Label,
    input2PlaceHolder,
    rememberMe,
    forgotPass,
    buttonText,
  } = languageCtx.isEnglish ? english : bahasa;

  const userCtx = React.useContext(UserContext);
  const storage = myStorage();
  const navigate = useNavigate();
  const [captchaVerified, setCaptchaVerified] = React.useState(false);

  const onSuccess = async (res: any) => {
    if (res?.data?.role == "admin") {
      storage.set("accessToken", res?.data?.token.access);
      storage.set("refreshToken", res?.data?.token.refresh);
      await userCtx.setUserFn(res?.data?.user_id);
      setShowToast({
        status: true,
        type: "success",
        message: "Login Success!",
        reason: "",
      });
      setFormData(initialFormData);
      setFormErrors(initialFormErrors);
      navigate(MENU_PATHS.DASHBOARD);
    } else {
      setShowToast({
        status: true,
        type: "warn",
        message: "Access denied!",
        reason: "User not authorized!!",
      });
      return;
    }
  };
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: "Login Failed!",
      reason: res?.response?.data?.message,
    });
  };
  const { mutate: userLogin, isLoading } = useUserLogin(onSuccess, onError);

  const [formData, setFormData] = React.useState<FormData>(initialFormData);
  const [formErrors, setFormErrors] =
    React.useState<FormErrors>(initialFormErrors);
  const [isRememberMeChecked, setIsRememberMeChecked] = React.useState(false);
  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    if (name === "password") {
      let isValue = value.slice(0, 15);
      setFormData((prevState) => ({ ...prevState, [name]: isValue }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
    setFormErrors((prevState) => ({ ...prevState, [name]: null }));
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const errors: FormErrors = {
      email: null,
      password: null,
      isError: false,
    };
    let isValid = true;

    if (!formData.email) {
      errors.email = errorEmailText1;
      isValid = false;
    } else if (
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email.toLowerCase())
    ) {
      errors.email = errorEmailText2;
      isValid = false;
    }

    // Validate password
    if (!formData.password) {
      errors.password = errorPasswordText1;
      isValid = false;
    }

    if (!isValid) {
      setFormErrors(errors);
    } else {
      // Submit the form data here
      await userLogin({
        username: formData.email,
        password: formData.password,
      });
    }
    setShowToast({
      status: false,
      type: "",
      message: "",
      reason: "",
    });
  }

  const handleCaptchaVerify = (isVerified: boolean) => {
    setCaptchaVerified(isVerified);
  };

  const handleError = (err: any) => {
    console.error("Captcha error:", err);
  };

  return (
    <AuthWrapper>
      <Toaster
        showToast={showToast.status}
        severity={showToast.type}
        summary={showToast.message}
        detail={showToast.reason}
      />
      <h1 className="heading">{heading}</h1>
      <p className="sub-heading">{subHeading}</p>
      {formErrors.isError ? (
        <ErrorWrapper>
          <IconsBox name="doubt" />
          <p className="error-text error-text-css">{errorText}</p>
        </ErrorWrapper>
      ) : (
        <></>
      )}

      <AuthFormWrapper onSubmit={handleSubmit}>
        <InputWrapper>
          <label htmlFor="email">{input1Label}</label>
          <AuthInput
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder={input1PlaceHolder}
            isError={formErrors.email}
          />
          {formErrors.email && (
            <span className="error-msg">{errorEmailText1}</span>
          )}
        </InputWrapper>
        <PasswordInput
          isLabel={input2Label}
          isValue={formData.password}
          onChange={handleInputChange}
          isPlaceholder={input2PlaceHolder}
          isError={formErrors.password ? errorPasswordText1 : null}
        />
        <OtherControls>
          <span>
            <CheckInputBox
              onChange={(e) => setIsRememberMeChecked(e.target.checked)}
              type="checkbox"
            />
            <p className="p-1">{rememberMe}</p>
          </span>
          <Link to={AUTH_PATHS.RESET}>
            <p className="p-2">{forgotPass}</p>
          </Link>
        </OtherControls>
        <SimpleCaptcha onVerify={handleCaptchaVerify} />
        <AuthButton disabled={isLoading || !captchaVerified} type="submit">
          {buttonText}
        </AuthButton>
      </AuthFormWrapper>
    </AuthWrapper>
  );
}

export default LoginComponent;
