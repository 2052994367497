import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import styled from "styled-components";
import { TableWrapper } from "../../../../../components/common-styles";
import React from "react";
import IconsBox from "../../../../../components/ui-components/iconbox";
import { OverlayPanel } from "primereact/overlaypanel";
import { useNavigate } from "react-router-dom";
import { ADMIN_MENU_PATHS } from "../../../../../all-routes/paths";
import DeleteUser from "./delete-user";
import { bahasa, english } from "../text";

const Container = styled(TableWrapper)`
  thead {
    tr {
      th {
        :first-child {
          justify-content: flex-start !important;
          div {
            margin: 0 0 0 2rem !important;
          }
        }
      }
    }
  }
  tbody {
    tr {
      td {
        :first-child {
          justify-content: flex-start !important;
          margin: 0 0 0 2rem !important;
        }
      }
    }
  }
  .more-icon {
    cursor: pointer;
  }
`;
const NameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  .file-image {
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 5rem;
  }
`;
export const MoreMenuWrapper = styled.div`
  .more-icon {
    :hover {
      svg {
        path {
          stroke: var(--blue_500) !important;
          fill: var(--blue_500) !important;
        }
      }
    }
  }
`;
export const MoreMenu = styled.div`
  position: relative;
  width: fit-content;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.8rem;
  border-radius: 0.5rem;
  ::before {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: -1.6rem;
    right: -0.6rem;
    border-radius: 0.2rem;
    transform: rotate(-45deg);
    background-color: var(--white_900);
    box-shadow: 1px -2px 1px -1px rgba(0, 0, 0, 0.1);
  }
  .more-item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.4rem;
    padding: 0.5rem;
    .view-text,
    .trash-text {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0.5px;
      margin: 0 0 0 1rem;
    }
    .trash-text {
      color: var(--red_600);
    }
    :hover {
      cursor: pointer;
      background-color: var(--light_gray);
    }
  }
`;

interface IProps {
  isEnglish: boolean;
  data: any;
  onGetUsers: any;
}

const UsersList = ({ isEnglish, data, onGetUsers }: IProps) => {
  const {
    tName,
    tEmail,
    tFilesAdded,
    tSurvey,
    tDate,
    tAccessedDate,
    tAction,
    viewTxt,
    deleteTxt,
  } = isEnglish ? english : bahasa;
  const navigate = useNavigate();
  const moreRef = React.useRef<any>(null);
  const [selectedFiles, setSelectedFiles] = React.useState<any[]>([]);
  const [uniqueField, setUniqueField] = React.useState("");
  const [userDeleteModal, setDeleteUserModal] = React.useState(false);

  function handleRowClick(event: any) {
    setUniqueField(event?.data?.um_user_id);
    const cellNum = event?.originalEvent?.target?.cellIndex;
    if (cellNum != undefined || cellNum < 6) {
      navigate(
        `${ADMIN_MENU_PATHS.USER_MANAGEMENT}${ADMIN_MENU_PATHS.USER_MANAGEMENT_USER_DETAILS}?${event?.data?.um_user_id}`
      );
    }
  }

  const moreTemplate = () => {
    let uniqueId = uniqueField;
    return (
      <MoreMenuWrapper>
        <span className="more-icon" onClick={(e) => moreRef.current.toggle(e)}>
          <IconsBox name="three-dots-v" />
        </span>
        <OverlayPanel ref={moreRef}>
          <MoreMenu>
            <div
              className="more-item"
              onClick={(event: any) => {
                event.stopPropagation();
                moreRef.current.toggle(false);
                navigate(
                  `${ADMIN_MENU_PATHS.USER_MANAGEMENT}${ADMIN_MENU_PATHS.USER_MANAGEMENT_USER_DETAILS}?${uniqueId}`
                );
              }}
            >
              <IconsBox name="visibility" />
              <p className="view-text">{viewTxt}</p>
            </div>
            <div
              className="more-item"
              onClick={(event: any) => {
                event.stopPropagation();
                moreRef.current.toggle(false);
                setDeleteUserModal(true);
              }}
            >
              <IconsBox name="trash" />
              <p className="trash-text">{deleteTxt}</p>
            </div>
          </MoreMenu>
        </OverlayPanel>
      </MoreMenuWrapper>
    );
  };

  const nameTemplate = (data: any) => {
    return (
      <NameWrapper>
        <img
          className="file-image"
          src={data?.user_image || "/images/test-user.jpg"}
          alt="test"
        />
        {data.name}
      </NameWrapper>
    );
  };

  return (
    <Container>
      <DeleteUser
        showModal={userDeleteModal}
        onHideModal={() => setDeleteUserModal(false)}
        isEnglish={isEnglish}
        userId={uniqueField}
        isSamePage={true}
        onGetUsers={onGetUsers}
      />
      <DataTable
        value={data}
        // selectionMode="checkbox"
        // selection={selectedFiles}
        // onSelectionChange={(e: any) => setSelectedFiles(e.value)}
        onRowClick={handleRowClick}
        rowHover={true}
      >
        {/* <Column selectionMode="multiple" exportable={false}></Column> */}
        <Column field="name" body={nameTemplate} header={tName}></Column>
        <Column field="email" header={tEmail}></Column>
        <Column field="file_user_count" header={tFilesAdded}></Column>
        <Column field="survey_user_count" header={tSurvey}></Column>
        <Column field="created_at" header={tDate}></Column>
        <Column field="updated_at" header={tAccessedDate}></Column>
        <Column header={tAction} body={moreTemplate}></Column>
      </DataTable>
    </Container>
  );
};
export default UsersList;
