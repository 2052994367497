import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import styled from "styled-components";
import React from "react";
import IconsBox from "../../../../../components/ui-components/iconbox";
import { OverlayPanel } from "primereact/overlaypanel";
import DeleteModal from "./delete-modal";
import Spinner from "../../../../../components/ui-components/spinner";
import { LoaderWrapper } from "../../../../../components/common-styles";
import ViewScrappedData from "../ViewScrappedData";

export const Container = styled.div`
  width: 100%;
  table {
    thead {
      tr {
        th {
          background-color: var(--blue_50) !important;
          font-size: 1.2rem;
          font-weight: 500 !important;
          line-height: 15px;
          letter-spacing: 0em;
          color: var(--gray_600) !important;
          padding: 2rem 0rem !important;
          :first-child {
            display: flex;
            padding: 2.5rem 1rem 2rem 3.5rem !important;
            border-radius: 1rem 0 0 0 !important;
          }
          :last-child {
            border-radius: 0 1rem 0 0 !important;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: -0.02em;
          padding: 2rem 0 !important;
          :first-child {
            padding: 2.5rem 1rem 2rem 3.5rem !important;
          }
        }
      }
    }
  }
  .more-icon {
    cursor: pointer;
  }
`;

const HeaderBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 0 0.5rem 0;
  .heading {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
  }
  .see-all {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    :hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
`;

const MoreMenuWrapper = styled.div`
  .more-icon {
    :hover {
      svg {
        path {
          stroke: var(--blue_500) !important;
          fill: var(--blue_500) !important;
        }
      }
    }
  }
`;
export const MoreMenu = styled.div`
  position: relative;
  width: fit-content;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.8rem;
  border-radius: 0.5rem;
  ::before {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: -1.6rem;
    left: -0.6rem;
    border-radius: 0.2rem;
    transform: rotate(-45deg);
    background-color: var(--white_900);
    box-shadow: 1px -2px 1px -1px rgba(0, 0, 0, 0.1);
  }
  .more-item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.4rem;
    padding: 0.5rem;
    .view-text,
    .trash-text {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0.5px;
      margin: 0 0 0 1rem;
    }
    .trash-text {
      color: var(--red_600);
    }
    :hover {
      cursor: pointer;
      background-color: var(--light_gray);
    }
  }
`;

interface IProps {
  isEnglish: boolean;
  isLoading: boolean;
  data: any;
  text1: string;
  text2: string;
  text3: string;
  text4: string;
  text5: string;
  text6: string;
  text7: string;
  onFetchData: any;
  onHandleSeeAll: any;
}

const MonitoringList = ({
  isEnglish,
  isLoading,
  data,
  text1,
  text2,
  text3,
  text4,
  text5,
  text6,
  text7,
  onFetchData,
  onHandleSeeAll,
}: IProps) => {
  const moreRef = React.useRef<any>(null);
  const [rowData, setRowData] = React.useState<any>("");
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [viewModal, setViewModal] = React.useState(false);
  const [seeAllToggle, setSeeAllToggle] = React.useState(false);

  // set row data by click on a row
  function handleRowClick(event: any) {
    setRowData(event?.data);
  }

  const moreTemplate = () => {
    return (
      <MoreMenuWrapper>
        <span className="more-icon" onClick={(e) => moreRef.current.toggle(e)}>
          <IconsBox name="three-dots-v" />
        </span>
        <OverlayPanel ref={moreRef}>
          <MoreMenu>
            <div
              className="more-item"
              onClick={(event: any) => {
                event.stopPropagation();
                moreRef.current.toggle(false);
                setViewModal(true);
              }}
            >
              <IconsBox name="visibility" />
              <p className="view-text">{text7}</p>
            </div>
            <div
              className="more-item"
              onClick={(event: any) => {
                event.stopPropagation();
                moreRef.current.toggle(false);
                setDeleteModal(true);
              }}
            >
              <IconsBox name="trash" />
              <p className="trash-text">{text5}</p>
            </div>
          </MoreMenu>
        </OverlayPanel>
      </MoreMenuWrapper>
    );
  };

  return (
    <Container>
      {/* Modals */}
      <DeleteModal
        showModal={deleteModal}
        onHideModal={() => setDeleteModal(false)}
        isEnglish={isEnglish}
        onFetchData={onFetchData}
        data={rowData}
      />
      <ViewScrappedData
        showModal={viewModal}
        onHideModal={() => setViewModal(false)}
        isEnglish={isEnglish}
        id={rowData?.id}
      />
      {/* Modals */}

      <HeaderBox>
        <p className="heading">{text1}</p>
        <p
          onClick={() => {
            setSeeAllToggle(!seeAllToggle);
            onHandleSeeAll(seeAllToggle);
          }}
          className="see-all"
        >
          {seeAllToggle ? text6 : text2}
        </p>
      </HeaderBox>
      {isLoading ? (
        <LoaderWrapper>
          <Spinner />
        </LoaderWrapper>
      ) : (
        <DataTable value={data} onRowClick={handleRowClick}>
          <Column field="url" header={text3}></Column>
          <Column header={text4} body={moreTemplate}></Column>
        </DataTable>
      )}
    </Container>
  );
};
export default MonitoringList;
