const myPaths: any = {
  Settings: "Pengaturan",
  "Manage files": "Kelola File",
  "User management": "Manajemen pengguna",
  "Media monitoring": "Pemantauan Media",
  "Category management": "Manajemen kategori",
  Keywords: "Kata kunci",
  Category: "Kategori",
  "Sub category": "Sub Kategori",
};

const mySubPaths: any = {
  "Edit profile": "Sunting profil",
  "Change password": "Ganti kata sandi",
  "Upload file": "Unggah data",
  "Detail page": "Halaman detail",
  Keywords: "Kata kunci",
  Category: "Kategori",
  "Sub category": "Sub Kategori",
};

export { myPaths, mySubPaths };
