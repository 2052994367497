import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { AuthButton, AuthInput } from "../../../../user-login/components/login";
import CountrySelector from "../../../../../components/ui-components/select/country-code";
import {
  ButtonOutlined,
  Select,
} from "../../../../../components/common-styles";
import { bahasa, english } from "../text";
import React from "react";
import {
  useInviteUser,
  useUserRoles,
  useVillageList,
} from "../../../../../api/admin-api/user-management";
import Toaster from "../../../../../components/ui-components/toaster";

const Heading = styled.h1`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 2.5rem 0 3rem 0;
`;
const Content = styled.form`
  max-width: 80rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  /* margin: 2.3rem 0 0 0; */
  .child-div {
    margin: 2.5rem 0 0 0;
    flex: 50%;
  }
`;
const InputBox = styled.div`
  position: relative;
  max-width: 38.6rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0rem;
  .label {
    color: var(--black_900);
    font-size: 1.2rem;
    line-height: 15px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 0.8rem 0;
  }
  .error-msg {
    position: absolute;
    bottom: -15px;
    color: var(--red_600);
    margin: 0.5rem 0 0 0;
  }
`;
const Input = styled(AuthInput)`
  max-width: 38.6rem;
  margin: 0;
`;
const SelectBox = styled(Select)`
  width: 100%;
  min-height: 46px;
  cursor: pointer;
  option {
    :hover {
      cursor: pointer;
      margin: 1rem 0;
    }
  }
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.6rem;
  margin: 3.6rem 0 0 0;
`;

const CancelButton = styled(ButtonOutlined)`
  width: max(10rem, 100px);
  height: max(4.7rem, 47px);
`;
const SubmitButton = styled(AuthButton)`
  width: max(12rem, 120px);
  height: max(4.7rem, 47px);
`;

export const PhoneInputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;

export const PhoneInput = styled(AuthInput)`
  width: 262px;
`;

// none style
interface FormData {
  name: string;
  phone: number | string;
  country_code: number | string;
  email: string;
  role: string;
  village_id: string;
}

interface FormErrors {
  name: string | null;
  phone: number | string | null;
  country_code: number | string | null;
  email: string | null;
  role: string | null;
  village_id: string | null;
  isError: boolean;
}

const initialFormData: FormData = {
  name: "",
  phone: "",
  country_code: "",
  email: "",
  role: "",
  village_id: "",
};

const initialFormErrors: FormErrors = {
  name: null,
  phone: null,
  country_code: null,
  email: null,
  role: null,
  village_id: null,
  isError: false,
};
interface Iprops {
  showModal: boolean;
  onHideModal: any;
  isEnglish: boolean;
}
const AddUser = ({ showModal, onHideModal, isEnglish }: Iprops) => {
  const {
    addUserHeading,
    addUserInputLabel1,
    addUserInputLabel2,
    addUserInputLabel3,
    addUserInputLabel4,
    addUserInputLabel5,
    addUserInputPlaceholder1,
    addUserInputPlaceholder2,
    addUserInputPlaceholder3,
    addUserInputPlaceholder4,
    addUserInputPlaceholder5,
    errorEmailText1,
    errorEmailText2,
    errorRoleText,
    errorPhoneText,
    errorCodeText,
    errorVillageText,
    errorNameText,
    addUserBtnText1,
    addUserBtnText2,
  } = isEnglish ? english : bahasa;

  const [inputValues, setInputValues] =
    React.useState<FormData>(initialFormData);
  const [formErrors, setFormErrors] =
    React.useState<FormErrors>(initialFormErrors);
  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });
  const [showVillage, setShowVillage] = React.useState(true);
  const [adminId, setAdminId] = React.useState("");

  const onSuccess = (res: any) => {
    setShowToast({
      status: true,
      type: "success",
      message: "Invitation sent",
      reason: "",
    });
    setInputValues(initialFormData);
    setFormErrors(initialFormErrors);
    setTimeout(() => onHideModal(), 1500);
  };
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: "Failed Invitation!",
      reason: res?.response?.data?.message,
    });
  };
  const { mutate: inviteUser } = useInviteUser(onSuccess, onError);
  const { data: userRoles, isLoading, isFetching } = useUserRoles();
  const { data: villageList, isLoading: villageListLoading } = useVillageList();
  const headerTemplate = <Heading>{addUserHeading}</Heading>;

  React.useEffect(() => {
    if (userRoles) {
      const adminidFilter = userRoles.filter(
        (item: any) => item.label == "ADMIN"
      );
      if (adminidFilter.length) {
        setAdminId(adminidFilter[0].value);
      }
    }
  }, [isFetching]);
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (e.target.name == "role") {
      if (adminId == e.target.value) {
        setShowVillage(false);
        setInputValues((prevInputValues) => ({
          ...prevInputValues,
          village_id: "",
        }));
      } else {
        setShowVillage(true);
      }
    }
    const { name, value } = e.target;
    if (name === "name") {
      let isValue = value.slice(0, 30);
      setInputValues((prevState) => ({ ...prevState, [name]: isValue }));
      setFormErrors((prevState) => ({ ...prevState, [name]: null }));
      return;
    }
    if (name === "phone") {
      let isValue = value.slice(0, 15);
      setInputValues((prevState) => ({ ...prevState, [name]: isValue }));
      setFormErrors((prevState) => ({ ...prevState, [name]: null }));
      return;
    }
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
    }));
    setFormErrors((prevState) => ({ ...prevState, [name]: null }));
  };

  const handleCodeCHange = (val: any) => {
    setInputValues((prevState) => ({ ...prevState, country_code: val.value }));
    setFormErrors((prevState) => ({ ...prevState, country_code: null }));
  };

  async function handleSubmit(event: any) {
    event.preventDefault();
    const errors: FormErrors = {
      name: null,
      phone: null,
      country_code: null,
      email: null,
      role: null,
      village_id: null,
      isError: false,
    };
    let isValid = true;

    // Validate
    if (!inputValues.email) {
      errors.email = errorEmailText1;
      isValid = false;
    } else if (
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValues.email.toLowerCase())
    ) {
      errors.email = errorEmailText2;
      isValid = false;
    }
    if (!inputValues.role) {
      errors.role = errorRoleText;
      isValid = false;
    }
    if (!inputValues.phone) {
      errors.phone = errorPhoneText;
      isValid = false;
    }
    if (!inputValues.country_code) {
      errors.country_code = errorCodeText;
      isValid = false;
    }
    if (!inputValues.village_id && showVillage) {
      errors.village_id = errorVillageText;
      isValid = false;
    }
    // Validate password
    if (!inputValues.name) {
      errors.name = errorNameText;
      isValid = false;
    }

    if (!isValid) {
      setFormErrors(errors);
    } else {
      let data = JSON.parse(JSON.stringify(inputValues));
      if (!data.village_id || data.village_id == "") {
        delete data.village_id;
      }
      // Submit the form data here
      await inviteUser(data);
    }
    setShowToast({
      status: false,
      type: "",
      message: "",
      reason: "",
    });
  }

  return (
    <>
      <Toaster
        showToast={showToast.status}
        severity={showToast.type}
        summary={showToast.message}
        detail={showToast.reason}
      />
      <Dialog
        header={headerTemplate}
        visible={showModal}
        onHide={onHideModal}
        style={{
          backgroundColor: "var(--white_900)",
          padding: "0rem 4rem 6rem 4rem",
          borderRadius: "1rem",
          border: "1px solid var(--gray_100)",
        }}
        maskStyle={{
          backgroundColor: "var(--black_050)",
        }}
        draggable={false}
      >
        <Content onSubmit={handleSubmit}>
          <InputBox className="child-div">
            <label className="label">{addUserInputLabel1}*</label>
            <Input
              type="text"
              name="name"
              value={inputValues.name}
              placeholder={addUserInputPlaceholder1}
              onChange={handleInputChange}
            />
            {formErrors.name && (
              <span className="error-msg">{formErrors.name}</span>
            )}
          </InputBox>

          <InputBox className="child-div">
            <label className="label">{addUserInputLabel2}*</label>
            <SelectBox
              name="role"
              value={inputValues.role}
              onChange={handleInputChange}
            >
              <option value={""}>{addUserInputPlaceholder2}</option>
              {!isLoading &&
                userRoles.map(({ value, label }: any) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
            </SelectBox>
            {formErrors.role && (
              <span className="error-msg">{formErrors.role}</span>
            )}
          </InputBox>

          <InputBox className="child-div">
            <label className="label">{addUserInputLabel3}*</label>
            <Input
              type="email"
              name="email"
              value={inputValues.email}
              placeholder={addUserInputPlaceholder3}
              onChange={handleInputChange}
            />
            {formErrors.email && (
              <span className="error-msg">{formErrors.email}</span>
            )}
          </InputBox>
          <InputBox className="child-div">
            <label className="label">{addUserInputLabel4}*</label>
            {/* <Input
              type="text"
              name="phone"
              value={inputValues.phone}
              placeholder={addUserInputPlaceholder4}
              onChange={handleInputChange}
            /> */}
            <PhoneInputWrapper>
              <CountrySelector
                onChange={(val: any) => handleCodeCHange(val)}
                isCode={false}
              />
              <PhoneInput
                name={"phone"}
                value={inputValues.phone}
                onChange={handleInputChange}
                type="number"
                placeholder={addUserInputPlaceholder4}
              />
            </PhoneInputWrapper>
            {formErrors.phone && (
              <span className="error-msg">{formErrors.phone}</span>
            )}
            {formErrors.country_code && (
              <span className="error-msg">{formErrors.country_code}</span>
            )}
          </InputBox>
          {showVillage && (
            <InputBox className="child-div">
              <label className="label">{addUserInputLabel5}*</label>
              <SelectBox
                name="village_id"
                value={inputValues.village_id}
                onChange={handleInputChange}
              >
                <option value={""}>{addUserInputPlaceholder5}</option>
                {!villageListLoading &&
                  villageList &&
                  villageList.map(({ value, label }: any) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
              </SelectBox>
              {formErrors.village_id && (
                <span className="error-msg">{formErrors.village_id}</span>
              )}
            </InputBox>
          )}
          <Footer>
            <CancelButton onClick={onHideModal}>{addUserBtnText1}</CancelButton>
            <SubmitButton type="submit">{addUserBtnText2}</SubmitButton>
          </Footer>
        </Content>{" "}
      </Dialog>
    </>
  );
};

export default AddUser;
