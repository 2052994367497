import styled from "styled-components";
import React from "react";
import RSelect from "react-select";
import { InputWrapper } from "../../../pages/user-login/components/login";

const SelectWrapper = styled(InputWrapper)`
  margin: 0 !important;
  .label-style {
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 15px;
    margin: 2.4rem 0 0.8rem 0 !important;
  }
  .r-select {
    min-width: 100%;
    > div {
      min-height: 46px;
      border-radius: 1rem;
      padding: 0;
      cursor: pointer;
    }
    div {
      font-family: var(--font_family);
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 17px;
      color: var(--gray_800);
      letter-spacing: -0.02em;
      ::-webkit-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
        display: none;
      }
    }
  }
`;

type OptionProp = {
  value: any;
  label: any;
};
const initialOptions: OptionProp = {
  value: "",
  label: "",
};
type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  options: OptionProp[];
  onChange: any;
  name: any;
  placeholder?: any;
  value?: any;
  isMultiple?: boolean;
  isEdit?: boolean;
  optionsLoading?: boolean;
  isLabel?: string;
};

const ReactSelect: React.FC<SelectProps> = ({
  options,
  onChange,
  name,
  placeholder,
  value,
  isMultiple,
  isEdit,
  isLabel,
  optionsLoading,
  ...props
}) => {
  const [isValue, setIsValue] = React.useState<OptionProp[]>(value);
  const [isOptions, setIsOptions] = React.useState<OptionProp[]>(options);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isEdit && isMultiple && options.length && value.length) {
      handleFilterOptions();
    }
    if (isEdit) {
      setIsValue(value);
    } else {
      setIsOptions(options);
      setIsValue(value);
    }
  }, [isEdit, optionsLoading]);

  const handleFilterOptions = () => {
    setIsLoading(true);
    const newOptions: any = [];
    const newValues: any = [];
    options?.filter((option: any) => {
      let flag = true;
      for (let i = 0; i < value.length; i++) {
        if (value[i].value === option.value) {
          flag = false;
          break;
        }
      }
      if (flag) {
        newOptions.push(option);
      } else {
        newValues.push(option);
      }
    });
    setIsOptions(newOptions);
    setIsValue(newValues);
    setIsLoading(false);
  };

  return (
    <SelectWrapper className="rs-wrapper">
      <label htmlFor="my-select" className="label-style">
        {isLabel}
      </label>
      {isLoading ? null : (
        <RSelect
          options={isOptions}
          value={isValue}
          onChange={(e: any) => {
            if (isMultiple) {
              let itemArray: any = [];
              e?.map((item: any) => {
                if (item?.value !== "") {
                  itemArray.push(item?.value);
                }
              });
              onChange({ target: { name, value: itemArray } });
            } else {
              onChange({ target: { name, value: e?.value } });
            }
            setIsValue(e);
          }}
          placeholder={placeholder}
          isMulti={isMultiple}
          className="r-select"
          components={{
            IndicatorSeparator: () => null,
          }}
          styles={{
            dropdownIndicator: (provided) => ({
              ...provided,
              svg: {
                fill: "var(--blue_600)",
              },
              padding: "0 0.8rem 0 0",
            }),
          }}
        />
      )}
    </SelectWrapper>
  );
};

export default React.memo(ReactSelect);
