import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import styled from "styled-components";
import React from "react";
import IconsBox from "../../../../../components/ui-components/iconbox";
import { OverlayPanel } from "primereact/overlaypanel";
import { bahasa, english } from "../text";
import { MoreMenuWrapper } from "../../../user-management/components/sub-components/users-table";
import {
  Container,
  MoreMenu,
} from "../../../media-monitoring/components/sub-components/monitoring-list";
import { PaginationWrapper } from "../../../user-management/components/users";
import PaginationManger from "../../../../../components/ui-components/pagination";
import UnLink from "./unlink-modal";

const TableContainer = styled(Container)`
  margin: 2.8rem 0 0 0;
  width: 100%;
  .table-heading {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    margin: 0 0 1.6rem 0;
  }
`;

const SurveyTitle = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  .title {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.02em;
    text-align: left;
  }
  .active-survey,
  .inactive-survey {
    border-radius: 2.6rem;
    padding: 0.2rem 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.035em;
    color: var(--white_900);
  }
  .active-survey {
    background-color: var(--green_400);
  }
  .inactive-survey {
    background-color: var(--red_600);
  }
`;

interface IProps {
  isEnglish: boolean;
  data: any;
  homeTab: number;
  activeTab: number;
  onGetList: any;
  offSet: number;
  limit: number;
  onPageChange: any;
  onUnlink: any;
  id: any;
  totalPages: number;
}

const SurveyList = ({
  isEnglish,
  data,
  homeTab,
  activeTab,
  onGetList,
  offSet,
  limit,
  onPageChange,
  onUnlink,
  id,
  totalPages
}: IProps) => {
  const {
    dpSurveyMainText,
    dpSurveyTH1,
    tableName8,
    activeText,
    deactiveText,
    deleteText,
    removeText,
  } = isEnglish ? english : bahasa;

  const moreRef = React.useRef<any>(null);

  const [rowData, setRowData] = React.useState<any>("");
  const [deleteModal, setDeleteModal] = React.useState(false);

  //   to get row data
  function handleRowClick(event: any) {
    setRowData(event?.data);
  }
  const headerTemplate = (tableRowData: any) => {
    return (
      <SurveyTitle>
        {tableRowData?.name}
        {tableRowData?.is_active ? (
          <p className="active-survey">{activeText}</p>
        ) : (
          <p className="inactive-survey">{deactiveText}</p>
        )}
      </SurveyTitle>
    );
  };
  const moreTemplate = () => {
    return (
      <MoreMenuWrapper>
        <span className="more-icon" onClick={(e) => moreRef.current.toggle(e)}>
          <IconsBox name="three-dots-v" />
        </span>
        <OverlayPanel ref={moreRef}>
          <MoreMenu>
            <div
              className="more-item"
              onClick={async (event: any) => {
                event.stopPropagation();
                const itemToRemove =
                  activeTab === 1
                    ? { category_ids: [rowData?.category_id] }
                    : activeTab === 2
                    ? { subcategory_ids: [rowData?.sub_category_id] }
                    : activeTab === 3
                    ? { keyword_ids: [rowData?.keyword_id] }
                    : activeTab === 4
                    ? { survey_ids: [rowData?.survey_id] }
                    : { report_id: [rowData?.reports_id] };
                await onUnlink({ id, homeTab, itemToRemove });
                moreRef.current.toggle(false);
                // setDeleteModal(true);
              }}
            >
              <IconsBox name="trash" />
              <p className="trash-text">{removeText}</p>
            </div>
          </MoreMenu>
        </OverlayPanel>
      </MoreMenuWrapper>
    );
  };

  return (
    <TableContainer>
      <UnLink
        showModal={deleteModal}
        onHideModal={() => setDeleteModal(false)}
        tabIndex={activeTab}
        isEnglish={isEnglish}
        onFetchData={onGetList}
        data={rowData}
      />
      <h2 className="table-heading">
        {dpSurveyMainText} ({data?.length || 0})
      </h2>

      <DataTable value={data} onRowClick={handleRowClick}>
        <Column
          field="name"
          body={headerTemplate}
          header={dpSurveyTH1}
        ></Column>
        <Column field="created_at" header={""}></Column>
        <Column field="updated_at" header={""}></Column>
        <Column header={tableName8} body={moreTemplate}></Column>
      </DataTable>
      <PaginationWrapper>
        <PaginationManger
          onPageChange={onPageChange}
          row={limit}
          page={offSet}
          totalItems={totalPages}
        />
      </PaginationWrapper>
    </TableContainer>
  );
};

export default SurveyList;
