import { Dialog } from "primereact/dialog";
import { bahasa, english } from "../text";
import React from "react";
import Toaster from "../../../../../components/ui-components/toaster";
import ReactSelect from "./react-select";
import {
  useAddSubCategory,
  useCategoriesListForSelect,
  useKeywordListForSelect,
} from "../../../../../api/admin-api/category-management";
import {
  CancelButton,
  Content,
  Footer,
  Heading,
  Input,
  InputBox,
  SubmitButton,
} from "./create-category";
import IconsBox from "../../../../../components/ui-components/iconbox";

// none style
interface FormData {
  name: string;
  description: string;
  s1: string;
  s2: any;
}

interface FormErrors {
  name: string | null | boolean;
  description: string | null | boolean;
  s1: string | null | boolean;
  s2: string | null | boolean;
  isError: any;
}

const initialFormData: FormData = {
  name: "",
  description: "",
  s1: "",
  s2: [],
};

const initialFormErrors: FormErrors = {
  name: null,
  description: null,
  s1: null,
  s2: null,
  isError: false,
};
interface Iprops {
  showModal: boolean;
  onHideModal: any;
  isEnglish: boolean;
  tabIndex: number;
  editMood?: boolean;
  editData?: any;
  onRefetch?: any;
}
const CreateSubCategory = ({
  showModal,
  onHideModal,
  isEnglish,
  tabIndex,
  editMood,
  editData,
  onRefetch,
}: Iprops) => {
  const {
    categoryDescriptionPH,
    subCategoryHeading,
    subCategoryNameLabel1,
    subCategoryNamePH,
    subCategoryNameLabel2,
    subCategoryNameLabel3,
    subCategoryNameLabel4,
    categoryKeywordsPH,
    requiredError,
    saveText,
    cancelText,
    createdSubCatText,
    smtgWentWrgText,
  } = isEnglish ? english : bahasa;

  const onSuccess = (res: any) => {
    setShowToast({
      status: true,
      type: "success",
      message: createdSubCatText,
      reason: "",
    });
    setInputValues(initialFormData);
    setFormErrors(initialFormErrors);
    onRefetch();
    onHideModal();
  };
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: smtgWentWrgText,
      reason: res?.response?.data?.message,
    });
  };

  // add subcategory api
  const { mutate: addSubCategory, isLoading } = useAddSubCategory(
    onSuccess,
    onError
  );
  // get list for select tag
  const { data: categoriesList, isLoading: loadingCat } =
    useCategoriesListForSelect();
  const { data: keywordList, isLoading: loadingKeywords } =
    useKeywordListForSelect();

  const [inputValues, setInputValues] =
    React.useState<FormData>(initialFormData);
  const [formErrors, setFormErrors] =
    React.useState<FormErrors>(initialFormErrors);
  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "name") {
      let isValue = value.slice(0, 25);
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [name]: isValue,
      }));
    }
    if (name === "description") {
      let isValue = value.slice(0, 25);
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [name]: isValue,
      }));
    } else {
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [name]: value,
      }));
    }
    setFormErrors((prevState) => ({ ...prevState, [name]: null }));
  };

  async function handleSubmit() {
    const errors: FormErrors = {
      name: null,
      description: null,
      s1: null,
      s2: null,
      isError: false,
    };
    let isValid = true;

    // Validate
    if (!inputValues.name) {
      errors.name = true;
      isValid = false;
    }
    if (!inputValues.description) {
      errors.description = true;
      isValid = false;
    }
    // if (!inputValues.s1) {
    //   errors.s1 = true;
    //   isValid = false;
    // }
    // if (!inputValues.s2.length) {
    //   errors.s2 = true;
    //   isValid = false;
    // }

    if (!isValid) {
      setFormErrors(errors);
    } else {
      // Submit the form data here
      const formData = {
        name: inputValues?.name,
        description: inputValues?.description,
        category: inputValues?.s1,
        keyword_ids: inputValues?.s2,
      };

      await addSubCategory(formData);
    }
    setShowToast({
      status: false,
      type: "",
      message: "",
      reason: "",
    });
  }

  const headerTemplate = <Heading>{subCategoryHeading}</Heading>;
  return (
    <>
      <Toaster
        showToast={showToast.status}
        severity={showToast.type}
        summary={showToast.message}
        detail={showToast.reason}
      />
      <Dialog
        header={headerTemplate}
        visible={showModal}
        onHide={() => {
          if (!editMood) {
            setInputValues(initialFormData);
          }
          onHideModal();
        }}
        style={{
          backgroundColor: "var(--white_900)",
          padding: "1rem",
          borderRadius: "1rem",
          border: "1px solid var(--gray_100)",
        }}
        maskStyle={{
          backgroundColor: "var(--black_050)",
        }}
        draggable={false}
        closeIcon={<IconsBox name="close-icon" />}
      >
        <Content>
          <InputBox className="child-div">
            <label className="label">{subCategoryNameLabel1}</label>
            <Input
              type="text"
              name="name"
              value={inputValues?.name?.slice(0, 25)}
              placeholder={subCategoryNamePH}
              onChange={handleInputChange}
            />
            {formErrors.name && (
              <span className="error-msg">{requiredError}</span>
            )}
          </InputBox>

          <InputBox className="child-div">
            <label className="label">{subCategoryNameLabel2}</label>
            <Input
              type="text"
              name="description"
              value={inputValues.description}
              placeholder={categoryDescriptionPH}
              onChange={handleInputChange}
            />
            {formErrors.description && (
              <span className="error-msg">{requiredError}</span>
            )}
          </InputBox>

          <InputBox className="child-div">
            <label className="label">{subCategoryNameLabel3}</label>
            <ReactSelect
              options={
                categoriesList && categoriesList.length ? categoriesList : []
              }
              name="s1"
              onChange={handleInputChange}
              placeholder={categoryKeywordsPH}
            />
            {formErrors.s1 && (
              <span className="error-msg error-msg-2">{requiredError}</span>
            )}
          </InputBox>

          <InputBox className="child-div">
            <label className="label">{subCategoryNameLabel4}</label>
            <ReactSelect
              options={keywordList && keywordList.length ? keywordList : []}
              name="s2"
              onChange={handleInputChange}
              placeholder={categoryKeywordsPH}
              isMultiple={true}
            />
            {formErrors.s2 && (
              <span className="error-msg error-msg-2">{requiredError}</span>
            )}
          </InputBox>

          <Footer>
            <CancelButton
              onClick={() => {
                setInputValues(initialFormData);
                onHideModal();
              }}
            >
              {cancelText}
            </CancelButton>
            <SubmitButton onClick={handleSubmit}>{saveText}</SubmitButton>
          </Footer>
        </Content>{" "}
      </Dialog>
    </>
  );
};

export default CreateSubCategory;
