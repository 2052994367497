import { Dialog } from "primereact/dialog";
import { bahasa, english } from "../text";
import React from "react";
import ReactSelect from "./react-select";
import {
  useCategoriesListForSelect,
  useKeywordListForSelect,
} from "../../../../../api/admin-api/category-management";
import {
  CancelButton,
  Content,
  Footer,
  Heading,
  SubmitButton,
} from "./create-category";
import { SelectBox, SelectBox2 } from "./assign-category";
import ConfirmAssign from "./confirm-assign";
import IconsBox from "../../../../../components/ui-components/iconbox";

// none style
interface FormData {
  s1: string;
  s2: any;
  s3: string;
}
interface FormErrors {
  s1: string | null | boolean;
  s2: string | null | boolean;
  s3: string | null | boolean;
  isError: any;
}
const initialFormErrors: FormErrors = {
  s1: null,
  s2: null,
  s3: null,
  isError: false,
};
const initialFormData: FormData = {
  s1: "",
  s2: [],
  s3: "",
};

interface Iprops {
  showModal: boolean;
  onHideModal: any;
  isEnglish: boolean;
  tabIndex: number;
  editData?: any;
  surveyList: any;
  onAssign: any;
}
const AssignSubCategory = ({
  showModal,
  onHideModal,
  isEnglish,
  tabIndex,
  editData,
  surveyList,
  onAssign,
}: Iprops) => {
  const {
    assignHeading,
    assignCategoryLabel,
    assignKeywordLabel,
    assignSurveyLabel,
    assignKeywordPH,
    assignCategoryPH,
    assignSurveyPH,
    assignSubCatConfirmSubHeading,
    requiredError,
    assignText,
    cancelText,
  } = isEnglish ? english : bahasa;

  const { data: keywordList, isLoading: loadingKeywords } =
    useKeywordListForSelect();
  const { data: categoriesList, isLoading: loadingCat } =
    useCategoriesListForSelect();

  const [inputValues, setInputValues] =
    React.useState<FormData>(initialFormData);
  const [formErrors, setFormErrors] =
    React.useState<FormErrors>(initialFormErrors);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
    }));
    setFormErrors((prevState) => ({ ...prevState, [name]: null }));
  };

  function handleSubmit() {
    const errors: FormErrors = {
      s1: null,
      s2: null,
      s3: null,
      isError: false,
    };
    let isValid = true;

    // Validate
    if (!inputValues.s1) {
      errors.s1 = true;
      isValid = false;
    }
    if (!inputValues.s2.length) {
      errors.s2 = true;
      isValid = false;
    }
    if (!inputValues.s3) {
      errors.s3 = true;
      isValid = false;
    }

    if (!isValid) {
      setFormErrors(errors);
    } else {
      // confirm modal
      setShowConfirmModal(true);
    }
  }

  const handleConfirm = async () => {
    await onAssign({
      category_id: inputValues?.s1,
      keyword_ids: inputValues?.s2,
      survey_id: inputValues?.s3,
      subcategory_ids: [editData?.sub_category_id],
    });
    setShowConfirmModal(false);
    onHideModal();
    setInputValues(initialFormData);
  };

  const headerTemplate = <Heading>{assignHeading}</Heading>;
  return (
    <>
      {showConfirmModal && (
        <ConfirmAssign
          showModal={showConfirmModal}
          onHideModal={() => setShowConfirmModal(false)}
          isEnglish={isEnglish}
          onConfirm={handleConfirm}
          subHead={assignSubCatConfirmSubHeading}
          inputData={[
            { heading: assignSurveyLabel, subHeading: inputValues.s3 },
            { heading: assignCategoryLabel, subHeading: inputValues.s1 },
            { heading: assignKeywordLabel, subHeading: inputValues.s2 },
          ]}
          activeHomeTab={tabIndex}
          surveys={surveyList}
          categories={categoriesList}
          subCategories={[]}
          keywords={keywordList}
        />
      )}

      <Dialog
        header={headerTemplate}
        visible={showModal}
        onHide={onHideModal}
        style={{
          backgroundColor: "var(--white_900)",
          padding: "1rem",
          borderRadius: "1rem",
          border: "1px solid var(--gray_100)",
        }}
        maskStyle={{
          backgroundColor: "var(--black_050)",
        }}
        draggable={false}
        closeIcon={<IconsBox name="close-icon" />}
      >
        <Content>
          <SelectBox className="child-div">
            <label className="label">{assignCategoryLabel}</label>
            <ReactSelect
              options={
                categoriesList && categoriesList?.length ? categoriesList : []
              }
              name="s1"
              onChange={handleInputChange}
              placeholder={assignCategoryPH}
            />
            {formErrors.s1 && (
              <span className="error-msg error-msg-2">{requiredError}</span>
            )}
          </SelectBox>

          <SelectBox className="child-div">
            <label className="label">{assignKeywordLabel}</label>
            <ReactSelect
              options={keywordList && keywordList?.length ? keywordList : []}
              name="s2"
              onChange={handleInputChange}
              placeholder={assignKeywordPH}
              isMultiple={true}
            />
            {formErrors.s2 && (
              <span className="error-msg error-msg-2">{requiredError}</span>
            )}
          </SelectBox>

          <SelectBox2 className="">
            <label className="label">{assignSurveyLabel}</label>
            <ReactSelect
              options={surveyList || []}
              name="s3"
              onChange={handleInputChange}
              placeholder={assignSurveyPH}
            />
            {formErrors.s3 && (
              <span className="error-msg error-msg-2">{requiredError}</span>
            )}
          </SelectBox2>

          <Footer>
            <CancelButton onClick={onHideModal}>{cancelText}</CancelButton>
            <SubmitButton onClick={handleSubmit}>{assignText}</SubmitButton>
          </Footer>
        </Content>{" "}
      </Dialog>
    </>
  );
};

export default AssignSubCategory;
