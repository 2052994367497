import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { AuthButton, AuthInput } from "../../../../user-login/components/login";
import { ButtonOutlined } from "../../../../../components/common-styles";
import { bahasa, english } from "../text";
import React from "react";
import Toaster from "../../../../../components/ui-components/toaster";
import ReactSelect from "./react-select";
import {
  useEditCategory,
  useGetDetailsById,
  useKeywordListForSelect,
  useSubCategoriesListForSelect,
} from "../../../../../api/admin-api/category-management";
import IconsBox from "../../../../../components/ui-components/iconbox";

export const Heading = styled.h1`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 2.5rem 0 3rem 0;
`;
export const Content = styled.div`
  max-height: 41.1rem;
  width: 71.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem 0;
  /* margin: 2.3rem 0 0 0; */
  .child-div {
    margin: 2.5rem 0 0 0;
    flex: 50%;
  }
`;
export const InputBox = styled.div`
  position: relative;
  max-width: 29.6rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0rem;
  .label {
    color: var(--black_900);
    font-size: 1.2rem;
    line-height: 15px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 0.8rem 0;
  }
  .error-msg {
    position: absolute;
    bottom: -15px;
    color: var(--red_600);
    margin: 0.5rem 0 0 0;
  }
  .error-msg-2 {
    bottom: 6px;
  }
`;
export const Input = styled(AuthInput)`
  max-width: 29.6rem;
  margin: 0;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.6rem;
  margin: 3.6rem 0 0 0;
`;

export const CancelButton = styled(ButtonOutlined)`
  width: max(10rem, 100px);
  height: max(4.7rem, 47px);
`;
export const SubmitButton = styled(AuthButton)`
  width: max(12rem, 120px);
  height: max(4.7rem, 47px);
`;

// none style
interface FormData {
  name: string;
  description: string;
  s1: any;
  s2: any;
}
interface FormErrors {
  name: string | null | boolean;
  description: string | null | boolean;
  s1: string | null | boolean;
  s2: string | null | boolean;
  isError: any;
}
const initialFormErrors: FormErrors = {
  name: null,
  description: null,
  s1: null,
  s2: null,
  isError: false,
};

interface Iprops {
  showModal: boolean;
  onHideModal: any;
  isEnglish: boolean;
  tabIndex: number;
  editMood?: boolean;
  editData?: any;
  onRefetch?: any;
}
const EditCategory = ({
  showModal,
  onHideModal,
  isEnglish,
  tabIndex,
  editMood,
  editData,
  onRefetch,
}: Iprops) => {
  const {
    editCategory,
    categoryNameLabel,
    categoryNamePH,
    categoryDescriptionLabel,
    categoryDescriptionPH,
    categorySubCategoryLabel,
    categoryKeywordsLabel,
    categoryKeywordsPH,
    requiredError,
    updateText,
    cancelText,
    updatedText,
    smtgWentWrgText,
  } = isEnglish ? english : bahasa;

  // fetch list for select tag
  const { data: keywordList } = useKeywordListForSelect();
  const { data: subCategoriesList } = useSubCategoriesListForSelect();
  // fetch values
  const { data: subCategoryValues, refetch: getSubCategoryValue } =
    useGetDetailsById(editData.category_id, 2, 1);
  const { data: keywordsValues, refetch: getKeywordValues } = useGetDetailsById(
    editData.category_id,
    3,
    1
  );

  //   convert array of values into usable for select tag
  const filteredSubCategory = subCategoryValues?.subCategoryList?.map(
    (subCat: any) => ({
      value: subCat.sub_category_id,
      label: subCat.name,
    })
  );
  const filteredKeywords = keywordsValues?.keywordsList?.map(
    (keyword: any) => ({
      value: keyword.keyword_id,
      label: keyword.name,
    })
  );

  const onSuccess = (res: any) => {
    setShowToast({
      status: true,
      type: "success",
      message: updatedText,
      reason: "",
    });
    setFormErrors(initialFormErrors);
    onRefetch();
    getSubCategoryValue();
    getKeywordValues();
    onHideModal();
  };
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: smtgWentWrgText,
      reason: res?.response?.data?.message,
    });
  };
  // edit category api
  const { mutate: editCategoryFn, isLoading: editLoading } = useEditCategory(
    onSuccess,
    onError
  );

  let editableData: FormData = {
    name: editData?.name,
    description: editData?.description,
    s1: filteredSubCategory?.map((subCat: any) => subCat?.value) || [],
    s2: filteredKeywords?.map((key: any) => key?.value) || [],
  };
  
  React.useEffect(() => {
    setInputValues(editableData);
  }, [editMood, editData]);

  const [inputValues, setInputValues] = React.useState<FormData>(editableData);
  const [formErrors, setFormErrors] =
    React.useState<FormErrors>(initialFormErrors);
  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "name") {
      let isValue = value.slice(0, 25);
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [name]: isValue,
      }));
    }
    if (name === "description") {
      let isValue = value.slice(0, 25);
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [name]: isValue,
      }));
    } else {
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [name]: value,
      }));
    }
    setFormErrors((prevState) => ({ ...prevState, [name]: null }));
  };

  async function handleSubmit() {
    const errors: FormErrors = {
      name: null,
      description: null,
      s1: null,
      s2: null,
      isError: false,
    };
    let isValid = true;

    // Validate
    if (!inputValues.name) {
      errors.name = true;
      isValid = false;
    }
    if (!inputValues.description) {
      errors.description = true;
      isValid = false;
    }
    if (!inputValues.s1.length) {
      errors.s1 = true;
      isValid = false;
    }
    if (!inputValues.s2.length) {
      errors.s2 = true;
      isValid = false;
    }

    if (!isValid) {
      setFormErrors(errors);
    } else {
      // Submit the form data here
      const formData = {
        name: inputValues?.name,
        description: inputValues?.description,
        subcategory: inputValues?.s1,
        keyword: inputValues?.s2,
      };

      await editCategoryFn({
        ...formData,
        category_id: editData.category_id,
      });
    }
    setShowToast({
      status: false,
      type: "",
      message: "",
      reason: "",
    });
  }

  const headerTemplate = <Heading>{editCategory}</Heading>;
  return (
    <>
      <Toaster
        showToast={showToast.status}
        severity={showToast.type}
        summary={showToast.message}
        detail={showToast.reason}
      />
      <Dialog
        header={headerTemplate}
        visible={showModal}
        onHide={onHideModal}
        style={{
          backgroundColor: "var(--white_900)",
          padding: "1rem",
          borderRadius: "1rem",
          border: "1px solid var(--gray_100)",
        }}
        maskStyle={{
          backgroundColor: "var(--black_050)",
        }}
        draggable={false}
        closeIcon={<IconsBox name="close-icon" />}
      >
        <Content>
          <InputBox className="child-div">
            <label className="label">{categoryNameLabel}</label>
            <Input
              type="text"
              name="name"
              value={inputValues?.name?.slice(0, 25)}
              placeholder={categoryNamePH}
              onChange={handleInputChange}
            />
            {formErrors.name && (
              <span className="error-msg">{requiredError}</span>
            )}
          </InputBox>

          <InputBox className="child-div">
            <label className="label">{categoryDescriptionLabel}</label>
            <Input
              type="text"
              name="description"
              value={inputValues.description}
              placeholder={categoryDescriptionPH}
              onChange={handleInputChange}
            />
            {formErrors.description && (
              <span className="error-msg">{requiredError}</span>
            )}
          </InputBox>

          <InputBox className="child-div">
            <label className="label">{categorySubCategoryLabel}</label>
            <ReactSelect
              options={
                subCategoriesList && subCategoriesList.length
                  ? subCategoriesList
                  : []
              }
              name="s1"
              onChange={handleInputChange}
              placeholder={categoryKeywordsPH}
              isMultiple={true}
              isEdit={true}
              value={filteredSubCategory?.length ? filteredSubCategory : []}
            />
            {formErrors.s1 && (
              <span className="error-msg error-msg-2">{requiredError}</span>
            )}
          </InputBox>

          <InputBox className="child-div">
            <label className="label">{categoryKeywordsLabel}</label>
            <ReactSelect
              options={keywordList && keywordList.length ? keywordList : []}
              name="s2"
              onChange={handleInputChange}
              placeholder={categoryKeywordsPH}
              isMultiple={true}
              isEdit={true}
              value={filteredKeywords?.length ? filteredKeywords : []}
            />
            {formErrors.s2 && (
              <span className="error-msg error-msg-2">{requiredError}</span>
            )}
          </InputBox>

          <Footer>
            <CancelButton onClick={onHideModal}>{cancelText}</CancelButton>
            <SubmitButton disabled={editLoading} onClick={handleSubmit}>
              {updateText}
            </SubmitButton>
          </Footer>
        </Content>{" "}
      </Dialog>
    </>
  );
};

export default EditCategory;
