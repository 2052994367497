import styled from "styled-components";
import { bahasa, english } from "./text";
import React from "react";
import Switch from "../../ui-components/switch";
import myStorage from "../../../store/my-storage";
import { LanguageContext } from "../../../store/language-context";

interface IStyles {
  isEng: boolean;
}
const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  max-height: 1024px;
  background-image: url("/images/auth/bg-img.png");
  background-repeat: no-repeat;
  background-size: cover;
`;
const LeftImageSection = styled.div<IStyles>`
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 100vh;
  img {
    height: 100%;
    object-fit: contain;
  }
  h1 {
    position: absolute;
    width: ${(props) => (props.isEng ? "300px" : "80%")};
    left: 15%;
    bottom: 30%;
    font-size: 9.2rem;
    font-weight: 600;
    line-height: 87px;
    letter-spacing: -0.06em;
    text-align: left;
    color: var(--white_900);
  }
  span {
    position: absolute;
    width: 100%;
    left: 15%;
    bottom: 22%;
    p {
      font-size: 6rem;
      font-weight: 300;
      line-height: 84px;
      letter-spacing: -0.04em;
      text-align: left;
      color: var(--white_900);
    }
    .line {
      position: absolute;
      width: min(50%, 378px);
      height: 1px;
      left: 30%;
      bottom: 40%;
      background-color: var(--white_900);
    }
  }
  .des {
    position: absolute;
    bottom: 17%;
    font-size: 2rem;
    font-weight: 300;
    line-height: 25px;
    letter-spacing: -0.04em;
    text-align: left;
    color: var(--white_900);
    left: 15%;
    padding-right: 20px;
  }
`;
const RightSection = styled.div`
  position: relative;
  background-color: var(--white_900);
  width: max(552px, 60%);
  border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;
  // padding: 8rem 9.7rem 0 9.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .line-2 {
    width: min(359px, 100%);
    height: 1px;
    background-color: var(--gray_300);
    margin: 0 0 1rem 0;
  }
`;
const RightInner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 84vh;
  max-height: 809px;
`;
const LogoSection = styled.div`
  height: max(6rem, 3rem);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 2rem 0;
  img {
    height: max(6rem, 3rem);
  }
  .logo-2 {
    margin: 0 1rem 0 2.7rem;
  }
  p {
    width: max(23rem, 10rem);
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
  }
`;

const LanguageSwitcher = styled.div`
  width: min(359px, 100%);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  p {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

function CommonWrapper(props: any) {
  const storage = myStorage();

  const languageCtx = React.useContext(LanguageContext);
  const [isChecked, setIsChecked] = React.useState(!languageCtx.isEnglish);
  const { heading, subHeading, logoSideText } = languageCtx.isEnglish
    ? english
    : bahasa;
  const [captchaToken, setCaptchaToken] = React.useState<string | null>(null);

  const handleCaptchaVerify = (token: string) => {
    console.log(token);
  };
  return (
    <Wrapper>
      <LeftImageSection isEng={languageCtx.isEnglish}>
        <img src="/images/auth/bg-img.png" alt="bg-img" />
        <h1>{heading}</h1>
        <span>
          <p>{subHeading}</p>
          <div className="line"></div>
        </span>
        <div className="des">
          Bagian dari Social Innovation Platform untuk mendengar lebih dalam
          aspirasi masyarakat demi kemajuan desa.
        </div>
      </LeftImageSection>
      <RightSection>
        <RightInner>
          <LogoSection>
            <img
              src="/images/auth/undp-logo-1.png"
              alt="logo 1"
              className="logo-1"
            />
            <img
              src="/images/auth/undp-logo-2.png"
              alt="logo 2"
              className="logo-2"
            />
            <p>{logoSideText}</p>
          </LogoSection>
          {props.children}
          <div className="line-2"></div>
          <LanguageSwitcher>
            <Switch
              isOn={isChecked}
              handleToggle={() => {
                setIsChecked((prevState) => {
                  const isEng = prevState ? "false" : "true";
                  storage.set("bahasa", isEng);
                  languageCtx.trackChangesHandler();
                  return !prevState;
                });
              }}
            />
            <p>{"Indonesian"}</p>
          </LanguageSwitcher>
        </RightInner>
      </RightSection>
    </Wrapper>
  );
}
export default CommonWrapper;
