import { Route, Routes, Navigate } from "react-router-dom";
import UserAuthRoutes from "./user-auth";
import Login from "../pages/user-login";
import ResetPassword from "../pages/reset-password";
import ChangePassword from "../pages/change-password";
import SetPassword from "../pages/set-password";
import ProfileDetails from "../pages/profile-details";
import { ADMIN_MENU_PATHS, AUTH_PATHS, MENU_PATHS, OTHER_PATHS } from "./paths";
import TermsAndConditions from "../pages/terms-conditions";
import MainMenuRoutes from "./menu";
import Settings from "../pages/settings";
import ManageFiles from "../pages/manage-files";
import ErrorPage from "../pages/error-page";
import SignUp from "../pages/user-signup";
import SurveyManagement from "../pages/survey";
import Analytics from "../pages/analytics";
import Dashboard from "../pages/dashboard";
import UserManagement from "../pages/admin/user-management";
import CategoryManagement from "../pages/admin/category-management";
import MediaMonitoring from "../pages/admin/media-monitoring";
import SocialMedia from "../pages/social-media";
import Login2 from "../pages/user-login2";
import FAQ from "../pages/faq";
import Demo from "../pages/demo";

function AllRoutes() {
  return (
    <Routes>
      <Route path={OTHER_PATHS.ERROR_PAGE} element={<ErrorPage />} />
      <Route element={<UserAuthRoutes />}>
        <Route path={AUTH_PATHS.SIGNUP} element={<SignUp />} />
        <Route path={AUTH_PATHS.LOGIN} element={<Login />} />
        <Route path={AUTH_PATHS.LOGIN2} element={<Login2 />} />
        <Route path={AUTH_PATHS.RESET} element={<ResetPassword />} />
        <Route path={AUTH_PATHS.RESET_PASSWORD} element={<ChangePassword />} />
        <Route path={AUTH_PATHS.SET_PASSWORD} element={<SetPassword />} />
        {/* <Route path={AUTH_PATHS.PROFILE_SETUP} element={<ProfileDetails />} /> */}
      </Route>
      <Route
        path={OTHER_PATHS.TERMS_CONDITIONS}
        element={<TermsAndConditions />}
      />
      <Route element={<MainMenuRoutes />}>
        <Route path="" element={<Navigate to={MENU_PATHS.DASHBOARD} />} />
        <Route path={MENU_PATHS.DASHBOARD} element={<Dashboard />} />
        <Route
          path={`${MENU_PATHS.MANAGE_FILES}/*`}
          element={<ManageFiles />}
        />
        <Route path={MENU_PATHS.SURVEY} element={<SurveyManagement />} />
        <Route path={MENU_PATHS.ANALYTICS} element={<Analytics />} />
        <Route path={`${MENU_PATHS.SETTINGS}/*`} element={<Settings />} />
        {/* Admin */}
        <Route
          path={`${ADMIN_MENU_PATHS.USER_MANAGEMENT}/*`}
          element={<UserManagement />}
        />
        <Route
          path={`${ADMIN_MENU_PATHS.CATEGORY_MANAGEMENT}/*`}
          element={<CategoryManagement />}
        />
        <Route
          path={`${ADMIN_MENU_PATHS.MEDIA_MONITORING}/*`}
          element={<MediaMonitoring />}
        />
        <Route
          path={`${ADMIN_MENU_PATHS.SOCIAL_MEDIA}/*`}
          element={<SocialMedia />}
        />
         <Route
          path={`${ADMIN_MENU_PATHS.FAQ}/*`}
          element={<FAQ />}
        />
          <Route
              path={`${ADMIN_MENU_PATHS.DEMO}/*`}
              element={<Demo />} />
      </Route>
    </Routes>
  );
}

export default AllRoutes;
