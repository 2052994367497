import React from "react";
import styled from "styled-components";
import {
  AuthFormWrapper,
  AuthWrapper,
  Button,
  ErrorWrapper,
  Input,
} from "../../../components/common-styles";
import { bahasa, english } from "./text";
import IconsBox from "../../../components/ui-components/iconbox";
import PasswordInput from "../../../components/ui-components/input/password-input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUserSignUp } from "../../../api/user-api";
import { AuthTextArea } from "../../profile-details/components/profile-details";
import { RegexPatterns } from "../../../variables/regex";
import { OtherControls } from "../../change-password/components/change-password";
import {
  AUTH_PATHS,
  MENU_PATHS,
} from "../../../all-routes/paths";
import { LanguageContext } from "../../../store/language-context";
import Toaster from "../../../components/ui-components/toaster";

interface IStyles {
  isError?: string | null;
}

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 2.4rem 0;
  .password-input {
    position: relative;
    .password-visible {
      position: absolute;
      right: 2rem;
      top: 2.2rem;
      cursor: pointer;
      z-index: 2;
    }
  }
  .error-msg {
    color: var(--red_600);
  }
  .error-msg-a {
    color: var(--red_600);
    margin: 0.5rem 0 0 0;
  }
  .t-a-label {
    margin: 1rem 0 0 0;
  }
`;

const AuthInput = styled(Input)<IStyles>`
  box-shadow: ${(props) =>
    props.isError != null
      ? `0 0 0 1px var(--red_600)`
      : `0 0 0 1px var(--gray_500)`};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  margin: 0.7rem 0 0.5rem 0;
`;

const OtherControlz = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.7rem 0 3.1rem 0;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .p-1 {
      color: var(--primary-900);
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      margin: 0;
    }
  }
  .p-2 {
    color: var(--blue_600);
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    margin: 0;
  }
`;

const AuthButton = styled(Button)`
  color: var(--white_900);
  background: linear-gradient(180deg, #1089e1 10.42%, #0468b1 100%);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
`;

const TextArea = styled(AuthTextArea)``;

// none style
interface FormData {
  // name: string;
  // phone: number | string;
  email: string;
  password: string;
  invitation_code: string;
  address: string;
}

interface FormErrors {
  // name: string | null;
  // phone: number | string | null;
  email: string | null;
  password: string | null;
  invitation_code: string | null;
  address: string | null;
  isError: boolean;
  errorMessage: any;
}
interface PasswordRule {
  isUpperCase: boolean;
  isOneNumber: boolean;
  isSpecialChar: boolean;
  isMinLength: boolean;
}

const initialFormData: FormData = {
  // name: "",
  // phone: "",
  email: "",
  password: "",
  invitation_code: "",
  address: "",
};
const initialPasswordRules: PasswordRule = {
  isUpperCase: false,
  isOneNumber: false,
  isSpecialChar: false,
  isMinLength: false,
};

const initialFormErrors: FormErrors = {
  // name: null,
  // phone: null,
  email: null,
  password: null,
  invitation_code: null,
  address: null,
  isError: false,
  errorMessage: "",
};

function SignUpComponent() {
  const languageCtx = React.useContext(LanguageContext);
  const {
    heading,
    subHeading,
    errorText,
    errorEmailText1,
    errorEmailText2,
    errorAddressText5,
    errorPasswordText1,
    inputAddressLabel,
    inputAddressPlaceHolder,
    input1Label,
    input1PlaceHolder,
    input2Label,
    input2PlaceHolder,
    haveAccount,
    buttonText,
    passwordRules,
    passwordHint1,
    passwordHint2,
    passwordHint3,
    passwordHint4,
    errorPasswordText3,
  } = languageCtx.isEnglish ? english : bahasa;

  const navigate = useNavigate();
  const { search } = useLocation();

  const onSuccess = (res: any) => {
    // storage.set("accessToken", res?.data?.token.access);
    // storage.set("userDetails", JSON.stringify(res?.data));
    // userCtx.setUserFn(res?.data);
    setShowToast({
      status: true,
      type: "success",
      message: "SignUp Success!",
      reason: "Please login",
    });
    setFormData(initialFormData);
    setFormErrors(initialFormErrors);
    setTimeout(() => navigate(MENU_PATHS.DASHBOARD), 1500);
  };
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: "SignUp Failed!",
      reason: res?.response?.data?.message,
    });
  };
  const { mutate: userSignUp, isLoading } = useUserSignUp(onSuccess, onError);

  const [formData, setFormData] = React.useState<FormData>(initialFormData);
  const [formErrors, setFormErrors] =
    React.useState<FormErrors>(initialFormErrors);
  const [rules, setRules] = React.useState<PasswordRule>(initialPasswordRules);
  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });

  // password rules check
  React.useEffect(() => {
    handlePasswordValidity(formData.password);
  }, [formData.password]);

  function handlePasswordValidity(password: string) {
    const pattern = RegexPatterns.password;
    const isValidPassword = pattern.test(password);

    const rules = {
      isUpperCase: RegexPatterns.uppercase.test(password),
      isOneNumber: RegexPatterns.number.test(password),
      isSpecialChar: RegexPatterns.spacialChar.test(password),
      isMinLength: RegexPatterns.minLength8.test(password),
    };

    setRules(rules);
    if (isValidPassword) {
      setRules({
        isUpperCase: true,
        isOneNumber: true,
        isSpecialChar: true,
        isMinLength: true,
      });
    }
  }

  function handleInputChange(e: any) {
    const { name, value } = e.target;

    if (name === "password") {
      let isValue = value.slice(0, 15);
      setFormData((prevState) => ({ ...prevState, [name]: isValue }));
    }
    if (name === "address") {
      let isValue = value.slice(0, 150);
      setFormData((prevState) => ({ ...prevState, [name]: isValue }));
    }
    if (name === "email") {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
    setFormErrors((prevState) => ({
      ...prevState,
      isError: false,
      errorMessage: "",
      [name]: null,
    }));
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const errors: FormErrors = {
      // name: null,
      // phone: null,
      invitation_code: null,
      address: null,
      email: null,
      password: null,
      isError: false,
      errorMessage: "",
    };
    let isValid = true;

    // Validate email
    if (!formData.email) {
      errors.email = errorEmailText1;
      isValid = false;
    } else if (
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email.toLowerCase())
    ) {
      errors.email = errorEmailText2;
      isValid = false;
    }

    // Validate password
    if (!formData.password) {
      errors.password = errorPasswordText1;
      isValid = false;
    }
    // Validate password
    if (!formData.address) {
      errors.address = errorAddressText5;
      isValid = false;
    }

    if (!isValid) {
      setFormErrors(errors);
    } else {
      // check password meeting the rules
      let key: keyof typeof rules;
      for (key in rules) {
        if (!rules[key]) {
          errors.isError = true;
          errors.errorMessage = errorPasswordText3;
          setFormErrors(errors);
          return;
        }
      }
      const invitation_code = search.split("code=")[1];
      // Submit the form data here
      await userSignUp({
        ...formData,
        invitation_code,
      });
    }
    setShowToast({
      status: false,
      type: "",
      message: "",
      reason: "",
    });
  }

  return (
    <AuthWrapper>
      <Toaster
        showToast={showToast.status}
        severity={showToast.type}
        summary={showToast.message}
        detail={showToast.reason}
      />
      <h1 className="heading">{heading}</h1>
      <p className="sub-heading">{subHeading}</p>
      {formErrors.isError ? (
        <ErrorWrapper>
          <IconsBox name="doubt" />
          <p className="error-text error-text-css">{formErrors.errorMessage}</p>
        </ErrorWrapper>
      ) : (
        <></>
      )}

      <AuthFormWrapper onSubmit={handleSubmit}>
        <InputWrapper>
          <label htmlFor="email">{input1Label}</label>
          <AuthInput
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder={input1PlaceHolder}
            isError={formErrors.email}
          />
          {formErrors.email && (
            <span className="error-msg">{formErrors.email}</span>
          )}
        </InputWrapper>
        <PasswordInput
          isLabel={input2Label}
          isValue={formData.password}
          onChange={handleInputChange}
          isPlaceholder={input2PlaceHolder}
          isError={formErrors.password}
        />
        <OtherControls>
          <p>{passwordRules}</p>
          {[
            {
              iconName: rules.isUpperCase,
              text: passwordHint1,
            },
            {
              iconName: rules.isSpecialChar,
              text: passwordHint2,
            },
            {
              iconName: rules.isOneNumber,
              text: passwordHint3,
            },
            {
              iconName: rules.isMinLength,
              text: passwordHint4,
            },
          ].map((item, i) => (
            <span key={i}>
              {<IconsBox name={item.iconName ? "tick" : "round"} />}
              <p className="p-1">{item.text}</p>
            </span>
          ))}
        </OtherControls>
        <InputWrapper>
          <label className="t-a-label" htmlFor="address">
            {inputAddressLabel}
          </label>
          <TextArea
            name={"address"}
            value={formData.address}
            onChange={handleInputChange}
            placeholder={inputAddressPlaceHolder}
            isError={formErrors.address}
          ></TextArea>
          {formErrors.address && (
            <span className="error-msg-a">{formErrors.address}</span>
          )}
        </InputWrapper>
        <OtherControlz>
          <span></span>
          <Link to={AUTH_PATHS.LOGIN}>
            <p className="p-2">{haveAccount}</p>
          </Link>
        </OtherControlz>
        <AuthButton disabled={isLoading} type="submit">
          {buttonText}
        </AuthButton>
      </AuthFormWrapper>
    </AuthWrapper>
  );
}

export default SignUpComponent;
