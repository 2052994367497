import styled from "styled-components";
import { AuthInput } from "../../../user-login/components/login";
import { AuthButton } from "../../../user-login/components/login";
import { bahasa, english } from "./text";
import React from "react";
import MonitoringList from "./sub-components/monitoring-list";
import { PaginationWrapper } from "../../user-management/components/users";
import PaginationManger from "../../../../components/ui-components/pagination";
import { PaginatorPageChangeEvent } from "primereact/paginator";
import {
  useAddUrl,
  useUrlList,
} from "../../../../api/admin-api/media-monitoring";
import Toaster from "../../../../components/ui-components/toaster";

interface IStyles {
  isInputError?: boolean;
}
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const AddMediaBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 2.4rem;
  margin: 0 0 4rem 0;
  .input-wrap {
    position: relative;
    width: min(67.8rem, 100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 0.7rem;
    .label {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
    }
    .error-msg {
      position: absolute;
      bottom: -2.2rem;
      left: 0;
    }
  }
`;
const Input = styled(AuthInput)<IStyles>`
  width: 100%;
  margin: 0;
  box-shadow: ${(props) =>
    props.isInputError
      ? `0 0 0 1px var(--red_600)`
      : `0 0 0 1px var(--gray_500)`};
`;
const AddButton = styled(AuthButton)``;

interface IProps {
  isEnglish: boolean;
}
const LandingPage = ({ isEnglish }: IProps) => {
  const {
    inputLabel,
    btnTxt1,
    tableHeading,
    tableSeeAllText,
    tUrl,
    tAction,
    moreOptText1,
    errorText1,
    tableSeeLessText,
    noResultFoundText,
    moreOptViewTxt,
  } = isEnglish ? english : bahasa;

  const [inputValue, setInputValue] = React.useState("");
  const [inputError, setInputError] = React.useState(false);
  const [first, setFirst] = React.useState<number>(0);
  const [rows, setRows] = React.useState<number>(5);
  const [pageNo, setPageNo] = React.useState(1);

  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });

  // get url list from server
  const {
    data: resultsData,
    isLoading,
    isError,
    refetch: fetchUrlList,
  } = useUrlList(rows, first);
  // handle success response from api
  const onSuccess = (res: any) => {
    setFirst(0)
    fetchUrlList();
    setShowToast({
      status: true,
      type: "success",
      message: "URL added",
      reason: "",
    });
  };
  // handle error response from api
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: "Something went wrong!!",
      reason: "",
    });
  };
  // add url to the server
  const { mutate: addUrl } = useAddUrl(onSuccess, onError);

  // input changer handler
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setInputError(false);
  };
  // url submit handler
  const handleSubmit = async () => {
    const urlPattern =
      /^(https?|ftp):\/\/([^\s\/$.?#].[^\s]*)\.[^\s]{2,}|www\.([^\s\/$.?#].[^\s]*)\.[^\s]{2,}/;
    if (!urlPattern.test(inputValue)) {
      setInputError(true);
      return;
    }
    setInputValue("");
    await addUrl({
      url: inputValue,
    });
    setShowToast({
      status: false,
      type: "",
      message: "",
      reason: "",
    });
  };

  // Pagination Handler
  const handlePageChange = (e: PaginatorPageChangeEvent, index: number) => {
    setFirst(() => e.first);
    setRows(() => e.rows);
    setPageNo(() => e.first / e.rows + 1);
  };
  // See all handler
  const handleSeeAll = (seeAll: boolean) => {
    if (seeAll) {
      setFirst(() => 0);
      setRows(() => 5);
    } else {
      setFirst(() => 0);
      setRows(() => 0);
    }
  };

  if (isError) {
    return <h1>{noResultFoundText}</h1>;
  }

  return (
    <Wrapper>
      <Toaster
        showToast={showToast.status}
        severity={showToast.type}
        summary={showToast.message}
        detail={showToast.reason}
      />
      <AddMediaBox>
        <div className="input-wrap">
          <label className="label" htmlFor="url">
            {inputLabel}
          </label>
          <Input
            type="text"
            name="url"
            placeholder={inputLabel}
            value={inputValue}
            onChange={handleInputChange}
            isInputError={inputError}
          />
          {inputError && (
            <p className="error-text-css error-msg">{errorText1}</p>
          )}
        </div>
        <AddButton onClick={handleSubmit}>{btnTxt1}</AddButton>
      </AddMediaBox>
      <MonitoringList
        isEnglish={isEnglish}
        isLoading={isLoading}
        data={resultsData?.urlList}
        text1={tableHeading}
        text2={tableSeeAllText}
        text3={tUrl}
        text4={tAction}
        text5={moreOptText1}
        text6={tableSeeLessText}
        text7={moreOptViewTxt}
        onFetchData={fetchUrlList}
        onHandleSeeAll={handleSeeAll}
      />
      <PaginationWrapper>
        <PaginationManger
          onPageChange={handlePageChange}
          row={rows}
          page={first}
          totalItems={resultsData?.totalPages || 0}
        />
      </PaginationWrapper>
    </Wrapper>
  );
};

export default LandingPage;
