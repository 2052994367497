import styled from "styled-components";
import { ButtonOutlined, TextArea } from "../../../../components/common-styles";
import IconsBox from "../../../../components/ui-components/iconbox";
import { bahasa, english } from "./text";
import { AuthButton, AuthInput } from "../../../user-login/components/login";
import React from "react";
import UserFilesList from "./sub-components/user-files-list";
import { OverlayPanel } from "primereact/overlaypanel";
import { MoreMenu } from "./sub-components/users-table";
import DeleteUser from "./sub-components/delete-user";
import {
  useControlUser,
  useUpdateUser,
  useUserDetails,
} from "../../../../api/admin-api/user-management";
import { useLocation } from "react-router-dom";
import Spinner from "../../../../components/ui-components/spinner";
import Toaster from "../../../../components/ui-components/toaster";
import FileView from "../../../manage-files/components/sub-components/file-viewer";

interface IStyles {
  isEdit?: boolean;
}
const UserDetailWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const UserDetailsBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  @media screen and (max-width: 980px) {
    flex-wrap: wrap;
  }
`;
const UserInfo1 = styled.div<IStyles>`
  width: 100%;
  max-width: 37.3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--white_900);
  border-radius: 0.9rem;
  padding: 4rem 3rem;
  .user-name {
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: -0.02em;
    text-align: left;
    margin: 2.6rem 0 3.2rem 0;
  }
  .line {
    display: ${(props) => (props.isEdit ? "none" : "block")};
    width: 100%;
    background-color: var(--gray_350);
    height: 1px;
    margin: 2.8rem 0 1.6rem 0;
  }
`;
const UserImageBox = styled.div`
  .user-image {
    width: max(8.4rem, 84px);
    border-radius: 5rem;
    :hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.6rem;
  margin: 0 0 4rem 0;
`;
const EditButton = styled(ButtonOutlined)`
  width: max(9.6rem, 96px);
  height: max(3.6rem, 36px);
  background: var(--blue_50_a);
`;
const MoreButton = styled(ButtonOutlined)`
  width: max(5.4rem, 54px);
  height: max(3.6rem, 36px);
  background: var(--blue_50_a);
`;
const MoreMenu2 = styled(MoreMenu)`
  ::before {
    top: -1.8rem;
    left: -0.4rem;
  }
`;

const FieldsBox = styled.div<IStyles>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.isEdit ? "column" : "row")};
  align-items: ${(props) => (props.isEdit ? "flex-start" : "center")};
  gap: 0.4rem;
  .label {
    color: var(--gray_800);
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
  }
  .field-value {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.02em;
    text-align: left;
  }
`;
const InputBox = styled(AuthInput)`
  width: 100%;
  height: 2.5rem;
  margin: 0.5rem 0 2.6rem 0;
  border-radius: 0.2rem;
`;
const TextAreaBox = styled(TextArea)`
  width: 100%;
  box-shadow: 0 0 0 1px var(--gray_500);
  margin: 0.5rem 0 2.6rem 0;
  border-radius: 0.2rem;
`;

const UserInfo2 = styled.div`
  width: 100%;
  max-width: 73.3rem;
  background-color: var(--white_900);
  border-radius: 0.9rem;
  padding: 3.6rem 0 0 3.8rem;
  @media screen and (max-width: 980px) {
    flex-wrap: wrap;
    padding: 2rem;
  }
  .details-text {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: -0.02em;
    text-align: left;
  }
`;
const UploadedDetail = styled.form`
  max-width: 64rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 4rem 0 0 0;
  gap: 5%;
  .child-div {
    margin: 2.5rem 0 0 0;
    flex: 40%;
    min-width: 15rem;
  }
`;

const InlineBox = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0rem;
  .label {
    color: var(--gray_800);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
  }
  .value {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.02em;
    text-align: left;
    margin: 1.6rem 0 1.8rem 0;
  }
  .line {
    width: 100%;
    background-color: var(--gray_350);
    height: 1px;
  }
`;

const UserFilesHeading = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 0 2.2rem 0;
  .heading {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  .see-all {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    :hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;

interface IProps {
  isEnglish: boolean;
}
interface UserState {
  name: string;
  email: string;
  phone: string;
  country_code: string;
  address: string;
  dob: string;
  country: string;
}

function UserDetails({ isEnglish }: IProps) {
  const {
    editProfileBtnText,
    userNameLabel,
    userEmailLabel,
    userPhoneLabel,
    userDOBLabel,
    userAddressLabel,
    userCountryLabel,
    cancelText,
    updateText,
    detailsText,
    detailsSurveyText,
    detailsCreatedDate,
    detailsFiles,
    detailsLastDate,
    tableHeading,
    tableSeeAllText,
    tableSeeLessText,
    viewTxt,
    deleteTxt,
    surveyListText,
    moreOptText1,
    moreOptText2,
    moreOptText3,
    blockedText,
    unblockedText,
    smtgWentWrgText,
  } = isEnglish ? english : bahasa;

  const { search } = useLocation();

  const initialUserState: UserState = {
    name: "",
    email: "",
    phone: "",
    country_code: "",
    address: "",
    dob: "",
    country: "",
  };

  const userRef = React.useRef<any>(null);
  const [userState, setUserState] = React.useState<UserState>(initialUserState);
  const [editMode, setEditMode] = React.useState(false);
  const [currentFile, setCurrentFile] = React.useState<any>("");
  const [userDeleteModal, setDeleteUserModal] = React.useState(false);
  const [userStatus, setUserStatus] = React.useState<boolean>();
  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });
  const [showViewModal, setShowViewModal] = React.useState<boolean>(false);
  const [controlUploadsLimit, setControlUploadsLimit] = React.useState(true);

  // Get user details from server
  const {
    data: userDetails,
    isLoading,
    refetch,
  } = useUserDetails(search.substring(1));

  React.useEffect(() => {
    if (!isLoading) {
      if (userDetails?.status == 1) {
        setUserStatus(true);
      }
      if (userDetails?.status == 2) {
        setUserStatus(false);
      } else {
        setUserStatus(true);
      }
      setUserState({
        name: userDetails?.name,
        email: userDetails?.email,
        phone: userDetails?.country_code + userDetails?.phone,
        country_code: userDetails?.country_code,
        address: userDetails?.address,
        dob: userDetails?.dob,
        country: userDetails?.country,
      });
    }
    return () => {};
  }, [userDetails]);

  const onSuccess = (res: any) => {
    const msg = userStatus ? blockedText : unblockedText;
    setShowToast({
      status: true,
      type: "success",
      message: msg,
      reason: "",
    });
    refetch();
    userRef.current.toggle(false);
  };
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: smtgWentWrgText,
      reason: "",
    });
  };
  // user block & unblock handler
  const { mutate: controlUser } = useControlUser(onSuccess, onError);

  const onSuccess2 = (res: any) => {
    setShowToast({
      status: true,
      type: "success",
      message: "Updated user",
      reason: res?.data?.message,
    });
    refetch();
    setEditMode(false);
  };
  const onError2 = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: smtgWentWrgText,
      reason: res?.data?.message,
    });
  };
  const { mutate: updateUser } = useUpdateUser(onSuccess2, onError2);

  function handleInputChanges(event: any) {
    const { name, value } = event.target;

    if (name === "name") {
      let isValue = value.slice(0, 30);
      setUserState((prevState) => ({ ...prevState, [name]: isValue }));
    }
    if (name === "address") {
      let isValue = value.slice(0, 150);
      setUserState((prevState) => ({ ...prevState, [name]: isValue }));
    }
    if (name === "phone") {
      let isValue = value.slice(0, 10);
      setUserState((prevState) => ({ ...prevState, [name]: isValue }));
    }
    if (name === "email") {
      setUserState((prevState) => ({ ...prevState, [name]: value }));
    }
  }

  const handleUserControl = async () => {
    const { um_user_id } = userDetails;
    await controlUser({
      user_id: um_user_id,
      is_blocked: userStatus,
    });
    setShowToast({
      status: false,
      type: "",
      message: "",
      reason: "",
    });
  };

  const validateFields = (): boolean => {
    const { email, phone, address, dob, country, name } = userState;
    let isValid = true;

    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      isValid = false;
    }

    if (!phone || !/^[0-9]{10}$/.test(phone)) {
      isValid = false;
    }

    if (!name) {
      isValid = false;
    }
    if (!address) {
      isValid = false;
    }
    // if (!dob) {
    //   isValid = false;
    // }
    // if (!country) {
    //   isValid = false;
    // }

    return isValid;
  };

  async function handleUpdate() {
    if (validateFields()) {
      await updateUser({
        userId: userDetails.um_user_id,
        name: userState.name,
        country_code: userDetails?.country_code,
        email: userState.email,
        address: userState.address,
        phone: userState.phone,
      });
    }
    setShowToast({
      status: false,
      type: "",
      message: "",
      reason: "",
    });
  }

  const handleFileView = async (fileFromTable: any) => {
    await setCurrentFile(() => fileFromTable);
    setShowViewModal(true);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <UserDetailWrapper>
      <Toaster
        showToast={showToast.status}
        severity={showToast.type}
        summary={showToast.message}
        detail={showToast.reason}
      />
      <UserDetailsBox>
        <UserInfo1 isEdit={editMode}>
          <UserImageBox>
            <img
              className="user-image"
              src={userDetails?.user_image || "/images/test-user.jpg"}
              alt="user"
            />
          </UserImageBox>
          <h2 className="user-name">{userDetails?.name || "-"}</h2>
          <ButtonBox>
            <EditButton
              onClick={() => {
                setUserState((prevState) => ({
                  ...prevState,
                  name: userDetails?.name,
                  email: userDetails?.email,
                  phone: userDetails?.phone,
                  address: userDetails?.address,
                  dob: userDetails?.dob,
                  country: userDetails?.country,
                }));
                setEditMode(!editMode);
              }}
            >
              {editMode ? cancelText : editProfileBtnText}
            </EditButton>
            <MoreButton onClick={(e) => userRef.current.toggle(e)}>
              <IconsBox name="more-v-icon" />
            </MoreButton>
            {/* Drop down */}
            <OverlayPanel ref={userRef}>
              <MoreMenu2>
                <div className="more-item" onClick={handleUserControl}>
                  <IconsBox name="lock-icon" />
                  <p className="view-text">
                    {userStatus ? moreOptText1 : moreOptText3}
                  </p>
                </div>
                <div
                  className="more-item"
                  onClick={() => {
                    userRef.current.toggle(false);
                    setDeleteUserModal(true);
                  }}
                >
                  <IconsBox name="trash" />
                  <p className="trash-text">{moreOptText2}</p>
                </div>
              </MoreMenu2>
            </OverlayPanel>
            {/* modal */}
            <DeleteUser
              showModal={userDeleteModal}
              onHideModal={() => setDeleteUserModal(false)}
              isEnglish={isEnglish}
              userId={search.substring(1)}
              isSamePage={false}
            />
          </ButtonBox>

          {editMode ? (
            <FieldsBox isEdit={editMode}>
              <label className="label" htmlFor="">
                {userNameLabel}
              </label>
              <InputBox
                name={"name"}
                onChange={handleInputChanges}
                value={userState.name}
                type="text"
              />
            </FieldsBox>
          ) : null}
          <FieldsBox isEdit={editMode}>
            <label className="label" htmlFor="">
              {userEmailLabel}
            </label>
            {editMode ? (
              <InputBox
                name={"email"}
                onChange={handleInputChanges}
                value={userState.email}
                type="email"
              />
            ) : (
              <p className="field-value">{userDetails?.email || "-"}</p>
            )}
          </FieldsBox>
          <div className="line"></div>
          <FieldsBox isEdit={editMode}>
            <label className="label" htmlFor="">
              {userPhoneLabel}
            </label>
            {editMode ? (
              <InputBox
                name={"phone"}
                onChange={handleInputChanges}
                value={userState.phone}
                type="number"
              />
            ) : (
              <p className="field-value">{userDetails?.phone || "-"}</p>
            )}
          </FieldsBox>
          {/* <div className="line"></div> */}
          {/* <FieldsBox isEdit={editMode}>
            <label className="label" htmlFor="dob">
              {userDOBLabel}
            </label>
            {editMode ? (
              <InputBox
                name={"dob"}
                onChange={handleInputChanges}
                value={userState.dob}
                type="date"
              />
            ) : (
              <p className="field-value">{userDetails?.dob || ""}</p>
            )}
          </FieldsBox> */}
          <div className="line"></div>
          <FieldsBox isEdit={editMode}>
            <label className="label" htmlFor="address">
              {userAddressLabel}
            </label>
            {editMode ? (
              <TextAreaBox
                name={"address"}
                onChange={handleInputChanges}
                value={userState.address}
              />
            ) : (
              <p className="field-value">{userDetails?.address || "-"}</p>
            )}
          </FieldsBox>
          <div className="line"></div>
          {/* <FieldsBox isEdit={editMode}>
            <label className="label" htmlFor="">
              {userCountryLabel}
            </label>
            {editMode ? (
              <InputBox
                name={"country"}
                onChange={handleInputChanges}
                value={userState.country}
                type="text"
              />
            ) : (
              <p className="field-value">{userDetails?.country || ""}</p>
            )}
          </FieldsBox> */}
          {editMode ? (
            <AuthButton
              onClick={handleUpdate}
              style={{
                width: "100%",
              }}
            >
              {updateText}
            </AuthButton>
          ) : null}
        </UserInfo1>

        <UserInfo2>
          <h1 className="details-text">{detailsText}</h1>
          <UploadedDetail>
            <InlineBox className="child-div">
              <label className="label" htmlFor="">
                {detailsSurveyText}
              </label>
              <p className="value">{userDetails?.survey_count || "-"}</p>
              <div className="line"></div>
            </InlineBox>
            <InlineBox className="child-div">
              <label className="label" htmlFor="">
                {detailsCreatedDate}
              </label>
              <p className="value">{userDetails?.created_at || "-"}</p>
              <div className="line"></div>
            </InlineBox>
            <InlineBox className="child-div">
              <label className="label" htmlFor="">
                {detailsFiles}
              </label>
              <p className="value">{userDetails?.file_upload_count || "-"}</p>
              <div className="line"></div>
            </InlineBox>
            <InlineBox className="child-div">
              <label className="label" htmlFor="">
                {detailsLastDate}
              </label>
              <p className="value">{userDetails?.updated_at || "-"}</p>
              <div className="line"></div>
            </InlineBox>
          </UploadedDetail>
        </UserInfo2>
      </UserDetailsBox>

      <UserFilesHeading>
        <h2 className="heading">{tableHeading}</h2>
        <p
          className="see-all"
          onClick={() => setControlUploadsLimit(!controlUploadsLimit)}
        >
          {controlUploadsLimit ? tableSeeAllText : tableSeeLessText}
        </p>
      </UserFilesHeading>
      {userDetails?.uploaded_files?.length ? (
        <UserFilesList
          isEnglish={isEnglish}
          data={
            controlUploadsLimit
              ? userDetails?.uploaded_files?.slice(0, 6)
              : userDetails?.uploaded_files
          }
          text1={viewTxt}
          text2={deleteTxt}
          onShowFile={handleFileView}
          onRefetch={refetch}
          isLoading={isLoading}
        />
      ) : null}
      <FileView
        showModal={showViewModal}
        onHideModal={() => setShowViewModal(false)}
        type={currentFile?.file_format}
        data={currentFile}
      />
      <UserFilesHeading>
        <h2 className="heading">{surveyListText}</h2>
        <p className="see-all">{tableSeeAllText}</p>
      </UserFilesHeading>
    </UserDetailWrapper>
  );
}

export default UserDetails;
