import apiCall from "..";
import { useQuery, useMutation } from "react-query";
import { API_END_POINTS } from "../api-end-points";
import myStorage from "../../store/my-storage";
import axios from "axios";
import { APP_ENV } from "../config";

const storage = myStorage();

// Login user
const userLogin = (userData: any) => {
  sessionStorage.removeItem('province');
  sessionStorage.removeItem('district');
  sessionStorage.removeItem('village');

  return apiCall.post(API_END_POINTS.USER_LOGIN, userData);
};
const useUserLogin = (onSuccess: any, onError: any) => {
  return useMutation(userLogin, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// SignUp user
const userSignUp = (userData: any) => {
  return apiCall.post(API_END_POINTS.USER_REGISTER, userData);
};
const useUserSignUp = (onSuccess: any, onError: any) => {
  return useMutation(userSignUp, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// Get current user
const fetchCurrentUser = ({ queryKey }: any) => {
  return apiCall.get(`${API_END_POINTS.GET_USER_DETAILS}${queryKey[1]}`);
};
const useCurrentUser = () => {
  const { um_user_id } = JSON.parse(storage.get("userDetails") || "");
  return useQuery(["current-user", um_user_id], fetchCurrentUser, {
    select: (data) => {
      return data?.data?.data;
    },
  });
};

// forgot password
const forgotPassword = (userEmail: any) => {
  return apiCall.post(API_END_POINTS.USER_FORGOT_PASSWORD, userEmail);
};
const useForgotPassword = (onSuccess: any, onError: any) => {
  return useMutation(forgotPassword, {
    onSuccess: onSuccess,
    onError: onError,
  });
};
// reset password
const resetPassword = (userData: any) => {
  return apiCall.post(API_END_POINTS.USER_RESET_PASSWORD, userData);
};
const useResetPassword = (onSuccess: any, onError: any) => {
  return useMutation(resetPassword, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// profile setup
const setProfile = (userData: any) => {
  return apiCall.post(API_END_POINTS.USER_UPDATE_PROFILE, userData);
};
const useSetProfile = (onSuccess: any, onError: any) => {
  return useMutation(setProfile, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// update user profile
const updateProfile = (userData: any) => {
  const userId = userData.userId;
  delete userData.userId;
  return axios({
    method: "put",
    url: `${APP_ENV.BASE_URL}${API_END_POINTS.USER_UPDATE_PROFILE}/${userId}`,
    data: userData,
    headers: {
      Authorization: `Bearer ${storage.get("accessToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};
const useUpdateProfile = (onSuccess: any, onError: any) => {
  return useMutation(updateProfile, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// update password
const updatePassword = (userData: any) => {
  return apiCall.post(API_END_POINTS.USER_UPDATE_PASSWORD, userData);
};
const useUpdatePassword = (onSuccess: any, onError: any) => {
  return useMutation(updatePassword, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// notifications
const getNotifications = ({ queryKey }: any) => {
  return [];
  // return apiCall.get(`${API_END_POINTS.GET_NOTIFICATIONS}${queryKey[1]}`);
};
const useGetNotifications = () => {
  const { um_user_id } = JSON.parse(storage.get("userDetails") || "");
  return useQuery(["current-user", um_user_id], getNotifications);
};

// logout
const userLogout = async () => {
  sessionStorage.removeItem('province');
  sessionStorage.removeItem('district');
  sessionStorage.removeItem('village');
  const res = await apiCall.post(API_END_POINTS.USER_LOGOUT, {});
  return;
};
const useUserLogout = () => {
  return useQuery("user-logout", userLogout, { enabled: false });
};

const uploadFile = (data: any) => {
  return axios({
    method: "post",
    url: `${APP_ENV.BASE_URL}${API_END_POINTS.UPLOAD_FILE}`,
    data: data,
    headers: {
      Authorization: `Bearer ${storage.get("accessToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

const useUploadFile = (onSuccess: any, onError: any) => {
  return useMutation(uploadFile, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// Get uploaded files by user
const fetchUploadedFiles = ({ queryKey }: any) => {
  return apiCall.get(
    `${API_END_POINTS.FETCH_UPLOADED_FILES}?search=${queryKey[1]}&limit=${queryKey[2]}&offset=${queryKey[3]}&filter_by=${queryKey[4]}${queryKey[5] ? '&village_name=' + queryKey[5] : ''}`
  );
};

const useFetchUploadedFiles = (
  search?: any,
  limit?: any,
  offSet?: any,
  filter_by?: any,
  village?: any
) => {
  if (!village) {
    village = '';
  }
  return useQuery(
    ["uploaded_files", search, limit, offSet, filter_by, village],
    fetchUploadedFiles,
    {
      select: (data) => {
        return {
          total_count: data?.data?.count,
          fileList: data?.data?.results,
        };
      },
    }
  );
};

//Delete file
const deleteFile = (fileId: any) => {
  return apiCall.delete(`${API_END_POINTS.DELETE_FILE}/${fileId}`);
};

const useDeleteFile = (onSuccess: any, onError: any) => {
  return useMutation(deleteFile, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// Get categories list for select
const fetchCategoriesListForSelect = () => {
  return apiCall.get(`/survey/category`);
};
const useCategoriesListForSelect = () => {
  return useQuery(["category-list-for-select"], fetchCategoriesListForSelect, {
    select: (data: any) => {
      return data?.data?.data.map((catg: any) => ({
        value: catg.category_id,
        label: catg.name,
      }));
    },
  });
};

// Get social list for select

const fetchSocialList = () => {
  return apiCall.get(API_END_POINTS.GET_SOCIAL_MEDIA);
};


const useSocialList = (isEnglish: any, fetchTrigger: any) => {
  return useQuery(["social-list", fetchTrigger], async () => {
    // Fetch social list data
    const socialListResponse = await fetchSocialList();
    console.log(socialListResponse)

    if (!isEnglish) {
      let filteredContent = [];
      try {
        filteredContent = socialListResponse?.data?.data?.map((item: any) => {
          return {
            challengesArr: item.challenges.split(','),
            opportunitiesArr: item.challenges.split(','),
            post_id: item.post_id,
            content: item.content
          }
        })
        console.log(filteredContent)
      }
      catch (e) {
        console.log(e)
      }
      const anotherApiResponse = await translateDataToBahasa(filteredContent);
      console.log(anotherApiResponse)
      return anotherApiResponse;
    }
    let modifiedArr = socialListResponse?.data?.data;
    try {
      socialListResponse?.data?.data?.forEach((item: any) => {
        item.challengesArr = item.challenges.split(',')
        item.opportunitiesArr = item.challenges.split(',')

      })
      modifiedArr = socialListResponse?.data?.data;
    }
    catch (e) {
      console.log(e)
    }
    return modifiedArr;
  });
};

const translateDataToBahasa = async (data: any) => {
  let payloadData = data

  const payload = {
    data: payloadData
  };
  try {
    const response = await apiCall.post(
      `${APP_ENV.BE_UPDATED_AI_BASE_URL}/translate`,
      payload
    );
    let jsonData;
    try {
      jsonData = JSON.parse(response?.data?.data);
    }
    catch (e) {
      jsonData = response?.data?.data;
    }
    return jsonData;
  } catch (error: any) { console.log(error) }
}

const fetchPosts = () => {
  return apiCall.post(API_END_POINTS.GET_POSTS, {});
};
const useFetchPosts = (onSuccess: any, onError: any) => {
  return useMutation(fetchPosts, {
    onSuccess: onSuccess,
    onError: onError,
  });
};


export {
  useUserSignUp,
  useUserLogin,
  useForgotPassword,
  useResetPassword,
  useSetProfile,
  useGetNotifications,
  useUserLogout,
  useCurrentUser,
  useUpdateProfile,
  useUpdatePassword,
  useUploadFile,
  useCategoriesListForSelect,
  useFetchUploadedFiles,
  useDeleteFile,
  useSocialList,
  useFetchPosts
};
