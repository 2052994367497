import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import styled from "styled-components";
import { TableWrapper } from "../../../../../components/common-styles";
import React from "react";
import IconsBox from "../../../../../components/ui-components/iconbox";
import { OverlayPanel } from "primereact/overlaypanel";
import { useNavigate } from "react-router-dom";
import { ADMIN_MENU_PATHS } from "../../../../../all-routes/paths";
import { bahasa, english } from "../text";
import {
  MoreMenu,
  MoreMenuWrapper,
} from "../../../user-management/components/sub-components/users-table";
import DeleteModal from "./delete-modal";
import AssignCategory from "./assign-category";
import AssignSubCategory from "./assign-subcategory";
import AssignKeyword from "./assign-keyword";
import EditCategory from "./edit-category";
import EditSubCategory from "./edit-subcategory";
import EditKeyword from "./edit-keyword";
import {
  useAssignSurvey,
  useSurveyListForSelect,
} from "../../../../../api/admin-api/category-management";
import Toaster from "../../../../../components/ui-components/toaster";

const Container = styled(TableWrapper)`
  margin: 2.8rem 0 0 0;
  table {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }
  .more-icon {
    cursor: pointer;
  }
`;

interface IProps {
  isEnglish: boolean;
  data: any;
  tableNumber: number;
  onGetList: any;
}

const CommonList = ({ isEnglish, data, tableNumber, onGetList }: IProps) => {
  const {
    tableName1,
    tableSubName1,
    tableKeywordsName1,
    tableName2,
    tableSubName2,
    tableName3,
    tableName4,
    tableName5,
    tableName6,
    tableName7,
    tableName8,
    assignText,
    editText,
    deleteText,
    assignSuccessText,
    smtgWentWrgText,
  } = isEnglish ? english : bahasa;
  //Get survey list from server
  const { data: surveyList } = useSurveyListForSelect();

  // survey Assign

  const onSuccess = (res: any) => {
    setShowToast({
      status: true,
      type: "success",
      message: assignSuccessText,
      reason: "",
    });
    onGetList();
  };
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: smtgWentWrgText,
      reason: res?.response?.data?.message,
    });
  };
  const { mutate: assignSurvey } = useAssignSurvey(onSuccess, onError);

  const navigate = useNavigate();
  const moreRef = React.useRef<any>(null);

  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });
  const [selectedFiles, setSelectedFiles] = React.useState<any[]>([]);
  const [rowData, setRowData] = React.useState("");
  const [editModal, setEditModal] = React.useState(false);
  const [assignModal, setAssignModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);

  //   to get row data
  function handleRowClick(event: any) {
    const cellNum = event?.originalEvent?.target?.cellIndex;
    const cellData = event?.data;
    if (cellNum >= 0 && cellNum <= 6) {
      if (tableNumber === 1) {
        navigate(ADMIN_MENU_PATHS.CATEGORY + "/" + cellData?.category_id, {
          state: cellData,
        });
      }
      if (tableNumber === 2) {
        navigate(
          ADMIN_MENU_PATHS.SUBCATEGORY + "/" + cellData?.sub_category_id,
          {
            state: cellData,
          }
        );
      }
      if (tableNumber === 3) {
        navigate(ADMIN_MENU_PATHS.KEYWORDS + "/" + cellData?.keyword_id, {
          state: cellData,
        });
      }
    }
    setRowData(event?.data);
  }
  const moreTemplate = () => {
    return (
      <MoreMenuWrapper>
        <span className="more-icon" onClick={(e) => moreRef.current.toggle(e)}>
          <IconsBox name="three-dots-v" />
        </span>
        <OverlayPanel ref={moreRef}>
          <MoreMenu>
            <div
              className="more-item"
              onClick={(event: any) => {
                event.stopPropagation();
                setAssignModal(true);
                moreRef.current.toggle(false);
              }}
            >
              <IconsBox name="" />
              <p className="view-text">{assignText}</p>
            </div>

            <div
              className="more-item"
              onClick={(event: any) => {
                event.stopPropagation();
                setEditModal(true);
                moreRef.current.toggle(false);
              }}
            >
              <IconsBox name="edit" />
              <p className="view-text">{editText}</p>
            </div>
            <div
              className="more-item"
              onClick={(event: any) => {
                event.stopPropagation();
                moreRef.current.toggle(false);
                setDeleteModal(true);
              }}
            >
              <IconsBox name="trash" />
              <p className="trash-text">{deleteText}</p>
            </div>
          </MoreMenu>
        </OverlayPanel>
      </MoreMenuWrapper>
    );
  };

  return (
    <Container>
      <Toaster
        showToast={showToast.status}
        severity={showToast.type}
        summary={showToast.message}
        detail={showToast.reason}
      />
      <DeleteModal
        showModal={deleteModal}
        onHideModal={() => setDeleteModal(false)}
        tabIndex={tableNumber}
        isEnglish={isEnglish}
        onFetchData={onGetList}
        data={rowData}
      />
      <DataTable
        value={data}
        // selectionMode="checkbox"
        // selection={selectedFiles}
        // onSelectionChange={(e: any) => setSelectedFiles(e.value)}
        onRowClick={handleRowClick}
        rowHover={true}
      >
        {/* <Column selectionMode="multiple" exportable={false}></Column> */}
        {/* conditional rendering according to top tab header */}
        <Column
          field="name"
          header={
            tableNumber === 1
              ? tableName1
              : tableNumber === 2
              ? tableSubName1
              : tableKeywordsName1
          }
        ></Column>
        {/* conditional rendering according to top tab header */}
        {tableNumber === 2 || tableNumber === 3 ? (
          <Column field="category_linked" header={tableSubName2}></Column>
        ) : (
          <Column field="sub_category_linked" header={tableName2}></Column>
        )}

        {/* conditional rendering according to top tab header */}
        {tableNumber === 1 || tableNumber === 2 ? (
          <Column field="keyword_linked" header={tableName3}></Column>
        ) : (
          <Column field="sub_category_linked" header={tableName2}></Column>
        )}

        <Column field="survey" header={tableName4}></Column>
        <Column field="reports_linked" header={tableName5}></Column>
        <Column field="created_at" header={tableName6}></Column>
        <Column field="updated_at" header={tableName7}></Column>
        <Column header={tableName8} body={moreTemplate}></Column>
      </DataTable>

      {/* Edit modals */}
      {tableNumber === 1 ? (
        <EditCategory
          key={"edit-category"}
          onHideModal={() => setEditModal(false)}
          showModal={editModal}
          isEnglish={isEnglish}
          tabIndex={tableNumber}
          editMood={editModal}
          editData={rowData}
          onRefetch={onGetList}
        />
      ) : tableNumber === 2 ? (
        <EditSubCategory
          key={"edit-sub-category"}
          onHideModal={() => setEditModal(false)}
          showModal={editModal}
          isEnglish={isEnglish}
          tabIndex={tableNumber}
          editMood={editModal}
          editData={rowData}
          onRefetch={onGetList}
        />
      ) : (
        <EditKeyword
          key={"edit-keyword"}
          onHideModal={() => setEditModal(false)}
          showModal={editModal}
          isEnglish={isEnglish}
          tabIndex={tableNumber}
          editMood={editModal}
          editData={rowData}
          onRefetch={onGetList}
        />
      )}
      {/* Assign modals */}
      {tableNumber === 1 && assignModal ? (
        <AssignCategory
          key={"assign-category"}
          onHideModal={() => setAssignModal(false)}
          showModal={assignModal}
          isEnglish={isEnglish}
          tabIndex={tableNumber}
          editData={rowData}
          surveyList={surveyList?.length ? surveyList : []}
          onAssign={assignSurvey}
        />
      ) : tableNumber === 2 && assignModal ? (
        <AssignSubCategory
          key={"assign-subcategory"}
          onHideModal={() => setAssignModal(false)}
          showModal={assignModal}
          isEnglish={isEnglish}
          tabIndex={tableNumber}
          editData={rowData}
          surveyList={surveyList?.length ? surveyList : []}
          onAssign={assignSurvey}
        />
      ) : tableNumber === 3 && assignModal ? (
        <AssignKeyword
          key={"assign-keyword"}
          onHideModal={() => setAssignModal(false)}
          showModal={assignModal}
          isEnglish={isEnglish}
          tabIndex={tableNumber}
          editData={rowData}
          surveyList={surveyList?.length ? surveyList : []}
          onAssign={assignSurvey}
        />
      ) : null}
    </Container>
  );
};

export default CommonList;
