import React, { useEffect } from "react";
import styled from "styled-components";
import { bahasa, english } from "./text";
import Spinner from "../../../components/ui-components/spinner";
import { useSocialList, useFetchPosts } from "../../../api/user-api";
import { AuthButton } from "../../user-login/components/login";
import Toaster from "../../../components/ui-components/toaster";
import IconsBox from "../../../components/ui-components/iconbox";

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  /* background-color: var(--white_900); */
`;

const Line = styled.div`
  width: 100%;
  border-top: 1px solid var(--gray_100);
`;
const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  .child-card {
  }

  .key-trend {
    width: 100%;
  }
  .key-stats {
    width: 100%;
  }
`;
const FlexCol = styled.div`
  width: 48%;
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  gap: 0.7rem;
  .keyTrends-subheading {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: -0.02em;
    margin: 1rem 0;
  }
  .the-text-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.8rem;
    border-radius: 8px;
    padding: 1rem;
    background-color: var(--white_900);
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.11);
    .the-circle {
      min-width: 8px;
      min-height: 8px;
      border-radius: 5rem;
      background-color: var(--primary-500);
    }
    .orange {
      background-color: var(--orange-600);
    }
    .the-text {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: -0.02em;
    }
  }
  .more-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .more-btn {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 0.8rem;
    border: 1px solid var(--blue_800);
    background-color: var(--white_900);
    font-size: 1.4rem;
  }
`;
const Cards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  box-shadow: 0px 4px 9px 0px rgba(221, 224, 227, 1);
  padding: 1.5rem;
  background-color: var(--white_900);
  margin-bottom: 20px;
  .card-heading {
    color: var(--blue_800);
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: -0.0175rem;
    margin: 0 0 1.5rem 0;
  }
  .refreshImg {
    width: 17px;
    display: inline-block;
    margin-left: 10px;
    vertical-align: top;
    margin-top: 1px;
    transform: rotate(0deg);
    cursor: pointer;
    transition-duration: 400ms;
    &:hover {
      transform: rotate(180deg);
    }
  }
  .sub-title {
    font-size: 1.2rem;
    line-height: 1.5rem;
    letter-spacing: 0em;
    color: var(--blue_800);
    margin: 0 0 0.8rem 0;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 698px) {
    flex-direction: column;
  }
  .card-subs {
    padding: 2rem 0 0 0;
  }
  .v-line {
    width: 1px;
    height: 100%;
    background-color: var(--gray_100);
  }
`;

const Content = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  margin: 1rem 0;
  ol,
  ul {
    padding-left: 15px;
    margin: 0px;
    line-height: 22px;
    span {
      font-weight: 600;
    }
  }
  .alphabet-list {
    list-style-type: lower-alpha;
  }
  img {
    max-width: 90%;
    margin: auto;
  }
  div {
    margin: 10px 0px;
  }
  h3 {
    margin: 10px 0px;
  }
  a {
    color: blue;
  }
`;
interface Iprops {
    isEnglish: boolean;
}
const TopHeader = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
  align-items: center;
`;
const UploadButton = styled(AuthButton)`
  width: max(12.7rem, 170px);
  height: max(4.7rem, 47px);
  margin: 20px 0px;
`;
function DemoDetailsComponent({ isEnglish }: Iprops) {


    const { heading, challengesText, fetchBtnText, fetching, opportunityText } =
        isEnglish ? english : bahasa;


     return (
        <Wrapper>
            <Cards className="key-trend">
                <h1 className="card-heading">
                    Indonesian Manual
                </h1>
                <Content>
                    <a href="/docs/admin_indonesian.pdf"><IconsBox name={"manage-files"} /> Download Manual </a>
                </Content>
                <Line />
                <FlexWrapper>
                    <FlexCol></FlexCol>
                    <div className="v-line"></div>
                </FlexWrapper>
            </Cards>
        </Wrapper>
     );
}

export default DemoDetailsComponent;
