import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { AuthButton } from "../../../../user-login/components/login";
import { ButtonOutlined } from "../../../../../components/common-styles";
import { bahasa, english } from "../text";
import React from "react";
import IconsBox from "../../../../../components/ui-components/iconbox";
import { useDeleteUser, useUserDetails } from "../../../../../api/admin-api/user-management";
import Toaster from "../../../../../components/ui-components/toaster";
import { useNavigate } from "react-router-dom";
import { MENU_PATHS } from "../../../../../all-routes/paths";

const Heading = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2.6rem 0;
  .heading-text {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
  }
  .close-icon {
    cursor: pointer;
    opacity: 0.8;
  }
`;
const Content = styled.div`
  min-width: 43.4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .confirm-text {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 1.4rem 0;
  }
`;
const ProfileBox = styled.div`
  width: 100%;
  max-height: 8.7rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--blue_100);
  border-radius: 0.6rem;
  gap: 1.8rem;
  padding: 1.5rem 2.2rem;
  .user-image {
    max-width: 5.4rem;
    border-radius: 5rem;
  }
  .user-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.9rem;
    h3 {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: -0.02em;
      text-align: left;
    }
    p {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: -0.02em;
      text-align: left;
    }
  }
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  margin: 3.6rem 0 0 0;
`;

const NoButton = styled(ButtonOutlined)`
  width: max(7.1rem, 71px);
  height: max(4.7rem, 47px);
`;
const DeleteButton = styled(AuthButton)`
  width: max(9.6rem, 96px);
  height: max(4.7rem, 47px);
  color: var(--white_900);
  background: var(--red_600);
`;

interface Iprops {
  showModal: boolean;
  onHideModal: any;
  isEnglish: boolean;
  userId: any;
  isSamePage: boolean;
  onGetUsers?: any;
}
const DeleteUser = ({
  showModal,
  onHideModal,
  isEnglish,
  userId,
  isSamePage,
  onGetUsers,
}: Iprops) => {
  const navigate = useNavigate();
  const onSuccess = (res: any) => {
    if (res?.data.code === 200) {
      setShowToast({
        status: true,
        type: "success",
        message: "User deleted!",
        reason: "",
      });
    }
    if (isSamePage) {
      setTimeout(() => onGetUsers(), 1500);
    } else {
      setTimeout(() => navigate(MENU_PATHS.USER_MANAGEMENT), 1000);
    }
  };
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: "Delete failed!!",
      reason: "",
    });
  };
  const { mutate: deleteUser } = useDeleteUser(onSuccess, onError);
  const { data: userData, isLoading } = useUserDetails(userId);

  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });
  const { moreOptText2, deleteConfirmText, deleteTxt, noText } = isEnglish
    ? english
    : bahasa;

  const handleDelete = async () => {
    await deleteUser({ user_id: userId });
    setShowToast({
      status: false,
      type: "",
      message: "",
      reason: "",
    });
    onHideModal();
  };

  const headerTemplate = (
    <Heading>
      <h2 className="heading-text">{moreOptText2}</h2>
      <span className="close-icon" onClick={onHideModal}>
        <IconsBox name="close-icon" />
      </span>
    </Heading>
  );

  if (isLoading || !userId) {
    return <></>;
  }

  return (
    <>
      <Toaster
        showToast={showToast.status}
        severity={showToast.type}
        summary={showToast.message}
        detail={showToast.reason}
      />
      <Dialog
        header={headerTemplate}
        visible={showModal}
        onHide={onHideModal}
        style={{
          backgroundColor: "var(--white_900)",
          padding: "0rem 4rem 6rem 4rem",
          borderRadius: "1rem",
          border: "1px solid var(--gray_100)",
        }}
        maskStyle={{
          backgroundColor: "var(--black_050)",
        }}
        closable={false}
        draggable={false}
      >
        <Content>
          <p className="confirm-text">{deleteConfirmText}</p>
          <ProfileBox>
            <img
              className="user-image"
              src={userData?.user_image || "/images/test-user.jpg"}
              alt="user"
            />
            <div className="user-details">
              <h3>{userData?.name}</h3>
              <p>{userData?.email}</p>
            </div>
          </ProfileBox>
          <Footer>
            <NoButton onClick={onHideModal}>{noText}</NoButton>
            <DeleteButton onClick={handleDelete}>{deleteTxt}</DeleteButton>
          </Footer>
        </Content>{" "}
      </Dialog>
    </>
  );
};

export default DeleteUser;
