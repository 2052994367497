import styled from "styled-components";
import React from "react";
import { LanguageContext } from "../../../store/language-context";
import LandingPage from "./components/landing-page";

const MediaMonitoringWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--white_900);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
  border-radius: 1.5rem;
  padding: 3.5rem 2.4rem;
`;

function MediaMonitoring() {
  const languageCtx = React.useContext(LanguageContext);

  return (
    <MediaMonitoringWrapper>
      <LandingPage isEnglish={languageCtx.isEnglish} />
    </MediaMonitoringWrapper>
  );
}
export default MediaMonitoring;
