import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { AuthButton } from "../../../../user-login/components/login";
import { ButtonOutlined } from "../../../../../components/common-styles";
import { bahasa, english } from "../text";
import React from "react";
import IconsBox from "../../../../../components/ui-components/iconbox";
import Toaster from "../../../../../components/ui-components/toaster";
import { useDeleteUrl } from "../../../../../api/admin-api/media-monitoring";

const Heading = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2.6rem 0;
  .heading-text {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
  }
  .close-icon {
    cursor: pointer;
    opacity: 0.8;
  }
`;
const Content = styled.div`
  min-width: 43.4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .confirm-text {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 1.4rem 0;
  }
`;
const UrlBox = styled.div`
  width: 100%;
  max-height: 8.7rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--blue_100);
  border-radius: 0.6rem;
  gap: 1.8rem;
  padding: 1.5rem 2.2rem;
  .url-text {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.02em;
    text-align: left;
    color: var(--gray_600);
  }
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  margin: 3.6rem 0 0 0;
`;

const NoButton = styled(ButtonOutlined)`
  width: max(7.1rem, 71px);
  height: max(4.7rem, 47px);
`;
const DeleteButton = styled(AuthButton)`
  width: max(9.6rem, 96px);
  height: max(4.7rem, 47px);
  color: var(--white_900);
  background: var(--red_600);
`;

interface IProps {
  showModal: boolean;
  onHideModal: any;
  isEnglish: boolean;
  onFetchData?: any;
  data?: any;
}
const DeleteModal = ({
  showModal,
  onHideModal,
  isEnglish,
  onFetchData,
  data,
}: IProps) => {
  const {
    modalBtnText1,
    modalBtnText2,
    modalContent,
    modalHeader,
    successText,
    errorText,
  } = isEnglish ? english : bahasa;

  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });

  // handle success api response
  const onSuccess = (res: any) => {
    setShowToast({
      status: true,
      type: "success",
      message: successText,
      reason: res?.response?.message,
    });
    onFetchData();
    onHideModal();
  };
  // handle error api response
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: errorText,
      reason: res?.response?.message,
    });
  };
  // Delete url api call
  const { mutate: deleteUrl } = useDeleteUrl(onSuccess, onError);

  // url delete handler
  const handleDelete = async () => {
    await deleteUrl(data?.id);
    setShowToast({
      status: false,
      type: "",
      message: "",
      reason: "",
    });
  };

  const headerTemplate = (
    <Heading>
      <h2 className="heading-text">{modalHeader}</h2>
      <span className="close-icon" onClick={onHideModal}>
        <IconsBox name="close-icon" />
      </span>
    </Heading>
  );

  return (
    <>
      <Toaster
        showToast={showToast.status}
        severity={showToast.type}
        summary={showToast.message}
        detail={showToast.reason}
      />
      <Dialog
        header={headerTemplate}
        visible={showModal}
        onHide={onHideModal}
        style={{
          backgroundColor: "var(--white_900)",
          padding: "0rem 4rem 6rem 4rem",
          borderRadius: "1rem",
          border: "1px solid var(--gray_100)",
        }}
        maskStyle={{
          backgroundColor: "var(--black_050)",
        }}
        closable={false}
        draggable={false}
      >
        <Content>
          <p className="confirm-text">{modalContent}</p>
          <UrlBox>
            {" "}
            <p className="url-text">{data?.url}</p>
          </UrlBox>
          <Footer>
            <NoButton onClick={onHideModal}>{modalBtnText2}</NoButton>
            <DeleteButton onClick={handleDelete}>{modalBtnText1}</DeleteButton>
          </Footer>
        </Content>{" "}
      </Dialog>
    </>
  );
};

export default DeleteModal;
