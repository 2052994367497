export const API_END_POINTS = {
  USER_LOGIN: "/users/login",
  USER_REGISTER: "/users/register_from_invite",
  USER_FORGOT_PASSWORD: "/users/forgot_password",
  USER_RESET_PASSWORD: "/users/reset_password",
  SURVEY_UPLOAD_DOC: "/survey/upload_document",
  USER_VIEW_PROFILE: "/users/view_profile",
  GET_USER_DETAILS: "/admin_panel/user_details/",
  GET_NOTIFICATIONS: "/",
  USER_LOGOUT: "/users/logout",
  USER_UPDATE_PROFILE: "/admin_panel/update_user_profile",
  USER_UPDATE_PASSWORD: "/users/profile_password_update",
  UPLOAD_FILE: "/survey/upload_file",
  FETCH_UPLOADED_FILES: "/survey/list/file",
  DELETE_FILE: "/survey/delete/file",
  // dashboard
  GET_DASHBOARD_DATA: "/showData",
  // admin apis
  ADMIN_USER_BY_ID: "/admin_panel/user_details/",
  ADMIN_GET_USER_ROLES: "/admin_panel/user_role",
  ADMIN_INVITE_USER: "/admin_panel/invite/user",
  ADMIN_USER_LIST: "/admin_panel/user_list",
  ADMIN_VILLAGE_LIST: "/users/list_villages",
  ADMIN_CREATE_USER: "/admin_panel/create_user",
  ADMIN_UPDATE_USER: "/admin_panel/update_user_profile",
  ADMIN_BLOCK_USER: "/admin_panel/block",
  ADMIN_DELETE_USER: "/admin_panel/delete",
  ADMIN_SCRAP_DATA: "/admin_panel/scraped_data",
  ADMIN_GET_SCRAPED_DATA_BY_ID: "/admin_panel/get_scraped_data",
  ADMIN_GET_SCRAPED_DATA: "/admin_panel/get_scraped_websites",
  // Media monitoring
  ADMIN_GET_URLS: "/admin_panel/get_scraped_websites",
  ADMIN_GET_SCRAPE_DATA: "/admin_panel/get_scraped_data",
  ADMIN_ADD_URL: "/admin_panel/scraped_data",
  ADMIN_DELETE_URL: "/admin_panel/scraped_data",
  // category management
  ADMIN_GET_CATEGORIES_LIST: "/survey/category",
  ADMIN_UNLINK_CATEGORY: "/survey/category_remove",
  ADMIN_ADD_CATEGORY: "/survey/category",
  ADMIN_EDIT_CATEGORY: "/survey/category",
  ADMIN_DELETE_CATEGORY: "/survey/category",
  ADMIN_GET_SUBCATEGORIES_LIST: "/survey/subcategory",
  ADMIN_UNLINK_SUBCATEGORY: "/survey/subcategory_remove",
  ADMIN_ADD_SUBCATEGORY: "/survey/subcategory",
  ADMIN_EDIT_SUBCATEGORY: "/survey/subcategory",
  ADMIN_DELETE_SUBCATEGORY: "/survey/subcategory",
  ADMIN_GET_KEYWORD_LIST: "/survey/keyword",
  ADMIN_UNLINK_KEYWORD: "/survey/keyword_remove",
  ADMIN_ADD_KEYWORD: "/survey/keyword",
  ADMIN_EDIT_KEYWORD: "/survey/keyword",
  ADMIN_DELETE_KEYWORD: "/survey/keyword",
  ADMIN_GET_SURVEY_LIST: "/survey/formdata",
  ADMIN_UNLINK_SURVEY: "",
  ADMIN_DELETE_SURVEY: "",
  ADMIN_GET_REPORTS_LIST: "",
  ADMIN_UNLINK_REPORT: "",
  ADMIN_DELETE_REPORT: "",
  ADMIN_GET_DETAILS: "/survey/",
  ADMIN_ASSIGN_SURVEY: "/survey/assign",
  //social media
  GET_SOCIAL_MEDIA: '/survey/get_challenges_opportunities',
  GET_POSTS: '/survey/get_posts'
};
