import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { TabsWrapper } from "../../../user-management/components/users";
import React from "react";

const TabsBox = styled(TabsWrapper)``;

// none styles
interface IProps {
  isEnglish: boolean;
  activeTab: number;
  onChangeTab: any;
  tabsArray: any;
  isDefaultTab?: boolean;
  defaultTab?: number;
}

const Tabs = ({
  isEnglish,
  activeTab,
  onChangeTab,
  tabsArray,
  isDefaultTab,
  defaultTab,
}: IProps) => {
  const location = useLocation();

  React.useEffect(() => {
    handleUserStatus(location.search);
    return () => {};
  }, [location]);

  function handleUserStatus(search: any) {
    let flag = false;
    tabsArray.forEach((tab: any) => {
      if (search) {
        if (search.includes(tab.tabPath)) {
          onChangeTab(tab.tabIndex);
          flag = true;
          return;
        }
      }
    });
    if (!flag) {
      onChangeTab(isDefaultTab ? defaultTab : 1);
    }
  }
  return (
    <TabsBox>
      <div className="tab-view">
        {tabsArray.map((tab: any, index: number) => (
          <Link to={tab.tabPath} key={tab.tabIndex}>
            <h1
              onClick={() => onChangeTab(tab.tabIndex)}
              className={tab.tabIndex === activeTab ? "tab activeTab" : "tab"}
            >
              {tab.tabName}
            </h1>
          </Link>
        ))}
      </div>
    </TabsBox>
  );
};

export default Tabs;
