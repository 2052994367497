import styled from "styled-components";

const BaseContainer = styled.main`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: var(--max-width);
`;

const Button = styled.button`
  border: transparent;
  background-color: none;
  width: 359px;
  height: 46px;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all var(--normal-time);
  :active {
    opacity: 0.8;
    transform: translateY(2px);
  }
  :disabled {
    box-shadow: 0 0 0 1px var(--gray_200);
    background-color: var(--gray_200);
    color: var(--gray_600);
    cursor: not-allowed;
  }
  @media screen and (max-width: 998px) {
    width: 28rem;
  }
`;
const ButtonOutlined = styled(Button)`
  color: var(--blue_600);
  /* border: 1px solid var(--blue_600); */
  box-shadow: 0 0 0 1px var(--blue_600);
  background-color: var(--white_900);
  @media screen and (max-width: 998px) {
    width: 28rem;
  }
`;
const Input = styled.input`
  border: none;
  width: 359px;
  height: 46px;
  border-radius: 10px;
  padding: 1.4rem;
  transition: all var(--normal-time);
  :active {
    box-shadow: 0 0 0 1px var(--black_900);
  }
  @media screen and (max-width: 998px) {
    width: 28rem;
  }
`;

const TextArea = styled.textarea`
  border: none;
  width: 359px;
  min-height: 100px;
  resize: vertical;
  transition: all var(--normal-time);
  border-radius: 1rem;
  padding: 1.5rem;
  font-size: 1.4rem;
  :active {
    box-shadow: 0 0 0 1px var(--black_900);
  }
  @media screen and (max-width: 998px) {
    width: 28rem;
  }
`;

const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;
const AuthWrapper = styled.div`
  width: min(359px, 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 2rem 0;
  .heading {
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: -0.03em;
    margin: 0 0 0.5rem 0;
  }
  .sub-heading {
    color: var(--bluish_gray);
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.02em;
    margin: 0 0 3.6rem 0;
  }
`;

const AuthFormWrapper = styled.form`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 1.3rem 0 0 0;
  label {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
  }
`;

const Select = styled.select`
  border-radius: 10px;
  padding: 0 1.4rem;
  border: none;
  background: var(--white_900);
  border: 1px solid var(--gray_500);
  transition: all var(--normal-time);
  :active {
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  .p-checkbox-box {
    max-width: 1.4rem !important;
    max-height: 1.4rem !important;
    border-radius: 0.3rem;
    border: 1px solid var(--gray_800);
    box-shadow: none !important;
  }
  .p-checkbox-box.p-highlight {
    background-color: var(--blue_500) !important;
    border: none;
    background: none;
  }
  table {
    thead {
      tr {
        th {
          background-color: var(--blue_50) !important;
          font-size: 1.2rem;
          font-weight: 500 !important;
          line-height: 15px;
          letter-spacing: 0em;
          color: var(--gray_600) !important;
          padding: 2rem 0rem !important;
          :first-child {
            display: flex;
            padding: 2.5rem 1rem 2rem 0.5rem !important;
            justify-content: center;
            border-radius: 1rem 0 0 0 !important;
          }
          :last-child {
            padding: 2rem 1rem 2rem 0 !important;
            border-radius: 0 1rem 0 0 !important;
            div {
              justify-content: flex-end;
              padding: 0 2rem 0 0;
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: -0.02em;
          padding: 2rem 0 !important;
          :first-child {
            display: flex;
            padding: 2.5rem 1rem 2rem 0.5rem !important;
            justify-content: center;
            /* border: none; */
          }
          :last-child {
            border: none;
            padding: 0 2rem 0 0 !important;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
        }
      }
    }
  }
`;

const LoaderWrapper = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  BaseContainer,
  Container,
  Button,
  ButtonOutlined,
  Input,
  TextArea,
  ErrorWrapper,
  AuthWrapper,
  AuthFormWrapper,
  Select,
  TableWrapper,
  LoaderWrapper,
};
