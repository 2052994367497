import React from "react";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { APP_ENV } from "./config";
import myStorage from "../store/my-storage";
import { AUTH_PATHS } from "../all-routes/paths";

const apiCall: AxiosInstance = (function () {
  const instance = axios.create({
    baseURL: APP_ENV.BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
    // timeout: 5000,
  });
  const storage = myStorage();

  const refreshToken = async (): Promise<string> => {
    const refreshToken = storage.get("refreshToken");
    try {
      const response: AxiosResponse<any> = await axios.post(
        `${APP_ENV.BASE_URL}/api/token/refresh/`,
        {
          refresh: refreshToken,
        }
      );
      const newToken = response?.data.access;
      storage.set("accessToken", newToken);
      return newToken;
    } catch (error: any) {
      if (error?.response?.status == 401) {
        console.log("401", error);
        storage.remove("refreshToken");
        storage.remove("accessToken");
        storage.remove("userDetails");
        window.location.href = `${APP_ENV.FE_ADMIN_BASE_URL}${AUTH_PATHS.LOGIN}`;
      }
      return Promise.reject(error);
    }
  };

  instance.interceptors.response.use(
    function (config) {
      const token = storage.get("accessToken");
      config.headers["Authorization"] = token ? `Bearer ${token}` : "";
      return config;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        storage.remove("newAT");
        originalRequest._retry = true;
        try {
          const newToken = await refreshToken();
          storage.set("accessToken", newToken);
          return instance(originalRequest);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    }
  );

  // Request interceptor for API calls
  instance.interceptors.request.use(
    async (config) => {
      const token = storage.get("accessToken");
      config.headers["Authorization"] = token ? `Bearer ${token}` : "";
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
})();

export default apiCall;
