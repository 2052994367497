import styled from "styled-components";
import React from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Map from "./map";
import AudioPlayer from "../../../../manage-files/components/sub-components/audio-player";

const Wrapper = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--white_900);
  border-radius: 1rem;
  @media screen and (max-width: 998px) {
    min-height: 50rem;
  }
`;

const Image = styled.div`
  min-width: 150px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px 10px 0px 0px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const Filewrapper = styled.div`
  width: 200px;
  background-color: var(--white_900);
  overflow: auto;
  border-radius: 1rem;
  max-height: 140px;
  display: flex;
`;
const Filewrapper2 = styled.div`
  width: 200px;
  background-color: var(--white_900);
  overflow: auto;
  border-radius: 1rem;
  max-height: 140px;
`;


const PlaceName = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 600;
`;

const LabelName = styled.div`
  width: 100%;
  font-size: 10px;
  margin: 10px 0px;
`;

const FileItems = styled.div`
  width: 20%;
`;
// none style
interface IProps {
  isEnglish?: boolean;
  latlon: any;
  placeName: any;
  mapLoading: boolean;
  files: any;
  video: any;
  audio: any;
}

const MapComponent = ({
  isEnglish,
  latlon,
  placeName,
  mapLoading,
  files,
  video,
  audio,
}: IProps) => {
  const { latitude, longitude } = latlon;

  const customIcon = new L.Icon({
    //creating a custom icon to use in Marker
    iconUrl: "/images/map-point.png",
    iconSize: [35, 35],
    iconAnchor: [5, 30],
  });

  function MapView() {
    let map = useMap();
    map.setView([latitude, longitude], map.getZoom());
    //Sets geographical center and zoom for the view of the map
    return null;
  }

  return (
    <Wrapper className="child-components">
      {mapLoading || !placeName?.s3 ? (
        <Map
          coords={{
            latitude: -2.3,
            longitude: 119.6,
          }}
          display_name={<h1>{placeName?.s3 || ""}</h1>}
        />
      ) : (
        <MapContainer
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "1rem",
            zIndex: 2,
          }}
          center={[latitude, longitude]}
          zoom={12}
        >
          <TileLayer
            //   attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>
            // contributors'
            // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {latlon ? (
            <>
              <Marker icon={customIcon} position={[latitude, longitude]}>
                <Popup>
                  <PlaceName>{placeName?.s3}</PlaceName>
                  {files?.fileList && files?.fileList.length > 0 && (
                    <LabelName>
                      <>
                        Images
                        {files.total_count > 5 && (
                          <span>(+{files.total_count - 5})</span>
                        )}
                      </>
                    </LabelName>
                  )}
                  <Filewrapper>
                    <>
                      {files?.fileList.map((item: any) => (
                        <Image
                          key={item.file_id}
                          style={{ backgroundImage: `url(${item.file_url})` }}
                        />
                      ))}
                    </>
                  </Filewrapper>
                  {video?.fileList && video?.fileList.length > 0 && (
                    <LabelName>
                      Videos{" "}
                      {video.total_count > 5 && (
                        <span>(+{video.total_count - 5})</span>
                      )}
                    </LabelName>
                  )}
                  <Filewrapper>
                    <>
                      {video?.fileList.map((item: any) => (
                        <Image key={item.file_id}>
                          <video
                            style={{ maxWidth: "100%" }}
                            src={item.file_url}
                            autoPlay={false}
                            controls
                          ></video>
                        </Image>
                      ))}
                    </>
                  </Filewrapper>
                  {audio?.fileList && audio?.fileList.length > 0 && (
                    <LabelName>
                      Audios{" "}
                      {audio.total_count > 5 && (
                        <span>(+{audio.total_count - 5})</span>
                      )}
                    </LabelName>
                  )}
                  <Filewrapper2>
                    <>
                      {audio?.fileList.map((item: any) => (
                        <AudioPlayer key={item.file_id} src={item.file_url} />
                      ))}
                    </>
                  </Filewrapper2>
                </Popup>
              </Marker>
            </>
          ) : null}
          <MapView />
        </MapContainer>
      )}
    </Wrapper>
  );
};
export default MapComponent;
