import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserLogout } from "../../../api/user-api";
import myStorage from "../../../store/my-storage";
import { AUTH_PATHS } from "../../../all-routes/paths";

const IDLE_TIMEOUT = 20 * 60 * 1000; // 20 minutes in milliseconds

const IdleTimeoutHandler: React.FC = () => {
  const [lastActivity, setLastActivity] = useState<number>(Date.now());
  const navigate = useNavigate();
  const [logoutPopup, setLogoutPopup] = React.useState(false);

  const handleLogout = () => {
    const storage = myStorage();
    storage.remove("accessToken");
    storage.remove("userDetails");
    storage.remove("refreshToken");
    setLogoutPopup(false);
    navigate("/v1/auth/admin-login");
  };

  useEffect(() => {
    const events = [
      "mousedown",
      "mousemove",
      "keypress",
      "scroll",
      "touchstart",
    ];

    const resetTimer = () => {
      setLastActivity(Date.now());
    };

    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    const intervalId = setInterval(() => {
      const now = Date.now();
      const timeSinceLastActivity = now - lastActivity;
      if (timeSinceLastActivity > IDLE_TIMEOUT) {
        handleLogout();
      }
    }, 6000);

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
      clearInterval(intervalId);
    };
  }, [lastActivity, handleLogout, navigate]);

  return null;
};

export default IdleTimeoutHandler;
