import styled from "styled-components";
import IconsBox from "../../../../components/ui-components/iconbox";
import Switch from "../../../../components/ui-components/switch";
import { Dialog } from "primereact/dialog";
import WordClouds from "./wordColud/WordCloud";
import { useScrapeData } from "../../../../api/admin-api/media-monitoring";
import { AuthButton } from "../../../user-login/components/login";
import { bahasa, english } from "./text";
import React from "react";
import Spinner from "../../../../components/ui-components/spinner";

const Heading = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2.6rem 0;
  .heading-text {
    width: 80%;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
  }
  .close-icon {
    cursor: pointer;
    opacity: 0.8;
  }
`;
const Content = styled.div`
  min-width: 43.4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .confirm-text {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 1.4rem 0;
  }
`;

const ButtonWrapper = styled.div`
  width: 120px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  color: #333;
`;
const BtnOuter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const AddButton = styled(AuthButton)``;
const TextSub = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 17px;
`;
const FlexCol = styled.div`
  width: 48%;
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  gap: 0.7rem;
  .keyTrends-subheading {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: -0.02em;
    margin: 1rem 0;
  }
  .the-text-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.8rem;
    border-radius: 8px;
    padding: 1rem;
    background-color: var(--white_900);
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.11);
    .the-circle {
      min-width: 8px;
      min-height: 8px;
      border-radius: 5rem;
      background-color: var(--primary-500);
    }
    .orange {
      background-color: var(--orange-600);
    }
    .the-text {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: -0.02em;
    }
  }
  .more-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .more-btn {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 0.8rem;
    border: 1px solid var(--blue_800);
    background-color: var(--white_900);
    font-size: 1.4rem;
  }
`;
const Cards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 1.2rem;
  box-shadow: 0px 4px 9px 0px rgba(221, 224, 227, 1);
  padding: 1.5rem;
  background-color: var(--white_900);
  .card-heading {
    color: var(--blue_800);
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: -0.0175rem;
    margin: 0 0 1.5rem 0;
  }
  .sub-title {
    font-size: 1.2rem;
    line-height: 1.5rem;
    letter-spacing: 0em;
    color: var(--blue_800);
    margin: 0 0 0.8rem 0;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 698px) {
    flex-direction: column;
  }
  .card-subs {
    padding: 2rem 0 0 0;
  }
  .v-line {
    width: 1px;
    height: 100%;
    background-color: var(--gray_100);
  }
`;
const Line = styled.div`
  width: 100%;
  border-top: 1px solid var(--gray_100);
`;

interface IProps {
  showModal: boolean;
  onHideModal: any;
  isEnglish: boolean;
  onFetchData?: any;
  id?: any;
}

const ViewScrappedData = ({
  showModal,
  onHideModal,
  isEnglish,
  id,
}: IProps) => {
  const { btnTxtChallenge, keyTrendsText, opportunityText, challengesText } =
    isEnglish ? english : bahasa;
  let {
    data: scrapeData,
    isLoading,
    isFetching,
  } = useScrapeData(id, isEnglish);
  const [challengesToggle, setChallengesToggle] = React.useState(false);
  const [hasChallenges, setHasChallenges] = React.useState(false);
  const [challengesOppurtunities, setChallengesOppurtunities] = React.useState({
    challenges: [],
    opportunities: [],
  });

  const onHideModalClick = () => {
    setHasChallenges(false);
    setChallengesToggle(false);
    // scrapeData = null;
    onHideModal();
  };

  const parseJSONWithCorrections = (jsonString: string) => {
    if (typeof jsonString == "string") {
      try {
        return JSON.parse(jsonString);
      } catch (e) {
        let correctedJsonString = jsonString.replace(/'/g, '"');
        try {
          return JSON.parse(correctedJsonString);
        } catch (e) {
          if (jsonString.includes("'oportunis'")) {
            let correctedOportunisJsonString = jsonString.replace(
              /'oportunis'/g,
              '"opportunities"'
            );
            try {
              correctedOportunisJsonString =
                correctedOportunisJsonString.replace(/'/g, '"');
              // Step 2: Add commas between array elements and correct any syntax errors
              console.log(correctedOportunisJsonString);
              return JSON.parse(correctedOportunisJsonString);
            } catch (e) {
              return jsonString; // or throw new Error("Failed to parse JSON");
            }
          } else {
            return jsonString; // or throw new Error("Failed to parse JSON");
          }
        }
      }
    } else {
      return jsonString;
    }
  };

  React.useEffect(() => {
    if (scrapeData) {
      if (scrapeData.challenges_and_opportunities) {
        scrapeData.result = parseJSONWithCorrections(
          scrapeData.challenges_and_opportunities
        );
        console.log(typeof scrapeData.result);
        if (scrapeData.result) {
          setChallengesOppurtunities(scrapeData.result);
          setHasChallenges(true);
        }
        if (typeof scrapeData.result == "string") {
          scrapeData.opType = "string";
        }
      }
    }
    return () => {
      setChallengesOppurtunities({
        challenges: [],
        opportunities: [],
      });
      // setHasChallenges(false);
      // setChallengesToggle(false);
    };
  }, [isFetching]);

  const handleSubmit = () => {
    setChallengesToggle((prevValue) => !prevValue);
  };

  const headerTemplate = (
    <Heading>
      <h2 className="heading-text">{scrapeData?.title}</h2>
      <span className="close-icon" onClick={onHideModalClick}>
        <IconsBox name="close-icon" />
      </span>
    </Heading>
  );
  return (
    <Dialog
      header={headerTemplate}
      visible={showModal}
      onHide={onHideModalClick}
      style={{
        backgroundColor: "var(--white_900)",
        padding: "0rem 4rem 6rem 4rem",
        borderRadius: "1rem",
        border: "1px solid var(--gray_100)",
      }}
      maskStyle={{
        backgroundColor: "var(--black_050)",
      }}
      closable={false}
      draggable={false}
    >
      {hasChallenges && (
        <BtnOuter>
          <ButtonWrapper>
            {/* <AddButton onClick={handleSubmit}>{btnTxtChallenge}</AddButton> */}
            <p>{btnTxtChallenge}</p>
            {(challengesOppurtunities?.challenges?.length ||
              challengesOppurtunities?.opportunities?.length) && (
              <Switch isOn={challengesToggle} handleToggle={handleSubmit} />
            )}
          </ButtonWrapper>
        </BtnOuter>
      )}
      <Content>
        {isLoading ? (
          <></>
        ) : challengesToggle ? (
          <Cards className="key-trend">
            <h1 className="card-heading">{keyTrendsText}</h1>
            <Line />
            {scrapeData.opType && (
              <Cards>
                <TextSub>Failed to parse data</TextSub>
              </Cards>
            )}
            {!scrapeData.opType && (
              <FlexWrapper>
                <FlexCol>
                  {scrapeData && (
                    <h2 className="keyTrends-subheading">{challengesText}</h2>
                  )}
                  {!isLoading && scrapeData ? (
                    challengesOppurtunities?.challenges?.length ? (
                      challengesOppurtunities?.challenges.map(
                        (challenge: any) => (
                          <div className="the-text-box" key={challenge}>
                            <span className="the-circle"></span>
                            <p className="the-text">{challenge}</p>
                          </div>
                        )
                      )
                    ) : (
                      "No Data"
                    )
                  ) : isLoading ? (
                    <Spinner />
                  ) : null}
                </FlexCol>
                <div className="v-line"></div>
                <FlexCol>
                  {scrapeData && (
                    <h2 className="keyTrends-subheading">{opportunityText}</h2>
                  )}
                  {!isLoading && scrapeData ? (
                    challengesOppurtunities?.opportunities?.length ? (
                      challengesOppurtunities?.opportunities.map(
                        (opportunity: any) => (
                          <div className="the-text-box" key={opportunity}>
                            <span className="the-circle orange"></span>
                            <p className="the-text">{opportunity}</p>
                          </div>
                        )
                      )
                    ) : (
                      "No Data"
                    )
                  ) : isLoading ? (
                    <Spinner />
                  ) : null}
                </FlexCol>
              </FlexWrapper>
            )}
          </Cards>
        ) : (
          <WordClouds
            cloudWords={scrapeData?.content}
            width={600}
            height={300}
          />
        )}
      </Content>
    </Dialog>
  );
};

export default ViewScrappedData;
