interface IPaths {
  SIGNUP: string;
  LOGIN: string;
  LOGIN2: string;
  RESET: string;
  RESET_PASSWORD: string;
  SET_PASSWORD: string;
  PROFILE_SETUP: string;
}

const AUTH_PATHS: IPaths = {
  SIGNUP: "/v1/auth/signup",
  LOGIN: "/v1/auth/login",
  LOGIN2: "/v1/auth/admin-login",
  RESET: "/v1/auth/reset",
  RESET_PASSWORD: "/v1/auth/reset/password",
  SET_PASSWORD: "/v1/auth/set-password",
  PROFILE_SETUP: "/v1/auth/profile-setup",
};

interface IPaths2 {
  TERMS_CONDITIONS: string;
  ERROR_PAGE: string;
}

const OTHER_PATHS: IPaths2 = {
  TERMS_CONDITIONS: "/v1/terms&conditions",
  ERROR_PAGE: "/*",
};

interface IPaths3 {
  DASHBOARD: string;
  MANAGE_FILES: string;
  SURVEY: string;
  ANALYTICS: string;
  USER_MANAGEMENT: string;
  CATEGORY_MANAGEMENT: string;
  MEDIA_MONITORING: string;
  SOCIAL_MEDIA: string;
  FAQ: string;
  DEMO: string;
  SETTINGS: string;
}

const MENU_PATHS: IPaths3 = {
  DASHBOARD: "/v1/dashboard",
  MANAGE_FILES: "/v1/manage-files",
  SURVEY: "/v1/survey",
  ANALYTICS: "/v1/analytics",
  USER_MANAGEMENT: "/v1/user-management",
  CATEGORY_MANAGEMENT: "/v1/category-management",
  MEDIA_MONITORING: "/v1/media-monitoring",
  SOCIAL_MEDIA: "/v1/social-media",
  FAQ: "/v1/FAQ",
  DEMO: "/v1/demo",
  SETTINGS: "/v1/settings",
};

interface IPaths4 {
  SETTINGS_BASE: string;
  SETTINGS_HOME: string;
  EDIT_PROFILE: string;
  CHANGE_PASSWORD: string;
}

const SETTINGS_SUB_PATHS: IPaths4 = {
  SETTINGS_BASE: "/v1/settings",
  SETTINGS_HOME: "/",
  EDIT_PROFILE: "/edit-profile",
  CHANGE_PASSWORD: "/change-password",
};
interface IPaths5 {
  MANAGE_FILES_BASE: string;
  MANAGE_FILES_HOME: string;
  UPLOAD_FILE: string;
  CHANGE_PASSWORD: string;
}

const MANAGE_FILES_SUB_PATHS: IPaths5 = {
  MANAGE_FILES_BASE: "/v1/manage-files",
  MANAGE_FILES_HOME: "/",
  UPLOAD_FILE: "/upload-file",
  CHANGE_PASSWORD: "/change-password",
};

interface IPaths6 {
  USER_MANAGEMENT: string;
  USER_MANAGEMENT_HOME: string;
  USER_MANAGEMENT_USER_DETAILS: string;
  CATEGORY_MANAGEMENT: string;
  CATEGORY_MANAGEMENT_HOME: string;
  CATEGORY: string;
  SUBCATEGORY: string;
  KEYWORDS: string;
  MEDIA_MONITORING: string;
  SOCIAL_MEDIA: string;
  FAQ: string;
  DEMO: string;
}
const ADMIN_MENU_PATHS: IPaths6 = {
  USER_MANAGEMENT: "/v1/user-management",
  USER_MANAGEMENT_HOME: "/",
  USER_MANAGEMENT_USER_DETAILS: "/detail-page",
  CATEGORY_MANAGEMENT: "/v1/category-management",
  CATEGORY_MANAGEMENT_HOME: "/",
  CATEGORY: "category",
  SUBCATEGORY: "sub-category",
  KEYWORDS: "keywords",
  MEDIA_MONITORING: "/v1/media-monitoring",
  SOCIAL_MEDIA: "/v1/social-media",
  FAQ: "/v1/FAQ",
  DEMO: "/v1/demo",
};

export {
  AUTH_PATHS,
  OTHER_PATHS,
  MENU_PATHS,
  MANAGE_FILES_SUB_PATHS,
  SETTINGS_SUB_PATHS,
  ADMIN_MENU_PATHS,
};
