import styled from "styled-components";
import React from "react";
import Tabs from "../sub-components/tabs";
import { english, bahasa } from "../text";
import TableList from "./table";
import SurveyList from "./surveys-table";
import { PaginatorPageChangeEvent } from "primereact/paginator";
import {
  useGetDetailsById,
  useUnlinkItem,
} from "../../../../../api/admin-api/category-management";
import Spinner from "../../../../../components/ui-components/spinner";

const Container = styled.div`
  margin: 0 0 0 0;
  width: 100%;
`;
const TabWrapper = styled.div`
  width: 100%;
  margin: 3.2rem 0 2.4rem 0;
  .tab-view {
    justify-content: flex-start;
  }
`;

// none styles
interface IProps {
  isEnglish: boolean;
  id: any;
  activeHomeTab: number;
  activeTab: number;
  onGetList: any;
  onChangeTab: any;
}

const TableContainer = ({
  isEnglish,
  id,
  activeHomeTab,
  activeTab,
  onGetList,
  onChangeTab,
}: IProps) => {
  const { detailPageTabs } = isEnglish ? english : bahasa;
  const [first, setFirst] = React.useState<number>(0);
  const [rows, setRows] = React.useState(5);
  const [pageNo, setPageNo] = React.useState(1);

  React.useEffect(() => {
    setFirst(() => 0);
    setRows(() => 5);
    return () => {};
  }, [activeTab]);

  //get details
  const {
    data,
    isLoading,
    refetch: getDetailsById,
  } = useGetDetailsById(id, activeTab, activeHomeTab, rows, first);

  //unlink api call
  const onSuccess = (res: any) => {
    getDetailsById();
    onGetList()
  };
  const onError = (res: any) => {
    console.log(res);
  };
  const { mutate: unlinkHandler } = useUnlinkItem(onSuccess, onError);

  //   pagination handler
  const handlePageChange = (e: PaginatorPageChangeEvent, index: number) => {
    setFirst(() => e.first);
    setRows(() => e.rows);
    setPageNo(() => e.first / e.rows + 1);
  };

  const filteredTabs = detailPageTabs?.filter(
    (tab: any) => tab.tabId != activeHomeTab
  );

  return (
    <Container>
      <TabWrapper>
        {" "}
        <Tabs
          isEnglish={isEnglish}
          activeTab={activeTab}
          onChangeTab={onChangeTab}
          tabsArray={filteredTabs}
          isDefaultTab={activeHomeTab === 1 ? true : false}
          defaultTab={2}
        />
      </TabWrapper>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {activeTab === 4 ? (
            //table for survey list
            <SurveyList
              isEnglish={isEnglish}
              homeTab={activeHomeTab}
              activeTab={activeTab}
              data={data?.surveyList}
              onGetList={() => {}}
              offSet={first}
              limit={rows}
              onPageChange={handlePageChange}
              onUnlink={unlinkHandler}
              id={id}
              totalPages={data?.totalPage}
            />
          ) : (
            //common table
            <TableList
              isEnglish={isEnglish}
              homeTab={activeHomeTab}
              data={
                activeTab === 1
                  ? data?.categoryList
                  : activeTab === 2
                  ? data?.subCategoryList
                  : activeTab === 3
                  ? data?.keywordsList
                  : activeTab === 4
                  ? data?.surveyList
                  : activeTab === 5
                  ? data?.reportsList
                  : []
              }
              activeTab={activeTab}
              onGetList={() => {}}
              offSet={first}
              limit={rows}
              onPageChange={handlePageChange}
              onUnlink={unlinkHandler}
              id={id}
              totalPages={data?.totalPage}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default TableContainer;
