import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";

import { ADMIN_MENU_PATHS } from "../../../all-routes/paths";
import { LanguageContext } from "../../../store/language-context";

import CmHomePage from "./components/cm-home-page";
import CmDetailPage from "./components/cm-detail-page";

const CategoryManagementWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function CategoryManagement() {
  const languageCtx = React.useContext(LanguageContext);

  const [activeTabIndex, setActiveTabIndex] = React.useState(1);

  const PageNavigate = [
    {
      pathName: `/${ADMIN_MENU_PATHS.CATEGORY}`,
      navigateTo: "?category",
    },
    {
      pathName: `/${ADMIN_MENU_PATHS.SUBCATEGORY}`,
      navigateTo: "?sub-category",
    },
    {
      pathName: `/${ADMIN_MENU_PATHS.KEYWORDS}`,
      navigateTo: "?keywords",
    },
  ];
  return (
    <CategoryManagementWrapper>
      <Routes>
        {PageNavigate.map(({ navigateTo, pathName }, key) => (
          <Route
            key={key}
            path={`${ADMIN_MENU_PATHS.CATEGORY_MANAGEMENT_HOME}${pathName}`}
            element={
              <Navigate
                to={`${ADMIN_MENU_PATHS.CATEGORY_MANAGEMENT}${navigateTo}`}
              />
            }
          />
        ))}
      
        <Route
          path={ADMIN_MENU_PATHS.CATEGORY_MANAGEMENT_HOME}
          element={
            <CmHomePage
              activeTab={activeTabIndex}
              onActiveTab={(tabIndx: any) => setActiveTabIndex(tabIndx)}
              isEnglish={languageCtx.isEnglish}
            />
          }
        />
        {["/category", "/sub-category", "/keywords"].map((path, index) => (
          <Route
            key={index}
            path={`${path}/:id`}
            element={
              <CmDetailPage
                activeHomeTab={activeTabIndex}
                isEnglish={languageCtx.isEnglish}
              />
            }
          />
        ))}
      </Routes>
    </CategoryManagementWrapper>
  );
}
export default CategoryManagement;
