import styled from "styled-components";
import UsersList from "./sub-components/users-table";
import React from "react";
import PaginationManger from "../../../../components/ui-components/pagination";
import { PaginatorPageChangeEvent } from "primereact/paginator";
import { bahasa, english } from "./text";
import IconsBox from "../../../../components/ui-components/iconbox";
import { OverlayPanel } from "primereact/overlaypanel";
import { ButtonOutlined, Input } from "../../../../components/common-styles";
import { AuthButton } from "../../../user-login/components/login";
import { Link, useLocation } from "react-router-dom";
import { ADMIN_MENU_PATHS } from "../../../../all-routes/paths";
import AddUser from "./sub-components/add-user";
import { useUsersList } from "../../../../api/admin-api/user-management";
import Spinner from "../../../../components/ui-components/spinner";
import { useDebounce } from "primereact/hooks";

const UserWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const TopHeader = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
`;

const CommonBox = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const TabsWrapper = styled.div`
  margin: 0 0 0 0;
  .tab-view {
    height: 5.4rem;
    width: 100%;
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: var(--white_900);
    gap: 6rem;
    border-radius: 1.6rem;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
  }
  .tab {
    height: 100%;
    cursor: pointer;
    transition: all 0.4s;
    font-family: var(--font_family);
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    padding: 1.8rem 1rem 1.8rem 1rem;
    border-bottom: 2px solid transparent;
    color: var(--gray_800);
    :hover {
      color: var(--blue_500);
      background-color: var(--gray_050);
    }
  }
  .activeTab {
    color: var(--blue_600);
    border-bottom: 2px solid var(--blue_500);
  }
`;
const FilterBox = styled.span`
  width: fit-content;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
  border-radius: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin: 0 2rem 0 1.3rem;
  background: var(--white_900);
  transition: all var(--normal-time);
  :hover {
    cursor: pointer;
    background-color: var(--gray_050);
    svg {
      path {
        stroke: var(--blue_600);
      }
    }
  }
`;
const SelectFilter = styled.div`
  position: relative;
  width: fit-content;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.8rem;
  border-radius: 0.5rem;

  ::before {
    content: "";
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: -1.8rem;
    left: -0.8rem;
    border-radius: 0.2rem;
    transform: rotate(-45deg);
    background-color: var(--white_900);
    box-shadow: 1px -2px 1px -1px rgba(0, 0, 0, 0.1);
    z-index: -1;
  }
  .option {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.6rem 1rem;
    gap: 1rem;
    cursor: pointer;
    :hover {
      background-color: var(--gray_100);
    }
    h3 {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: -0.02em;
    }
  }
`;
const CheckInputBox = styled.input`
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
  border-radius: 3px;
`;

export const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.4rem;
  @media screen and (max-width: 980px) {
    flex-wrap: wrap;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: max(27.1rem, 271px);
  height: max(4.6rem, 46px);
  .search-input {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0 0 0 45px;
  }
  .search-icon {
    position: absolute;
    width: 15%;
    height: 100%;
    left: 0.2rem;
    top: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
`;

export const SearchInput = styled(Input)`
  box-shadow: 0 0 0 1px var(--gray_500);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  margin: 0;
  padding-right: 1rem !important;
`;

const AddButton = styled(AuthButton)`
  width: max(12.7rem, 127px);
  height: max(4.7rem, 47px);
`;

const FilteredItems = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5rem;
  margin: 2rem 0;
`;
const FilteredItemsBtn = styled(ButtonOutlined)`
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.4rem;
  gap: 0.4rem;
  svg {
    width: 1.8rem;
  }
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 2.4rem 0 0 0;
  .dropdown {
    .p-dropdown-label {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
    }
    .p-dropdown-items {
      font-size: 1.2rem !important;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
    }
  }
  .pagination {
    background: transparent;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    button {
      svg {
        transform: scale(1.5);
        path {
          fill: var(--blue_600);
        }
      }
    }
  }
`;

interface IProps {
  isEnglish: boolean;
}

const Users = ({ isEnglish }: IProps) => {
  const { btnTxt1, inputPlaceholder, tab1, tab2, noResults } = isEnglish
    ? english
    : bahasa;
  const location = useLocation();

  const [blockedUser, setBlockedUser] = React.useState("&user_status=2");
  const [searchValue, debouncedValue, setSearchValue] = useDebounce("", 800);
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [first, setFirst] = React.useState<number>(0);
  const [rows, setRows] = React.useState(5);
  const [pageNo, setPageNo] = React.useState(1);
  const [addUserModal, setAddUserModal] = React.useState(false);
  const [optionsArray, setOptionsArray] = React.useState([
    {
      name: "Admin",
      id: 1,
      isSelected: false,
      key: "admin",
    },
    {
      name: "Agent",
      id: 2,
      isSelected: false,
      key: "agent",
    },
    {
      name: "User",
      id: 3,
      isSelected: false,
      key: "user",
    },
  ]);
  const [filterBy, setFilterBy] = React.useState<any>("");

  // Get user list from server
  const { data, isLoading, isError, refetch } = useUsersList(
    debouncedValue,
    rows,
    first,
    blockedUser,
    filterBy
  );
  const filterRef = React.useRef<any>(null);

  // for user status
  React.useEffect(() => {
    handleUserStatus(location.search);
    return () => {};
  }, [location]);

  // for filter
  React.useEffect(() => {
    handleFilterValues();
    return () => {};
  }, [optionsArray]);

  // checking user status or path
  function handleUserStatus(search: any) {
    if (search) {
      if (search.includes("?active")) {
        setBlockedUser("&user_status=1");
        setActiveTab(1);
        return;
      }
      if (search.includes("?blocked")) {
        setActiveTab(2);
        setBlockedUser("&user_status=2");
        return;
      } else {
        setBlockedUser("&user_status=1");
        setActiveTab(1);
      }
    } else {
      setBlockedUser("&user_status=1");
      setActiveTab(1);
    }
  }
  // add filter data
  const handleCheck = (value: any, id: any) => {
    setOptionsArray((prevState) => {
      return prevState.map((option) => {
        return option.id === id
          ? Object.assign({}, option, { isSelected: value })
          : option;
      });
    });
    filterRef.current.toggle(false);
  };
  // remove filter data
  const handleRemoveCheck = (id: any) => {
    setOptionsArray((prevState) => {
      return prevState.map((option) => {
        return option.id === id
          ? Object.assign({}, option, { isSelected: false })
          : option;
      });
    });
  };

  // set filter values
  const handleFilterValues = () => {
    let filterValues: any = optionsArray
      .filter((option) => option.isSelected)
      .map((option) => {
        return `${option.key}`;
      });
    filterValues = filterValues.join(",");
    setFilterBy(() => filterValues);
    setFirst(0);
  };
  //   pagination handler
  const handlePageChange = (e: PaginatorPageChangeEvent, index: number) => {
    setFirst(() => e.first);
    setRows(() => e.rows);
    setPageNo(() => e.first / e.rows + 1);
  };

  if (isError) {
    return <h1>{noResults}</h1>;
  }
  return (
    <UserWrapper>
      {addUserModal ? (
        <AddUser
          showModal={addUserModal}
          onHideModal={() => setAddUserModal(false)}
          isEnglish={isEnglish}
        />
      ) : null}
      <TopHeader>
        <CommonBox>
          <FilterBox onClick={(e) => filterRef.current.toggle(e)}>
            <IconsBox name="filter" />
          </FilterBox>
          <TabsWrapper>
            <div className="tab-view">
              {[1, 2].map((tab) => (
                <Link
                  to={`${ADMIN_MENU_PATHS.USER_MANAGEMENT}${
                    tab === 1 ? "?active" : "?blocked"
                  }`}
                  key={tab}
                >
                  <h1
                    onClick={() => setActiveTab(tab)}
                    className={tab === activeTab ? "tab activeTab" : "tab"}
                  >
                    {tab === 1 ? tab1 : tab2}
                  </h1>
                </Link>
              ))}
            </div>
          </TabsWrapper>
          <OverlayPanel ref={filterRef}>
            <SelectFilter>
              {optionsArray.map((option) => (
                <div
                  onClick={() => handleCheck(!option.isSelected, option.id)}
                  className="option"
                  key={option.id}
                >
                  <CheckInputBox
                    type="checkbox"
                    defaultChecked={option.isSelected}
                  />
                  <h3>{option.name}</h3>
                </div>
              ))}
            </SelectFilter>
          </OverlayPanel>
        </CommonBox>
        <SearchBox>
          <InputWrapper>
            <SearchInput
              className="search-input"
              type="search"
              placeholder={inputPlaceholder}
              value={searchValue}
              onChange={(event: any) => setSearchValue(event.target.value)}
            />
            <span className="search-icon">
              <IconsBox name="search" />
            </span>
          </InputWrapper>
          <AddButton onClick={() => setAddUserModal(true)}>{btnTxt1}</AddButton>
        </SearchBox>
      </TopHeader>
      <FilteredItems>
        {optionsArray
          .filter((option) => option.isSelected)
          .map((option) => (
            <FilteredItemsBtn
              key={option.id}
              onClick={() => handleRemoveCheck(option.id)}
            >
              {option.name}
              <IconsBox name="close-icon" fill="var(--blue_600)" />
            </FilteredItemsBtn>
          ))}
      </FilteredItems>
      {isLoading ? (
        <Spinner />
      ) : (
        <UsersList
          isEnglish={isEnglish}
          data={data ? data.usersList : []}
          onGetUsers={refetch}
        />
      )}
      <PaginationWrapper>
        <PaginationManger
          onPageChange={handlePageChange}
          row={rows}
          page={first}
          totalItems={data ? data.totalPages : 0}
        />
      </PaginationWrapper>
    </UserWrapper>
  );
};
export default Users;
