import { useMutation, useQuery } from "react-query";
import apiCall from "..";
import { API_END_POINTS } from "../api-end-points";
import { APP_ENV } from "../config";

// Get URLs
const fetchUrls = ({ queryKey }: any) => {
  return apiCall.get(
    `${API_END_POINTS.ADMIN_GET_URLS}?limit=${queryKey[1]}&offset=${queryKey[2]}`
  );
};
const useUrlList = (limit?: any, offSet?: any) => {
  return useQuery(["url-list", limit, offSet], fetchUrls, {
    select: (data) => {
      return {
        totalPages: data?.data?.count,
        urlList: data?.data?.results,
      };
    },
  });
};

// Get website scrapped data by id
const getScrapeData = async ({ queryKey }: any) => {
  if (!queryKey[1]) return;
  const data = await apiCall.get(`${API_END_POINTS.ADMIN_GET_SCRAPE_DATA}/${queryKey[1]}`);
  if (queryKey[2]) {
    return data
  } else {
    if (data?.data?.data) {
      const translateData = await translateDataToBahasa(data?.data?.data);
      data.data.data.content = translateData.content;
      data.data.data.title = translateData.title;
      if (data.data.data.challenges_and_opportunities) {
        data.data.data.challenges_and_opportunities = translateData.challenges_and_opportunities;
      }
      return data;
    } else {
      return data
    }
  }
};

const parseJSONWithCorrections = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    let correctedJsonString = jsonString.replace(/'/g, '"');
    try {
      return JSON.parse(correctedJsonString);
    } catch (e) {
      if (jsonString.includes("'oportunis'")) {
        let correctedOportunisJsonString = jsonString.replace(
          /'oportunis'/g,
          '"opportunities"'
        );
        try {
          correctedOportunisJsonString = correctedOportunisJsonString.replace(
            /'/g,
            '"'
          );
          // Step 2: Add commas between array elements and correct any syntax errors
          console.log(correctedOportunisJsonString);
          return JSON.parse(correctedOportunisJsonString);
        } catch (e) {
          return jsonString; // or throw new Error("Failed to parse JSON");
        }
      } else {
        return jsonString; // or throw new Error("Failed to parse JSON");
      }
    }
  }
};

const useScrapeData = (limit?: any, isEnglish?: any) => {
  return useQuery(["url-list", limit, isEnglish], getScrapeData, {
    select: (data) => {
      return data?.data?.data;
    },
    refetchOnWindowFocus: false,
  });
};


const translateDataToBahasa = async (data: any) => {
  let payloadData: any = {
    content: data.content,
    title: data.title
  }
  if (data.challenges_and_opportunities) {
    payloadData.challenges_and_opportunities = parseJSONWithCorrections(data.challenges_and_opportunities);
  }

  const payload = {
    data: payloadData
  };
  try {
    const response = await apiCall.post(
      `${APP_ENV.BE_UPDATED_AI_BASE_URL}/translate`,
      payload
    );
    let jsonData;
    try {
      jsonData = JSON.parse(response?.data?.data);
    }
    catch (e) {
      jsonData = response?.data?.data;
    }
    return jsonData;
  } catch (error: any) { console.log(error) }
}

// Add URL
const addUrl = (urlData: any) => {
  return apiCall.post(API_END_POINTS.ADMIN_ADD_URL, urlData);
};
const useAddUrl = (onSuccess: any, onError: any) => {
  return useMutation(addUrl, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

// Delete URL
const deleteUrl = (urlId: any) => {
  return apiCall.delete(`${API_END_POINTS.ADMIN_DELETE_URL}/${urlId}/`);
};
const useDeleteUrl = (onSuccess: any, onError: any) => {
  return useMutation(deleteUrl, {
    onSuccess: onSuccess,
    onError: onError,
  });
};

export { useUrlList, useScrapeData, useAddUrl, useDeleteUrl };
