const english = {
  btnTxt1: "Add to Monitoring",
  btnTxtChallenge: 'Challenges',
  keyTrendsText: "Key Trends",
  challengesText: "Challenges",
  opportunityText: "Opportunities",
  inputLabel: "Enter URL",

  // table headings
  tableHeading: "Monitoring List",
  tUrl: "URL to be Monitored",
  tAction: "Action",
  tableSeeAllText: "See All",
  tableSeeLessText: "See Less",
  moreOptText1: "Delete",
  moreOptViewTxt: "View",

  // Modal text
  modalHeader: "Delete",
  modalContent: "Are you sure to Delete this URL?",
  modalBtnText1: "Delete",
  modalBtnText2: "No",

  // error message
  errorText1: "Enter valid URL",
  noResultFoundText: "No results found!",

  // Delete modal
  successText: "Successfully deleted url",
  errorText: "Failed to delete!!",
};
const bahasa = {
  btnTxt1: "Tambahkan ke Pemantauan",
  btnTxtChallenge: 'Tantangan',
  inputLabel: "Masukkan URL",
  challengesText: "Tantangan",
  opportunityText: "Peluang",
  keyTrendsText: "Tren Kunci",

  // table headings
  tableHeading: "Daftar Pemantauan",
  tUrl: "URL yang akan Dipantau",
  tAction: "Tindakan",
  tableSeeAllText: "Lihat semua",
  tableSeeLessText: "Lihat lebih sedikit",
  moreOptText1: "Hapus",
  moreOptViewTxt: "Lihat",

  // Modal text
  modalHeader: "Hapus",
  modalContent: "Anda yakin ingin menghapus URL ini?",
  modalBtnText1: "Hapus",
  modalBtnText2: "TIDAK",

  // error message
  errorText1: "Masukkan URL yang valid",
  noResultFoundText: "Tidak ada hasil yang ditemukan!",

  // Delete modal
  successText: "Berhasil menghapus url",
  errorText: "Gagal menghapus!!",
};

export { english, bahasa };
