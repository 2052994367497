import styled from "styled-components";
import { bahasa, english } from "../text";
import React from "react";
import IconsBox from "../../../../components/ui-components/iconbox";
import { usePersonBasedData } from "../../../../api/user-api/dashboard-api";
import Spinner from "../../../../components/ui-components/spinner";
import { Select } from "antd";
import { Carousel } from "antd";
import { AuthButton } from "../../../../pages/user-login/components/login";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 1.5rem;
  gap: 1.5rem;
  background-color: var(--white_900);
  .keytrend-text {
    // max-width: 15rem;
    color: var(--blue_800);
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2rem;
    letter-spacing: -0.02em;
    color: var(--blue_800);
  }
  .refreshImg {
    width: 17px;
    display: inline-block;
    margin-left: 10px;
    vertical-align: top;
    margin-top: 1px;
    transform: rotate(0deg);
    cursor: pointer;
    transition-duration: 400ms;
    &:hover {
      transform: rotate(180deg);
    }
  }
`;
const SelectTagBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  width: 20rem;
  label {
    font-size: 1.2rem;
    font-weight: 500;
  }
  .error-msg {
    font-size: 1rem !important;
    position: absolute;
    bottom: -1.8rem;
    left: 0;
  }
`;
const AvatarCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  padding: 2rem;
  border-radius: 1rem;
  /* box-shadow: 0 0 0 0 rgba(224, 237, 247, 1); */
  background-image: url("/images/persona-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media screen and (max-width: 698px) {
    flex-wrap: wrap;
  }
`;
const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  .persona-img {
    width: 7.3rem;
    border-radius: 50%;
    outline: 0.8rem solid var(--white_900);
  }
`;
const AvatarDes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  .avatar-name {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: -0.015em;
  }
  .avatar-designation {
    color: var(--gray_600);
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: -0.015em;
  }
`;
const DetailsWrapper = styled.div`
  position: relative;
  max-width: 60%;
  padding: 2rem;
  border-radius: 1rem;
  background-color: var(--white_900);
  box-shadow: 0px 4px 26px 0px rgba(239, 239, 239, 1);
  .quote-icon {
    position: absolute;
    top: -10px;
    left: 10px;
  }
`;

const Issue = styled.p`
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
`;
export const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin: 0 0 1.5rem 0;
  flex-wrap: wrap;
  @media screen and (max-width: 698px) {
  }
`;

const DataWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 2rem;
  border-radius: 1.5rem;
  padding: 1rem 0;
  /* background-color: var(--blue_200); */
`;
const ChallengesCard = styled.div`
  max-width: 27rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const CardHead = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  /* flex-direction: column; */
  margin: 0 0 2.3rem 0;
  .color-badge,
  .color-badge-2 {
    width: 1rem;
    height: 1rem;
    border-radius: 5rem;
    background-color: var(--green_400);
  }
  .color-badge-2 {
    background-color: var(--yellow_700) !important;
  }
  .card-heading {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    color: var(--bluish_gray);
  }
`;
const Card = styled.div`
  width: fit-content;
  .card-title {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: var(--blue_800);
    margin: 0 0 0.8rem 0;
  }
  .card-description {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: var(--gray_600);
    margin: 0 0 2.5rem 0;
  }
`;

const PersonaWrapper = styled.div`
  width: 100%;
`;
const CarouselWrapper = styled(Carousel)`
  > .slick-dots li button {
    background: black;
    color: black;
    margin-top: 5px;
  }
  > .slick-dots li.slick-active button {
    background: black;
    color: black;
  }
`;

const ConfirmButton = styled(AuthButton)`
  width: max(14.8rem, 19rem);
  height: max(3.7rem, 21px);
  margin-top: 12px;
`;

const OpportunityCard = styled(ChallengesCard)``;

interface IProps {
  isEnglish: boolean;
  dashboardData: any;
  placeName: any;
  loaded2?: (val:any) => void;
}
const Persona = ({ isEnglish, placeName, loaded2 }: IProps) => {
  const {
    listenToText,
    s1LabelText,
    s2LabelText,
    s3LabelText,
    s4LabelText,
    s5LabelText,
    s6LabelText,
    challengesText,
    opportunityText,
    submitText,
  } = isEnglish ? english : bahasa;

  const ageArray = [
    { label: "12-17", value: "12 to 17" },
    { label: "18-30", value: "18 to 30" },
    { label: "31-45", value: "31 to 45" },
    { label: "46-65", value: "46 to 65" },
    { label: "above 65", value: "65 to 100" },
  ];
  const bahasaAgeArray = [
    { label: "12-17", value: "12 to 17" },
    { label: "18-30", value: "18 to 30" },
    { label: "31-45", value: "31 to 45" },
    { label: "46-65", value: "46 to 65" },
    { label: "di atas 65", value: "65 to 100" },
  ];
  const yesNoArray = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];
  const bahasaYesNoArray = [
    { label: "Ya", value: "yes" },
    { label: "TIDAK", value: "no" },
  ];
  const genderArray = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Others", value: "others" },
  ];
  const bahasaGenderArray = [
    { label: "Laki-laki", value: "male" },
    { label: "Perempuan", value: "female" },
    { label: "Lainnya", value: "others" },
  ];
  const incomeArray = [
    { label: "0 - IDR 12,000,000", value: "0 to IDR 12,000,000" },
    {
      label: "IDR 12,000,000 - IDR 24,000,000",
      value: "IDR 12,000,000 to IDR 24,000,000",
    },
    {
      label: "IDR 24,000,000 - IDR 48,000,000",
      value: "IDR 24,000,000 to IDR 48,000,000",
    },
    {
      label: "IDR 48,000,000 - IDR 96,000,000",
      value: "IDR 48,000,000 to IDR 96,000,000",
    },
    {
      label: "IDR 96,000,000 - IDR 192,000,000",
      value: "IDR 96,000,000 to IDR 192,000,000",
    },
    {
      label: "Above 192,000,000",
      value: "Above 192,000,000",
    },
  ];
  const bahasaIncomeArray = [
    { label: "0 - IDR 12,000,000", value: "IDR 0 to IDR 12,000,000" },
    {
      label: "IDR 12,000,000 - IDR 24,000,000",
      value: "IDR 12,000,000 to IDR 24,000,000",
    },
    {
      label: "IDR 24,000,000 -IDR 48,000,000",
      value: "IDR 24,000,000 -IDR 48,000,000",
    },
    {
      label: "IDR 48,000,000 - IDR 96,000,000",
      value: "IDR 48,000,000 to IDR 96,000,000",
    },
    {
      label: "IDR 96,000,000 - IDR 192,000,000",
      value: "IDR 96,000,000 to IDR 192,000,000",
    },
    {
      label: "Lebih dari IDR 192,000,000",
      value: "Above IDR 192,000,000",
    },
  ];

  const employeeStatus = [
    { label: "Work Full Time", value: "work full time" },
    { label: "Work part time", value: "work part time" },
    { label: "Doesn't work", value: "doesn't work" },
    { label: "Self-employed", value: "self-employed" },
    { label: "Housewife", value: "housewife" },
    { label: "Student", value: "student" },
    { label: "Pension", value: "pension" },
    { label: "Others", value: "any work" },
  ];

  const bahasaEmployeeStatus = [
    { label: "Bekerja Penuh Waktu", value: "work full time" },
    { label: "Bekerja Paruh Waktu", value: "work part time" },
    { label: "Tidak Bekerja", value: "doesn't work" },
    { label: "Wiraswasta", value: "self-employed" },
    { label: "Ibu rumah tangga", value: "housewife" },
    { label: "Murid", value: "student" },
    { label: "Pensiun", value: "pension" },
    { label: "Lainnya", value: "any work" },
  ];

  const [callApi, setCallApi] = React.useState(false);
  const [isGender, setIsGender] = React.useState("Male");

  const initialState = {
    // age: "12 to 17",
    disability: "no",
    indigenous: "no",
    gender: "male",
    income: "IDR 0 to IDR 12,000,000",
    work: "any work",
  };

  const [formData, setFormData] = React.useState(initialState);

  const handleSelect = (value: any, name: any) => {
    if (name === "gender") {
      setIsGender(value);
    }
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const {
    data: personaDataFull,
    isLoading,
    isFetching,
  }: any = usePersonBasedData(formData, placeName, callApi, isEnglish);

  const handleSelectedData = async () => {
    setCallApi(() => false);
    if (!placeName) {
      return;
    }
    setCallApi(() => true);
    setTimeout(() => {
      setCallApi(() => false);
    }, 100);
  };

  React.useEffect(() => {
    handleSelectedData();
  }, [placeName, isEnglish]);

  React.useEffect(() => {
    if (!isLoading && !isFetching) {
      if (loaded2) {
        loaded2(true);
      }
    }else{
      if (loaded2) {
        loaded2(false);
      }
    }
  }, [isLoading, isFetching]);

  return (
    <Wrapper className="child-components">
      <h2 className="keytrend-text">
        {listenToText}{" "}
        {!isLoading && !isFetching ? (
          <img
            onClick={handleSelectedData}
            className="refreshImg"
            src="/images/refresh.png"
            alt="refresh"
          />
        ) : null}
      </h2>
      <SelectWrapper>
        {/* <SelectTagBox>
          {" "}
          <label>{s1LabelText + "*"}</label>
          <Select
            options={isEnglish ? ageArray : bahasaAgeArray}
            suffixIcon={<IconsBox name="dropdown" />}
            onSelect={(value: any) => handleSelect(value, "age")}
            placeholder={formData.age}
            value={formData.age}
          />
        </SelectTagBox> */}
        <SelectTagBox>
          {" "}
          <label>{s4LabelText + "*"}</label>
          <Select
            options={isEnglish ? yesNoArray : bahasaYesNoArray}
            suffixIcon={<IconsBox name="dropdown" />}
            onSelect={(value: any) => handleSelect(value, "disability")}
            placeholder={formData.disability}
            value={formData.disability}
          />
        </SelectTagBox>
        <SelectTagBox>
          {" "}
          <label>{s5LabelText + "*"}</label>
          <Select
            options={isEnglish ? yesNoArray : bahasaYesNoArray}
            suffixIcon={<IconsBox name="dropdown" />}
            onSelect={(value: any) => handleSelect(value, "indigenous")}
            placeholder={formData.indigenous}
            value={formData.indigenous}
          />
        </SelectTagBox>
        <SelectTagBox>
          {" "}
          <label>{s2LabelText + "*"}</label>
          <Select
            options={isEnglish ? genderArray : bahasaGenderArray}
            suffixIcon={<IconsBox name="dropdown" />}
            onSelect={(value: any) => handleSelect(value, "gender")}
            placeholder={formData.gender}
            value={formData.gender}
          />
        </SelectTagBox>
        <SelectTagBox>
          {" "}
          <label>{s3LabelText + "*"}</label>
          <Select
            options={isEnglish ? incomeArray : bahasaIncomeArray}
            suffixIcon={<IconsBox name="dropdown" />}
            onSelect={(value: any) => handleSelect(value, "income")}
            placeholder={formData.income}
            value={formData.income}
          />
        </SelectTagBox>
        <SelectTagBox>
          {" "}
          <label>{s6LabelText + "*"}</label>
          <Select
            options={isEnglish ? employeeStatus : bahasaEmployeeStatus}
            suffixIcon={<IconsBox name="dropdown" />}
            onSelect={(value: any) => handleSelect(value, "work")}
            placeholder={formData.work}
            value={formData.work}
          />
        </SelectTagBox>
        <ConfirmButton onClick={handleSelectedData}>{submitText}</ConfirmButton>
      </SelectWrapper>
      <CarouselWrapper autoplay>
        {personaDataFull &&
          personaDataFull?.user_persona &&
          personaDataFull?.user_persona.length > 0 &&
          personaDataFull?.user_persona.map((personaData: any) => (
            <>
              <PersonaWrapper key={personaData.person_name}>
                <AvatarCard>
                  <Avatar>
                    {isGender ? (
                      <img
                        className="persona-img"
                        src={
                          isGender === "female"
                            ? "/images/female.png"
                            : isGender === "male"
                            ? "/images/male.png"
                            : "/images/male.png"
                        }
                        alt="person"
                      />
                    ) : null}
                    <AvatarDes>
                      <h1 className="avatar-name">
                        {personaData && !isLoading && !isFetching
                          ? personaData?.person_name
                          : ""}
                      </h1>
                      <h3 className="avatar-designation">
                        {personaData && !isLoading && !isFetching
                          ? personaData?.person_occupation
                          : ""}
                      </h3>
                    </AvatarDes>
                  </Avatar>
                  <DetailsWrapper>
                    <span className="quote-icon">
                      <IconsBox name={"quote"} />
                    </span>
                    <Issue>
                      {personaData && !isLoading && !isFetching
                        ? personaData?.persona_bio
                        : ""}
                    </Issue>
                  </DetailsWrapper>
                </AvatarCard>
              </PersonaWrapper>
              <DataWrapper>
                <ChallengesCard>
                  <CardHead>
                    <div className="color-badge"></div>
                    <h2 className="card-heading">{challengesText}</h2>
                  </CardHead>
                  {personaData.c_o?.challenges?.length
                    ? personaData.c_o?.challenges?.map(
                        (item: any, indx: number) => (
                          <Card key={indx}>
                            <h3 className="card-title">{item?.challenge}</h3>
                            <p className="card-description">
                              {item?.expansion}
                              {/* {item?.split("Challenge")[1] ?? item} */}
                            </p>
                          </Card>
                        )
                      )
                    : "No data to show"}
                </ChallengesCard>
                <OpportunityCard>
                  <CardHead>
                    <div className="color-badge-2"></div>
                    <h2 className="card-heading">{opportunityText}</h2>
                  </CardHead>
                  {personaData.c_o?.opportunities?.length
                    ? personaData.c_o?.opportunities?.map(
                        (item: any, indx: number) => (
                          <Card key={indx}>
                            <h3 className="card-title">{item?.opportunity}</h3>
                            <p className="card-description">
                              {item?.expansion}
                              {/* {item?.split("Opportunity")[1] ?? item} */}
                            </p>
                          </Card>
                        )
                      )
                    : "No data to show"}
                </OpportunityCard>
              </DataWrapper>
            </>
          ))}
      </CarouselWrapper>
      {isLoading || isFetching ? <Spinner /> : null}
    </Wrapper>
  );
};

export default React.memo(Persona);
