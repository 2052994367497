import React, { useState } from "react";
import { Text } from "@visx/text";
import { scaleLog } from "@visx/scale";
import Wordcloud from "@visx/wordcloud/lib/Wordcloud";

interface ExampleProps {
  width: number;
  height: number;
  showControls?: boolean;
  cloudWords: any;
}

export interface WordData {
  text: string;
  value: number;
}

export default function WordClouds({
  width,
  height,
  showControls,
  cloudWords,
}: ExampleProps) {
  const colors = [
    "#143059",
    "#2F6B9A",
    "#82a6c2",
    "#2F4F4F",
    "#4B0082",
    "#800000",
    "#008080",
    "#654321",
    "#333333",
    "#4B0082",
    "#00008B",
    "#006400",
    "#8B0000",
  ];

  function wordFreq(text: string): WordData[] {
    if (!text) {
      text = "";
    }
    const words: string[] = text.replace(/\./g, "").split(/\s/);
    const freqMap: Record<string, number> = {};

    for (const w of words) {
      if (!freqMap[w]) freqMap[w] = 0;
      freqMap[w] += 1;
    }
    return Object.keys(freqMap).map((word) => ({
      text: word,
      value: freqMap[word],
    }));
  }

  function getRotationDegree() {
    const rand = Math.random();
    const degree = rand > 0.5 ? 60 : -60;
    return rand * degree;
  }
  const words = wordFreq(cloudWords);

  const fontScale = scaleLog({
    domain: [
      Math.min(...words.map((w) => w.value)),
      Math.max(...words.map((w) => w.value)),
    ],
    range: [15, 60],
  });
  const fontSizeSetter = (datum: WordData) => fontScale(datum.value);

  const fixedValueGenerator = () => 0.5;

  type SpiralType = "archimedean" | "rectangular";

  const [spiralType] = useState<SpiralType>("archimedean");
  const [withRotation] = useState(false);

  return (
    <Wordcloud
      words={words}
      width={width}
      height={height}
      fontSize={fontSizeSetter}
      font={"Inter"}
      padding={3}
      spiral={spiralType}
      rotate={withRotation ? getRotationDegree : 0}
      random={fixedValueGenerator}
    >
      {(cloudWords: any) =>
        cloudWords.map((w: any, i: any) => (
          <Text
            key={w.text}
            fill={colors[i % colors.length]}
            textAnchor={"middle"}
            transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
            fontSize={w.size}
            fontFamily={w.font}
          >
            {w.text?.replace(/[^a-zA-Z0-9\s]/g, "")}
          </Text>
        ))
      }
    </Wordcloud>
  );
}
