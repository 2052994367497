import styled from "styled-components";
import { Route, Routes } from "react-router-dom";
import Users from "./components/users";
import React from "react";
import UserDetails from "./components/user-detail-page";
import { LanguageContext } from "../../../store/language-context";
import { ADMIN_MENU_PATHS } from "../../../all-routes/paths";

const UserManagementWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function UserManagement() {
  const languageCtx = React.useContext(LanguageContext);

  return (
    <UserManagementWrapper>
      <Routes>
        <Route
          path={ADMIN_MENU_PATHS.USER_MANAGEMENT_HOME}
          element={<Users isEnglish={languageCtx.isEnglish} />}
        />
        <Route
          path={`${ADMIN_MENU_PATHS.USER_MANAGEMENT_HOME}${ADMIN_MENU_PATHS.USER_MANAGEMENT_USER_DETAILS}`}
          element={<UserDetails isEnglish={languageCtx.isEnglish} />}
        />
      </Routes>
    </UserManagementWrapper>
  );
}
export default UserManagement;
