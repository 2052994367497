import React, { useState } from "react";
import { BaseContainer } from "./components/common-styles";
import AllRoutes from "./all-routes";
import styled from "styled-components";

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #dcdcdf;
  border-radius: 10px;
  padding: 20px;
  z-index: 1001;
  width: 600px;
  max-width: 90vw;
`;

const IFrame = styled.iframe`
  border: none;
  height: 500px;
  max-height: 80vh;
  width: 100%;
`;

const ButtonPop = styled.button`
  position: fixed;
  bottom: 10px;
  right: 20px;
  background-color: rgb(0, 123, 255);
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: -1px 1px 1px 2px #c3c3c3;
`;

function App() {
  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };
  return (
    <BaseContainer>
      <ButtonPop onClick={toggleForm}>Need Help?</ButtonPop>
      {showForm && (
        <PopupOverlay onClick={toggleForm}>
          <Popup>
            <IFrame
              title="Support Form"
              src="https://forms.zohopublic.in/zohopeople140/form/UNDPSupportForm/formperma/cCPc7DVhiRPXFzISZQXNhBybeulB7ml6R9V6dAPkRnE?zf_rszfm=1"
              aria-label="Support Form"
            ></IFrame>
          </Popup>
        </PopupOverlay>
      )}
      <AllRoutes />
    </BaseContainer>
  );
}

export default App;
