import styled from "styled-components";
import { CancelButton, SubmitButton } from "./sub-components/create-category";
import React from "react";
import { bahasa, english } from "./text";
import { BackBtn, TopBox } from "../../../manage-files/components/upload-file";
import IconsBox from "../../../../components/ui-components/iconbox";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ADMIN_MENU_PATHS } from "../../../../all-routes/paths";
import AssignCategory from "./sub-components/assign-category";
import AssignSubCategory from "./sub-components/assign-subcategory";
import AssignKeyword from "./sub-components/assign-keyword";
import TableContainer from "./detail-tables";
import {
  useAssignSurvey,
  useGetById,
  useSurveyListForSelect,
} from "../../../../api/admin-api/category-management";
import EditCategory from "./sub-components/edit-category";
import EditSubCategory from "./sub-components/edit-subcategory";
import EditKeyword from "./sub-components/edit-keyword";
import Spinner from "../../../../components/ui-components/spinner";
import Toaster from "../../../../components/ui-components/toaster";

const CMWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DetailsWrapper = styled.div`
  margin: 1.2rem 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: var(--white_900);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
  border-radius: 0.9rem;
  padding: 3.5rem;
  .details-text {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: -0.02em;
    margin: 2.4rem 0 1.6rem 0;
  }
`;
const DetailsHeader = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
`;
const TextBox = styled.div`
  max-width: 45.6rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  .heading {
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: -0.02em;
  }
  .desc {
    color: var(--gray_800);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
  }
`;
const ButtonBox = styled.div`
  display: flex;
  gap: 1.6rem;
`;

const DetailsBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .child-div {
    margin: 2.5rem 0 0 0;
    flex: 29rem;
  }
`;
const Box = styled.div`
  max-width: 29rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  .box-heading {
    color: var(--gray_800);
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.02em;
  }
  .box-value {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.02em;
    margin: 1.6rem 0 1.8rem 0;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: var(--gray_350);
  }
`;

// none styles
interface IProps {
  isEnglish: boolean;
  activeHomeTab: any;
}

const CmDetailPage = ({ activeHomeTab, isEnglish }: IProps) => {
  const {
    editText,
    assignText,
    backText,
    detailText,
    updatedOn,
    addedOn,
    reportsText,
    surveyText,
    keywordText,
    subCatText,
    tableSubName2,
    assignSuccessText,
    smtgWentWrgText,
  } = isEnglish ? english : bahasa;

  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const [editModal, setEditModal] = React.useState(false);
  const [assignModal, setAssignModal] = React.useState(false);

  // Get Details
  const {
    data: detailedData,
    refetch: getDetails,
    isLoading,
    isFetching,
    isError,
  } = useGetById(id, activeHomeTab);

  //Get survey list from server
  const { data: surveyList } = useSurveyListForSelect();

  // Survey Assign
  const onSuccess = (res: any) => {
    getDetails();
    setShowToast({
      status: true,
      type: "success",
      message: assignSuccessText,
      reason: "",
    });
  };
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: smtgWentWrgText,
      reason: res?.response?.data?.message,
    });
  };
  const { mutate: assignSurvey } = useAssignSurvey(onSuccess, onError);

  React.useEffect(() => {}, [isFetching]);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <>
      <Toaster
        showToast={showToast.status}
        severity={showToast.type}
        summary={showToast.message}
        detail={showToast.reason}
      />
      <CMWrapper>
        <TopBox>
          <BackBtn
            onClick={() => navigate(`${ADMIN_MENU_PATHS.CATEGORY_MANAGEMENT}`)}
          >
            <IconsBox name="left-arrow" /> {backText}
          </BackBtn>
        </TopBox>
        <DetailsWrapper>
          <DetailsHeader>
            <TextBox>
              <h2 className="heading">{detailedData?.name || "-"}</h2>
              <p className="desc">{detailedData?.description || "-"}</p>
            </TextBox>
            <ButtonBox>
              <CancelButton onClick={() => setEditModal(true)}>
                {editText}
              </CancelButton>
              <SubmitButton onClick={() => setAssignModal(true)}>
                {assignText}
              </SubmitButton>
            </ButtonBox>
          </DetailsHeader>
          <p className="details-text">{detailText}</p>
          <DetailsBox>
            <Box className="child-div">
              <h3 className="box-heading">
                {activeHomeTab === 1 ? subCatText : tableSubName2}
              </h3>
              <p className="box-value">
                {activeHomeTab === 1
                  ? detailedData?.subcategory_count || "-"
                  : detailedData?.category_count || "-"}
              </p>
              <div className="line"></div>
            </Box>
            <Box className="child-div">
              <h3 className="box-heading">
                {" "}
                {activeHomeTab === 3 ? subCatText : keywordText}
              </h3>
              <p className="box-value">
                {" "}
                {activeHomeTab === 3
                  ? detailedData?.subcategory_count || "-"
                  : detailedData?.keywords_count || "-"}
              </p>
              <div className="line"></div>
            </Box>
            <Box className="child-div">
              <h3 className="box-heading">{addedOn}</h3>
              <p className="box-value">{detailedData?.created_at || "-"}</p>
              <div className="line"></div>
            </Box>
            <Box className="child-div">
              <h3 className="box-heading">{surveyText}</h3>
              <p className="box-value">{detailedData?.survey_count || "-"}</p>
              <div className="line"></div>
            </Box>
            <Box className="child-div">
              <h3 className="box-heading">{reportsText}</h3>
              <p className="box-value">{detailedData?.reports_count || "-"}</p>
              <div className="line"></div>
            </Box>
            <Box className="child-div">
              <h3 className="box-heading">{updatedOn}</h3>
              <p className="box-value">{detailedData?.updated_at || "-"}</p>
              <div className="line"></div>
            </Box>
          </DetailsBox>
        </DetailsWrapper>

        {/* tabs & tables */}
        {isFetching ? (
          <Spinner />
        ) : (
          <TableContainer
            isEnglish={isEnglish}
            id={id}
            activeHomeTab={activeHomeTab}
            activeTab={activeTabIndex}
            onGetList={getDetails}
            onChangeTab={(tab: number) => setActiveTabIndex(tab)}
          />
        )}
      </CMWrapper>
      {/* Edit  */}
      {isFetching ? null : (
        <>
          {" "}
          {activeHomeTab === 1 ? (
            <EditCategory
              key={"edit-category"}
              onHideModal={() => setEditModal(false)}
              showModal={editModal}
              isEnglish={isEnglish}
              tabIndex={activeHomeTab}
              editMood={editModal}
              editData={detailedData}
              onRefetch={getDetails}
            />
          ) : activeHomeTab === 2 ? (
            <EditSubCategory
              key={"edit-sub-category"}
              onHideModal={() => setEditModal(false)}
              showModal={editModal}
              isEnglish={isEnglish}
              tabIndex={activeHomeTab}
              editMood={editModal}
              editData={detailedData}
              onRefetch={getDetails}
            />
          ) : (
            <EditKeyword
              key={"edit-keyword"}
              onHideModal={() => setEditModal(false)}
              showModal={editModal}
              isEnglish={isEnglish}
              tabIndex={activeHomeTab}
              editMood={editModal}
              editData={detailedData}
              onRefetch={getDetails}
            />
          )}
        </>
      )}
      {/* Assign modals */}

      {activeHomeTab === 1 && assignModal ? (
        <AssignCategory
          key={"assign-category"}
          onHideModal={() => setAssignModal(false)}
          showModal={assignModal}
          isEnglish={isEnglish}
          tabIndex={activeHomeTab}
          editData={detailedData}
          surveyList={surveyList?.length ? surveyList : []}
          onAssign={assignSurvey}
        />
      ) : activeHomeTab === 2 && assignModal ? (
        <AssignSubCategory
          key={"assign-sub-category"}
          onHideModal={() => setAssignModal(false)}
          showModal={assignModal}
          isEnglish={isEnglish}
          tabIndex={activeHomeTab}
          editData={detailedData}
          surveyList={surveyList?.length ? surveyList : []}
          onAssign={assignSurvey}
        />
      ) : activeHomeTab === 3 && assignModal ? (
        <AssignKeyword
          key={"assign-keyword"}
          onHideModal={() => setAssignModal(false)}
          showModal={assignModal}
          isEnglish={isEnglish}
          tabIndex={activeHomeTab}
          editData={detailedData}
          surveyList={surveyList?.length ? surveyList : []}
          onAssign={assignSurvey}
        />
      ) : null}
    </>
  );
};

export default CmDetailPage;
