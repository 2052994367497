import React from "react";
import styled from "styled-components";
import { OverlayPanel } from "primereact/overlaypanel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import IconsBox from "../../../../../components/ui-components/iconbox";
import { TableWrapper } from "../../../../../components/common-styles";
import { bahasa, english } from "../text";
import DeleteFile from "../../../../manage-files/components/sub-components/delete-file";
import { useDeleteFile } from "../../../../../api/user-api";
import Toaster from "../../../../../components/ui-components/toaster";

const ListContainer = styled(TableWrapper)`
  .more-icon {
    cursor: pointer;
  }
  thead {
    tr {
      th {
        :first-child {
          justify-content: flex-start !important;
          div {
            margin: 0 0 0 1rem !important;
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        :first-child {
          /* max-width: 220px;  */
          /* white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;  */
          justify-content: flex-start !important;
          /* margin: 0 0 0 1rem !important; */
        }
      }
    }
    .tags-temp {
      max-width: 120px;
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

const FileNameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  .file-image {
    width: 3.6rem;
    height: 2.8rem;
    border-radius: 0.4rem;
  }
`;
const MoreMenuWrapper = styled.span`
  .more-icon {
    :hover {
      svg {
        path {
          stroke: var(--blue_500) !important;
          fill: var(--blue_500) !important;
        }
      }
    }
  }
`;
const MoreMenu = styled.div`
  position: relative;
  width: fit-content;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.8rem;
  border-radius: 0.5rem;
  ::before {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: -1.6rem;
    right: -0.6rem;
    border-radius: 0.2rem;
    transform: rotate(-45deg);
    background-color: var(--white_900);
    box-shadow: 1px -2px 1px -1px rgba(0, 0, 0, 0.1);
  }
  .more-item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.4rem;
    padding: 0.5rem;
    .view-text,
    .trash-text {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0.5px;
      margin: 0 0 0 1rem;
    }
    .trash-text {
      color: var(--red_600);
    }
    :hover {
      cursor: pointer;
      background-color: var(--light_gray);
    }
  }
`;

interface IProps3 {
  isEnglish: boolean;
  data?: any[];
  text1?: string;
  text2?: string;
  onShowFile: any;
  onRefetch: any;
  isLoading: boolean;
}

function UserFilesList({
  isEnglish,
  data,
  text1,
  text2,
  onShowFile,
  onRefetch,
  isLoading,
}: IProps3) {
  const {
    fileNameTH,
    categoryTH,
    keywordsTH,
    fileSizeTH,
    dateCreatedTH,
    actionTH,
  } = isEnglish ? english : bahasa;
  const moreRef = React.useRef<any>(null);
  const [selectedFiles, setSelectedFiles] = React.useState<any[]>([]);
  const [currentFile, setCurrentFile] = React.useState<any>("");
  const [deleteModal, setDeleteModal] = React.useState<any>(false);
  const [showToast, setShowToast] = React.useState({
    status: false,
    type: "",
    message: "",
    reason: "",
  });

  const onSuccess = (res: any) => {
    onRefetch();
    setShowToast({
      status: true,
      type: "success",
      message: "File Deleted!",
      reason: "",
    });
    setTimeout(() => setDeleteModal(false), 1000);
  };
  const onError = (res: any) => {
    setShowToast({
      status: true,
      type: "error",
      message: "File delete Failed!",
      reason: res?.response?.data?.message,
    });
  };
  const { mutate: deleteFile } = useDeleteFile(onSuccess, onError);

  function handleRowClick(event: any) {
    const cellNum = event?.originalEvent?.target?.cellIndex;
    setCurrentFile(event?.data);
    if (cellNum) {
      onShowFile(event?.data);
    }
  }

  const moreTemplate = () => {
    const file = currentFile;
    return (
      <MoreMenuWrapper>
        <span className="more-icon" onClick={(e) => moreRef.current.toggle(e)}>
          <IconsBox name="three-dots-v" />
        </span>
        <OverlayPanel ref={moreRef}>
          <MoreMenu>
            <div
              className="more-item"
              onClick={(event: any) => {
                event.stopPropagation();
                moreRef.current.toggle(false);
                onShowFile(file);
              }}
            >
              <IconsBox name="visibility" />
              <p className="view-text">{text1}</p>
            </div>
            <div
              className="more-item"
              onClick={(event: any) => {
                event.stopPropagation();
                setDeleteModal(true);
                moreRef.current.toggle(false);
              }}
            >
              <IconsBox name="trash" />
              <p className="trash-text">{text2}</p>
            </div>
          </MoreMenu>
        </OverlayPanel>
      </MoreMenuWrapper>
    );
  };
  const fileNameTemplate = (data: any) => {
    return (
      <FileNameWrapper>
        {data?.file_format === "image" ? (
          <img className="file-image" src={data?.file_url} alt="pic" />
        ) : data?.file_format === "audio" ? (
          <img className="file-image" src="/images/audio-img.png" alt="audio" />
        ) : data?.file_format === "video" ? (
          <img className="file-image" src="/images/video-1.png" alt="video" />
        ) : (
          <img className="file-image" src="/images/excel.png" alt="excel" />
        )}
        {data.file_name_original ? data.file_name_original : data.file_name}
      </FileNameWrapper>
    );
  };
  const tagsTemplate = (data: any) => {
    return (
      <div className="tags-temp">
        <p>{data?.keywords}</p>
      </div>
    );
  };
  return (
    <ListContainer>
      <Toaster
        showToast={showToast.status}
        severity={showToast.type}
        summary={showToast.message}
        detail={showToast.reason}
      />
      <DataTable
        value={data}
        tableStyle={{
          width: "100%",
          fontSize: "1.4rem",
        }}
        // selectionMode="checkbox"
        // selection={selectedFiles}
        // onSelectionChange={(e: any) => setSelectedFiles(e.value)}
        onRowClick={handleRowClick}
        rowHover={true}
      >
        {/* <Column selectionMode="multiple" exportable={false}></Column> */}
        <Column
          field="file_name"
          body={fileNameTemplate}
          header={fileNameTH}
          sortable
        ></Column>
        <Column field="category_name" header={categoryTH} sortable></Column>
        <Column body={tagsTemplate} header={keywordsTH}></Column>
        <Column field="file_size" header={fileSizeTH} sortable></Column>
        <Column field="created_at" header={dateCreatedTH} sortable></Column>
        <Column header={actionTH} body={moreTemplate}></Column>
      </DataTable>
      <DeleteFile
        showModal={deleteModal}
        onHideModal={() => setDeleteModal(false)}
        isEnglish={isEnglish}
        onDeleteFile={async (id: any) => {
          await deleteFile(id);
        }}
        fileId={currentFile?.file_id}
        fileType={currentFile?.file_format}
        fileName={currentFile?.file_name}
        fileURL={currentFile?.file_url}
        fileSize={currentFile?.file_size}
        isLoading={isLoading}
      />
    </ListContainer>
  );
}

export default React.memo(UserFilesList);
