import { Navigate, Outlet } from "react-router-dom";
import CommonMenu from "../components/layout/menu/common-menu";
import { AUTH_PATHS } from "./paths";
import myStorage from "../store/my-storage";
import IdleTimeoutHandler from "../components/ui-components/idleTimer";

interface IType {
  token: boolean | undefined;
}

function MainMenuRoutes() {
  const storage = myStorage();
  const token = !!storage.get("accessToken") && !!storage.get("userDetails");
  let auth: IType = { token };

  return auth.token ? (
    <CommonMenu>
      <IdleTimeoutHandler/>
      <Outlet />
    </CommonMenu>
  ) : (
    <Navigate to={AUTH_PATHS.LOGIN} />
  );
}

export default MainMenuRoutes;
